import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from "components/misc/Buttons";

const SourceMenu = ({ id }) => {
  const navigate = useNavigate();

  return (
    <>
      {/* <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/source?id=${id}`)}>Add Alert</PrimaryButton> */}
      <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/bots?id=${id}`)}>Dashboards</PrimaryButton>
      {/* <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/add_targets?id=${id}`)}>Add Plans and Targets</PrimaryButton> */}
      <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/targets?id=${id}`)}>Forecasts</PrimaryButton>
      <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/alerts?id=${id}`)}>Alerts</PrimaryButton>
      {/* <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/issues?id=${id}`)}>Issues</PrimaryButton> */}
      {/* <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/issues?id=${id}`)}>Issues</PrimaryButton> */}
    </>
  );
};

export default SourceMenu;

// const SourceMenu = ({ id }) => {
//   const navigate = useNavigate();

//   return (
//     <>
//       <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/source?id=${id}`)}>Add Alert</PrimaryButton>
//       <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/bots?id=${id}`)}>Alert Overview</PrimaryButton>
//       <PrimaryButton style={{ marginRight: '5px' }} onClick={() => navigate(`/alerts?id=${id}`)}>Notifications</PrimaryButton>
//     </>
//   );
// };

// export default SourceMenu;
