import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';

// You should replace this with your own Mapbox access token
mapboxgl.accessToken = 'pk.eyJ1IjoibWNoYW5nY2lyY2xlb3BzIiwiYSI6ImNscmxhdjFjdjBtOWsybGtkY2trMjVvdGMifQ.L9iV0efDIolzkizB89zmBw';

const SimpleMapDisplay = () => {
    const mapContainerRef = useRef(null);
    const [map, setMap] = useState(null);
  
    const coastalAreas = [
      { name: "Seattle", lat: 47.6062, lng: -122.3321 },
      { name: "Portland", lat: 45.5155, lng: -122.6789 },
      { name: "San Francisco", lat: 37.7749, lng: -122.4194 },
      { name: "Los Angeles", lat: 34.0522, lng: -118.2437 },
      { name: "San Diego", lat: 32.7157, lng: -117.1611 },
      { name: "Boston", lat: 42.3601, lng: -71.0589 },
      { name: "New York", lat: 40.7128, lng: -74.0060 },
      { name: "Philadelphia", lat: 39.9526, lng: -75.1652 },
      { name: "Baltimore", lat: 39.2904, lng: -76.6122 },
      { name: "Miami", lat: 25.7617, lng: -80.1918 },
      { name: "Tampa", lat: 27.9506, lng: -82.4572 },
      { name: "New Orleans", lat: 29.9511, lng: -90.0715 },
      { name: "Houston", lat: 29.7604, lng: -95.3698 }
    ];
  
    useEffect(() => {
      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [-98, 40],
        zoom: 3.5
      });
  
      newMap.on('load', () => {
        const points = coastalAreas.map(city => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [city.lng, city.lat]
          },
          properties: {
            name: city.name,
            value: Math.floor(Math.random() * 100)
          }
        }));
  
        newMap.addSource('coastal-points', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: points
          }
        });
  
        newMap.addLayer({
          id: 'coastal-points',
          type: 'circle',
          source: 'coastal-points',
          paint: {
            'circle-radius': [
              'interpolate', ['linear'], ['get', 'value'],
              0, 8,
              100, 25
            ],
            'circle-color': [
              'interpolate', ['linear'], ['get', 'value'],
              0, '#ffcccb',   // Light red
              50, '#ff6666',  // Medium red
              100, '#8b0000'  // Dark red
            ],
            'circle-opacity': 0.8
          }
        });
  
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });
  
        newMap.on('mouseenter', 'coastal-points', (e) => {
          newMap.getCanvas().style.cursor = 'pointer';
          
          const coordinates = e.features[0].geometry.coordinates.slice();
          const { name, value } = e.features[0].properties;
          
          popup.setLngLat(coordinates)
            .setHTML(`${name}<br>Value: ${value}`)
            .addTo(newMap);
        });
  
        newMap.on('mouseleave', 'coastal-points', () => {
          newMap.getCanvas().style.cursor = '';
          popup.remove();
        });
      });
  
      setMap(newMap);
  
      return () => newMap.remove();
    }, []);
  
    return <div ref={mapContainerRef} style={{ width: '100%', height: '500px' }} />;
  };
  
  export default SimpleMapDisplay;