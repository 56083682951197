import SetUp2 from 'internal_pages/SetUp2.js'
import Source from "internal_pages/Source";
import Settings from 'internal_pages/Settings'; 
import Home from "Home.js"
import SignIn from 'internal_pages/SignIn.js'
import Dashboard from 'internal_pages/Dashboard.js'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AnalysisBot from "internal_pages/AnalysisBot";
import GetStarted from 'internal_pages/GetStarted';
import Reports from 'internal_pages/Reports/Reports';
import Alerts from 'internal_pages/Alerts';
import KPITracker from 'components/parts/KPITracker';
import PlansOverview from 'internal_pages/Reports/PlansOverview';
import AddTargets from 'internal_pages/AddTargets';
import Issues from 'internal_pages/Issues';
import MapDemo from 'components/MapDemo';
import Home2 from 'Home2.js';
import ParkingManagement from 'case_studies/ParkingManagement';
import EquipmentMaintenance from 'case_studies/EquipmentMaintenance';
import GeneralForecast from 'solutions/GeneralForecast';
import InventoryForecast from 'solutions/InventoryForecast';
import PredictiveMaintenance from 'solutions/PredictiveMaintenance';
import CostPrediction from 'solutions/CostPrediction';
import StaffingForecast from 'solutions/StaffingForecast';
import EmployeeRisk from 'solutions/EmployeeRisk';
import RevenueForecasting from 'solutions/RevenueForecasting';
import OperationalAnomalies from 'solutions/OperationalAnomalies';
import IntelligentMonitoring from 'solutions/IntelligentMonitoring';
import FacilitiesManagement from 'case_studies/FacilitiesManagement';
import HotelsResorts from 'case_studies/HotelsResorts';
import AutomotiveServices from 'case_studies/AutomotiveServices';

export default function App() {

  return (

    <>
      <Router>
        <Routes>
          {/* <Route path="/hotels_resorts" element={<HotelsResorts /> } />
          <Route path="/automotive_services" element={<AutomotiveServices /> } />
          <Route path="/facilities_management" element={<FacilitiesManagement /> } />
          <Route path="/solutions/intelligent_monitoring" element={<IntelligentMonitoring /> } />
          <Route path="/solutions/operational_anomalies" element={<OperationalAnomalies />} />
          <Route path="/solutions/revenue_forecasting" element={<RevenueForecasting />} />
          <Route path="/solutions/employee_risk" element={<EmployeeRisk />} />
          <Route path="/solutions/staffing_demand" element={<StaffingForecast />} />
          <Route path="/solutions/predict_costs" element={<CostPrediction />} />
          <Route path="/solutions/predictive_maintenance" element={<PredictiveMaintenance /> } />
          <Route path="/solutions/inventory_forecast" element={<InventoryForecast /> } />
          <Route path="/solutions/general_forecast" element={<GeneralForecast /> } />
          <Route path="/equipment" element={<EquipmentMaintenance />} />
          <Route path="/parking_management" element={<ParkingManagement />} />
          <Route path="/maps" element={<MapDemo />}/>
          <Route path="/alt" element={<Home />} /> */}
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/setup" element={<SetUp2 />} />
          <Route path="/source" element={<Source demo={false} />} />
          <Route path="/add_targets" element={<AddTargets />} /> */}
          <Route path="/targets" element={<PlansOverview />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/sign_in" element={<SignIn />} />
          <Route path="/bots" element={<AnalysisBot />} />
          <Route path="/alerts" element={<Alerts />} />

          {/* <Route path="/get_started" element={<GetStarted />} />
          <Route path="/reports" element={<Reports />} /> */}
          {/* <Route path="/parking_operations" element={<ParkingOperations />} />
          <Route path="/hotels" element={<HotelOperations />} />
          <Route path="/terminal" element={<Terminal />} />
          <Route path="/location" element={<Location />} /> */}
          {/* <Route path="/alerts" element={<Alerts />} />
          <Route path="/kpi_tracker" element={<KPITracker />} />
          <Route path="/issues" element={<Issues />} /> */}
        </Routes>
      </Router>
    </>
  );
}