import styled from "styled-components";

export const StyledContainer = styled("div")`
  display: inline-block;
  width: 100%;
  padding: 10px 5px;
  margin-bottom: -0.625rem;
`;

export const StyledTextArea = styled.textarea`
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;

    &:focus {
        background: none;
        box-shadow: #2e186a 0px 0px 0px 1px;
    }
`;


export const Label = styled("label")`
  display: block;
  padding-bottom: 10px;
  text-transform: capitalize;
`;
