import React from 'react';
import { BasicParagraph2, BasicParagraphStrong, SimpleStyledInput, StandardButton2 } from 'components/misc/Layouts';
import Plot from 'react-plotly.js';
import { useState, useEffect } from 'react';
import ViewToggle from './ViewToggle';
import CustomCalendar from './CustomCalendar';
import NumberCard from 'components/NumberCard';
import {Table, Tbody, Thead, Trow, Td, Th} from "components/misc/Layouts";

const PerformanceDetails = ({ details }) => {
  const renderPerDayDetails = () => {
    console.log('per day', details.data)
    const { data, analysis, forecast_analysis } = details.data; 
    console.log('forecast analysis', forecast_analysis)
    
    // analysis is now a map to => comparison and average 
    const comparison = analysis.comparison;
    const momComparison = analysis.mom_comparison;
    
    // comparison is what was there before so please apply the old analysisTimestamps logic there 
    const analysisTimestamps = Object.keys(comparison).sort((a, b) => parseFloat(a) - parseFloat(b));
    const analysisDates = analysisTimestamps.map(timestamp => new Date(parseFloat(timestamp) * 1000).toISOString());

    const currentValues = analysisTimestamps.map(timestamp => comparison[timestamp].current_value);
    const previousValues = analysisTimestamps.map(timestamp => comparison[timestamp].previous_value);
    const netDifferences = currentValues.map((current, index) => (current - previousValues[index]));


    const forecastComparison = forecast_analysis.comparison;
    const forecastMomComparison = forecast_analysis.mom_comparison;
    const forecastAnalysisTimestamps = Object.keys(forecastComparison).sort((a, b) => parseFloat(a) - parseFloat(b));
    const forecastAnalysisDates = forecastAnalysisTimestamps.map(timestamp => new Date(parseFloat(timestamp) * 1000).toISOString());

    const forecastCurrentValues = forecastAnalysisTimestamps.map(timestamp => forecastComparison[timestamp].current_value);
    const forecastPreviousValues = forecastAnalysisTimestamps.map(timestamp => forecastComparison[timestamp].previous_value);
    const forecastNetDifferences = forecastCurrentValues.map((current, index) => (current - forecastPreviousValues[index]));

    const mainPlotData = [
      {
        x: analysisDates,
        y: netDifferences,
        type: 'bar',
        marker: { color: 'blue' },
      },
      {
        x: forecastAnalysisDates,
        y: forecastNetDifferences,
        type: 'bar',
        marker: {color: 'skyblue'}
      }
    ];

    const trendsTimestamps = Object.keys(data.trends).sort((a, b) => a.localeCompare(b));
    const momComparisonTimestamps = Object.keys(momComparison).sort((a, b) => a.localeCompare(b));
    const forecastMomComparisonTimestamps = Object.keys(forecastMomComparison).sort((a, b) => a.localeCompare(b));
    const nonOverlappingMomTimestamps = momComparisonTimestamps.filter(timestamp => !forecastMomComparisonTimestamps.includes(timestamp));

    // Create the combined dataset for x and y values
    const combinedTimestamps = [...nonOverlappingMomTimestamps, ...forecastMomComparisonTimestamps];
    const combinedValues = [
      ...nonOverlappingMomTimestamps.map(timestamp => momComparison[timestamp].current_value - momComparison[timestamp].previous_value),
      ...forecastMomComparisonTimestamps.map(timestamp => forecastMomComparison[timestamp].current_value - forecastMomComparison[timestamp].previous_value)
    ];
    const combinedColors = [
      ...nonOverlappingMomTimestamps.map(() => 'blue'),
      ...forecastMomComparisonTimestamps.map(() => 'skyblue')
    ];

    
    const additionalPlots = [
      {
        data: [
          {
            x: trendsTimestamps,
            y: trendsTimestamps.map(timestamp => data.trends[timestamp]),
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: 'green' },
          },
        ],
        layout: {
          title: 'AI Recommendation',
          autosize: true,
          xaxis: { range: [trendsTimestamps[0], trendsTimestamps[trendsTimestamps.length - 1]] }
        },
      },
      {
        data: [
          {
            x: combinedTimestamps,
            y: combinedValues,
            type: 'bar',
            marker: {
              color: combinedColors
            }
          }
        ],
        layout: {
          title: 'Year Over Year Change by Month',
          autosize: true,
          xaxis: { range: [momComparisonTimestamps[0], forecastMomComparisonTimestamps[forecastMomComparisonTimestamps.length - 1]] },
          bargap: 0.1
        }
      }
    ];
    
    return (
      <div>
        <BasicParagraph2>Trend Details Per Day</BasicParagraph2>
        {additionalPlots.map((plot, index) => (
          <Plot key={index} data={plot.data} layout={plot.layout} style={{ width: '100%' }} />
        ))}
        <Plot data={mainPlotData} layout={{ title: 'Year Over Year Change By Day (7-day rolling average)', autosize: true,     showlegend: false }} style={{ width: '100%' }} />
      </div>
    );
  };

  const sortDaysOfWeek = (days, values) => {
    const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return dayOrder.map(day => {
      const index = days.indexOf(day);
      return {
        day,
        value: values[index],
      };
    }).filter(item => item.value !== undefined); // Filter out any undefined values
  };

  const renderWeeklyDetails = () => {
    const { data, analysis } = details.data;
    console.log('weekly analysis', data);

    const sortedDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const sortedValues = sortedDays.map(dayOfWeek => data[dayOfWeek]);
  
    const mainPlotData = [
      {
        x: sortedDays,
        y: sortedValues,
        type: 'bar',
        marker: { color: 'blue' }, // Blue color for the bars
      },
    ];
  
    // Normalize the data to show deviations from the mean value
    const normalizeData = (values) => {
      const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
      return values.map(value => value - mean);
    };
  
    // Sort days of the week from Sunday to Saturday

  
    // Additional plots for each year in the analysis object
    const additionalPlots = Object.keys(analysis).sort((a, b) => b - a).map(year => {
      const yearData = analysis[year];
      console.log('year data', yearData);
  
      const days = Object.keys(yearData);
      const values = normalizeData(Object.values(yearData));
  
      const sortedData = sortDaysOfWeek(days, values);
      const sortedDays = sortedData.map(item => item.day);
      const sortedValues = sortedData.map(item => item.value);
  
      return {
        data: [
          {
            x: sortedDays,
            y: sortedValues,
            type: 'bar',
            marker: { color: 'blue' }, // Blue color for the bars
          },
        ],
        layout: {
          title: `Analysis for ${year}`,
          xaxis: {
            title: 'Day of the Week',
          },
          yaxis: {
            title: 'Deviation from Mean Value',
          },
          style: { width: '100%' },
        },
      };
    });
  
    return (
      <div>
        <BasicParagraph2>Weekly Trend Details</BasicParagraph2>
        <Plot
          data={mainPlotData}
          layout={{
            title: 'AI Recommendation',
            xaxis: {
              title: 'Day of the Week',
            },
            yaxis: {
              title: 'Deviation from Mean Value',
            },
          }}
          style={{ marginTop: '20px', width: '100%' }} // Add marginTop and adjust width
        />
        {additionalPlots.map((plot, index) => (
            <Plot data={plot.data} layout={plot.layout} style={{ width: '100%' }} />
        ))}
      </div>
    );
  };
  
  const renderDailyDetails = () => {
    const { data, analysis, forecast_analysis } = details.data;

    const analysisWithDate = analysis.map(entry => ({
        ...entry,
        timestamp: new Date(entry.timestamp * 1000).toISOString(), // convert seconds to milliseconds and export to ISO time
    }));

    const forecastAnalysisDates = forecast_analysis.map(entry => ({
        ...entry,
        timestamp: new Date(entry.timestamp * 1000).toISOString(), // convert seconds to milliseconds and export to ISO time
    }));

    // Sort data by date in increasing order
    const sortedData = Object.entries(data).sort((a, b) => new Date(a[0]) - new Date(b[0]));

    console.log('sorted data', sortedData);
  
    const mainPlotData = [
        {
            x: sortedData.map(([date]) => date),
            y: sortedData.map(([, value]) => value),
            type: 'bar',
            marker: { color: 'blue' }, // Blue color for the bars
        },
    ];
  
    // Group analysis data by year
    const groupEntriesByYear = (entries) => (
        entries.reduce((acc, entry) => {
            const year = new Date(entry.timestamp).getFullYear();
            if (!acc[year]) {
                acc[year] = [];
            }
            acc[year].push(entry);
            return acc;
        }, {})
    );

    const groupedAnalysis = groupEntriesByYear(analysisWithDate);
    const groupedForecast = groupEntriesByYear(forecastAnalysisDates);
    const mergedGroupedData = { ...groupedAnalysis };
    Object.keys(groupedForecast).forEach(year => {
        if (!mergedGroupedData[year]) {
            mergedGroupedData[year] = groupedForecast[year];
        } else {
            const forecastEntries = groupedForecast[year];
            const updatedEntries = mergedGroupedData[year];

            forecastEntries.forEach(forecastEntry => {
                const index = updatedEntries.findIndex(entry => entry.timestamp === forecastEntry.timestamp);
                if (index !== -1) {
                    updatedEntries[index] = forecastEntry; 
                } else {
                    updatedEntries.push(forecastEntry); 
                }
            });

            mergedGroupedData[year] = updatedEntries;
        }
    });

    // Calculate mean delta for each year
    const calculateMeanDelta = (entries) => {
        const values = entries.map(entry => entry.rolling_avg_7d);
        const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
        return entries.map(entry => ({
            ...entry,
            delta_mean: entry.rolling_avg_7d - mean,
        }));
    };

    const additionalPlots = Object.entries(mergedGroupedData).sort(([yearA], [yearB]) => yearB - yearA).map(([year, entries]) => {
        const updatedEntries = calculateMeanDelta(entries);

        return {
            data: [
                {
                    x: updatedEntries.map(entry => entry.timestamp),
                    y: updatedEntries.map(entry => entry.rolling_avg_7d),
                    type: 'bar',
                    marker: {
                        color: updatedEntries.map(entry => 
                            groupedForecast[year] && groupedForecast[year].some(forecastEntry => forecastEntry.timestamp === entry.timestamp) ? 
                            'lightblue' : 
                            'blue'
                        ),
                    },
                },
            ],
            layout: {
                title: `7-Day Rolling Average for ${year}`,
                xaxis: {
                    range: [`${year}-01-01`, `${year}-12-31`],
                },
                style: { width: '100%' },
            },
        };
    });

    return (
      <div>
        <BasicParagraph2>Daily Trend Details</BasicParagraph2>
        <Plot data={mainPlotData} layout={{ title: 'AI Recommendation' }}  style={{ width: '100%' }} />
        {additionalPlots.map((plot, index) => (
          <div key={index} style={{ marginTop: '20px' }}>
            <Plot data={plot.data} layout={plot.layout} style={{ width: '100%' }} />
          </div>
        ))}
        {/* <Table>
          <Thead>
            <Trow>
              <Td>Factor</Td>
              <Td>Impact on Total Segment</Td>
            </Trow>
          </Thead>
          <Tbody>
            <Trow>
              <Td>Revenue from Source OCRA is down 35% YoY.</Td>
              <Td>15%</Td>
            </Trow>
            <Trow>
              <Td>Revenue from Source SpotHero is down 40% YoY.</Td>
              <Td>10%</Td>
            </Trow>
            <Trow>
              <Td>Revenue registered between 10am - 12pm is down 40% YoY.</Td>
              <Td>5%</Td>
            </Trow>
          </Tbody>
        </Table>
        <Table>
  <Thead>
    <Trow>
      <Td>Factor</Td>
      <Td>Impact on Parking Lot Revenue</Td>
    </Trow>
  </Thead>
  <Tbody>
    <Trow>
      <Td>Increased summer travel to Los Angeles</Td>
      <Td>Positive impact due to increased airport parking demand</Td>
    </Trow>
    <Trow>
      <Td>LA Pride celebrations in late June</Td>
      <Td>Potential increase in parking demand</Td>
    </Trow>
    <Trow>
      <Td>Los Angeles Dodgers home games at Dodger Stadium throughout June</Td>
      <Td>Potential increase in parking demand</Td>
    </Trow>
    <Trow>
      <Td>Los Angeles Film Festival (June 13-23, 2024)</Td>
      <Td>Potential increase in parking demand</Td>
    </Trow>
    <Trow>
      <Td>Graduation ceremonies for UCLA, USC, and other major LA universities in early June</Td>
      <Td>Potential increase in parking demand</Td>
    </Trow>
    <Trow>
      <Td>Potential strikes or labor actions by airport/travel industry workers</Td>
      <Td>Negative impact on travel volume and parking demand</Td>
    </Trow>
    <Trow>
      <Td>Construction on LAX terminals/roadways</Td>
      <Td>Negative impact due to traffic disruptions near parking areas</Td>
    </Trow>
    <Trow>
      <Td>Major highway/road closures or construction projects</Td>
      <Td>Negative impact due to traffic disruptions</Td>
    </Trow>
    <Trow>
      <Td>Early summer heat waves in LA</Td>
      <Td>Potential negative impact on willingness to walk longer distances</Td>
    </Trow>
    <Trow>
      <Td>Major concert tours with shows in LA in June 2024</Td>
      <Td>Potential increase in parking demand (schedules to be checked)</Td>
    </Trow>
  </Tbody>
</Table> */}
      </div>
    );    
  };
  
  
  

  const renderDefaultDetails = () => (
    <pre>{JSON.stringify(details, null, 2)}</pre>
  );

  const renderDetails = () => {
    if (!details) {
      return <p>No details available</p>;
    }

    switch (details.type) {
      case 'per_day':
        return renderPerDayDetails();
      case 'weekly':
        return renderWeeklyDetails();
      case 'daily':
        return renderDailyDetails();
      default:
        return renderDefaultDetails();
    }
  };

  return (
    <div>
      <BasicParagraphStrong>Performance Details</BasicParagraphStrong>
      {renderDetails()}
    </div>
  );
};

const ForecastDayAnalysisComponent = ({ data, ai_recommendation = false }) => {
  const [componentsData, setComponentsData] = useState([]);

  useEffect(() => {
    setComponentsData([...data.components]);
  }, [data]);

  // Initialize error bounds
  const errorMinus = new Array(componentsData.length).fill(0);
  const errorPlus = new Array(componentsData.length).fill(0);

  // Update error bounds based on each component's bounds field
  componentsData.forEach((component, index) => {
    if (component.bounds) {
      console.log('found bounds', component.bounds)
      errorMinus[index] = component.bounds[0] * -1;
      errorPlus[index] = component.bounds[1];
    }
  });

  const barData = {
    type: 'bar',
    x: componentsData.map((component) => component.name),
    y: componentsData.map((component) => component.value),
    marker: { color: 'blue' },
  };

  const scatterData = {
    type: 'scatter',
    mode: 'markers',
    x: componentsData.map((component) => component.name),
    y: componentsData.map((component) => component.value),
    error_y: {
      type: 'data',
      symmetric: false,
      array: errorPlus,
      arrayminus: errorMinus,
      visible: true,
    }
  };

  return (
    <>
      <BasicParagraphStrong>{data.title}</BasicParagraphStrong>

      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <div style={{ flex: 1, width: '100%' }}>
          <Plot
            data={[barData, scatterData]}
            layout={{
              title: 'Forecast Day Analysis with Error Margins',
              xaxis: { title: 'Components' },
              yaxis: { title: 'Values' },
              barmode: 'overlay',
              showlegend: false // Add this line to hide the legend
            }}
            config={{ displayModeBar: false }}  // Hide the toolbar if desired
            
          />
        </div>

        <div
          style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}
        >
          <BasicParagraphStrong>Components</BasicParagraphStrong>
          {componentsData.map((item, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                {ai_recommendation ? (
                  <>
                    <BasicParagraph2>
                      {item.name}: {item.value}
                    </BasicParagraph2>
                  </>
                ) : (
                  <>
                    <BasicParagraph2>{item.name}:</BasicParagraph2>
                    <SimpleStyledInput
                      type="number"
                      value={item.value}
                      onChange={(e) => {
                        // Implement the logic to update the component value
                        const newValue = Number(e.target.value);
                        const updatedData = [...componentsData];
                        updatedData[index] = { ...updatedData[index], value: newValue };
                        setComponentsData(updatedData);
                      }}
                      style={{ marginLeft: '10px' }}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
          <div style={{ marginTop: '20px' }}></div>
        </div>
      </div>
      <hr />
      <div></div>
    </>
  );
};


const DateRow = ({ dateData, onBreakdownClick }) => {
  const dateObject = new Date(dateData.date * 1000);
  const dayOfWeek = dateObject.toLocaleString('en-US', { weekday: 'long' , timeZone: 'UTC' });
  const monthlyFormattedDate = dateObject.toLocaleDateString('en-US', { month: 'long', day: 'numeric' , timeZone: 'UTC' });
  const year = dateObject.toLocaleString('en-US', { year: 'numeric' , timeZone: 'UTC' });
  const formattedDateString = `${dayOfWeek}, ${monthlyFormattedDate}, ${year}`;

  console.log('date row', dateData)

  return (
    <Trow>
      <Td>{formattedDateString}</Td>
      <Td>{dateData.totalPrediction}</Td>
      <Td><StandardButton2 onClick={() => onBreakdownClick(dateData)}>Details</StandardButton2></Td>
    </Trow>
  );
};


const DayDetails = ({ data, index }) => {
  const [displayRows, setDisplayRows] = useState([]);
  const [formattedDate, setFormattedDate] = useState('');
  const [relevantDates, setRelevantDates] = useState([])

  useEffect(() => {
    console.log('data', data)
    const referenceDate = data.forecast[index].timestamp;
    const referenceDateObject = new Date(referenceDate * 1000);
    
    // Identify the date one year ago
    const oneYearAgo = new Date(referenceDateObject.getTime());
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    const oneYearAgoTimestamp = oneYearAgo.getTime() / 1000;
    
    // Identify the same day of week one week ago
    const oneWeekAgo = new Date(referenceDateObject.getTime() - 604800000); // 604800000 is the number of milliseconds in a week
    const oneWeekAgoTimestamp = oneWeekAgo.getTime() / 1000;

    const equivalentDateLastYear = findEquivalentDateLastYear(referenceDate);
    const equivalentDateLastYearTimestamp = equivalentDateLastYear.getTime() / 1000;
    
    const relevantDatesWithValues = [
      { date: oneYearAgoTimestamp },
      { date: oneWeekAgoTimestamp },
      { date: equivalentDateLastYearTimestamp }
    ].map((dateObj) => {
      let matchingValue = data.interpretation.realized_values.find((realizedValue) => realizedValue.timestamp === dateObj.date);
      if (!matchingValue) {
        matchingValue = data.realized_values.find((realizedValue) => realizedValue.timestamp === dateObj.date);
      }
      return { ...dateObj, totalPrediction: matchingValue?.value };
    });

    setRelevantDates(relevantDatesWithValues);

  }, [data.forecast[index].timestamp])

  function findEquivalentDateLastYear(timestampInSec) {
    const currentdate = new Date(timestampInSec * 1000);
    const lastYear = currentdate.getFullYear() - 1;
    const dayOfWeek = currentdate.toLocaleDateString('en-US', { weekday: 'long', timeZone: 'UTC' });
    const weekOfMonth = Math.ceil((currentdate.getDate() - 1) / 7);
    const startDateLastYear = new Date(Date.UTC(lastYear, currentdate.getMonth(), 1));
    const endDateLastYear = new Date(Date.UTC(lastYear, currentdate.getMonth() + 1, 0));
    for (let d = startDateLastYear; d <= endDateLastYear; d.setDate(d.getDate() + 1)) {
      if (d.toLocaleDateString('en-US', { weekday: 'long', timeZone: 'UTC' }) === dayOfWeek && Math.ceil((d.getDate() - 1) / 7) === weekOfMonth) {
        return d;
      }
    }
    return null;
  }

  const onBreakdownClick = (dateData) => {
    // Format the dateData.date as a string
    const dateObject = new Date(dateData.date * 1000);
    const dayOfWeek = dateObject.toLocaleString('en-US', { weekday: 'long', timeZone: 'UTC'  });
    const monthlyFormattedDate = dateObject.toLocaleDateString('en-US', { month: 'long', day: 'numeric' , timeZone: 'UTC' });
    const year = dateObject.toLocaleString('en-US', { year: 'numeric' , timeZone: 'UTC' });
    const formattedDateString = `${dayOfWeek}, ${monthlyFormattedDate}, ${year}`;
    const baselineFormattedDate = dateObject.toISOString().split('T')[0];
    const shortDate = dateObject.toLocaleDateString('en-US', { month: 'short', day: '2-digit', timeZone: 'UTC'  }).replace(',', '');
  
    const breakdownParameters = {
      title: `Breakdown for ${formattedDateString}`,
      components: [
        {
          name: 'Trend',
          value: data.interpretation.trend.data.trends[baselineFormattedDate]
        },
        {
          name: 'Day of Week',
          value: data.interpretation.weekly.data[dayOfWeek]
        },
        {
          name: 'Time of Year',
          value: data.interpretation.daily.data[shortDate]
        }, 
        {
          name: 'Observational Noise', 
          value: 0, 
          bounds: [data.interpretation.noise.negative[baselineFormattedDate], data.interpretation.noise.positive[baselineFormattedDate]]
        },
        {
          name: "AI Expectation", 
          value: data.interpretation.trend.data.trends[baselineFormattedDate] + data.interpretation.weekly.data[dayOfWeek] + data.interpretation.daily.data[shortDate], 
          bounds: [data.interpretation.noise.negative[baselineFormattedDate], data.interpretation.noise.positive[baselineFormattedDate]]
        }, 
        {
          name: 'True Value', 
          value: dateData.totalPrediction
        }
      ]
    };
  
    setDisplayRows([...displayRows, {data: breakdownParameters, type: true}]);
  };

  useEffect(() => {
    const timestamp = data.forecast[index].timestamp;
    const dateObject = new Date(timestamp * 1000);
    
    const dayOfWeek = dateObject.toLocaleString('en-US', { weekday: 'long' });
    const monthlyFormattedDate = dateObject.toLocaleDateString('en-US', { month: 'long', day: 'numeric', timeZone: 'UTC'});
    const year = dateObject.toLocaleString('en-US', { year: 'numeric', timeZone: 'UTC' });
    const baselineFormattedDate = dateObject.toISOString().split('T')[0];
    const shortDate = dateObject.toLocaleDateString('en-US', { month: 'short', day: '2-digit', timeZone: 'UTC' }).replace(',', '');

    const formattedDateString = `${dayOfWeek}, ${monthlyFormattedDate}, ${year}`;
    setFormattedDate(formattedDateString);

    // extracting out AI Recommendation Object
    const aiRecommendationObject = {
      title: "AI Recommendation", 
      components: [
        {
          name: "Baseline Revenue", 
          value: data.interpretation.trend.data.trends[baselineFormattedDate]
        },
        {
          name: "Day of Week Impact", 
          value: data.interpretation.weekly.data[dayOfWeek]
        },
        {
          name: "Time of Year Impact", 
          value: data.interpretation.daily.data[shortDate]
        },
        {
          name: 'Observational Noise', 
          value:  0,
          bounds: [data.interpretation.noise.negative[baselineFormattedDate], data.interpretation.noise.positive[baselineFormattedDate]]
        },
        {
          name: "AI Expectation", 
          value: data.interpretation.trend.data.trends[baselineFormattedDate] + data.interpretation.weekly.data[dayOfWeek] + data.interpretation.daily.data[shortDate], 
          bounds: [data.interpretation.noise.negative[baselineFormattedDate], data.interpretation.noise.positive[baselineFormattedDate]]
        }
      ]
    }

    setDisplayRows([{type: true, data: aiRecommendationObject}])
  }, [data, index]);

  return (
    <>
      <BasicParagraphStrong>Analysis for: {formattedDate}</BasicParagraphStrong>
      <hr />
      {displayRows.map((row) => (
        <ForecastDayAnalysisComponent data={row.data} ai_recommendation={row.type} />
      ))}

      <BasicParagraphStrong>Relevant Previous Dates</BasicParagraphStrong>
      <Table>
        <Thead>
          <Trow>
            <Th>Reference Date</Th>
            <Th>Amount</Th>
            <Th></Th>
          </Trow>
        </Thead>
        <Tbody>
            {relevantDates.map((dateData) => (
              <DateRow key={dateData.date} dateData={dateData} onBreakdownClick={onBreakdownClick}  />
            ))}
        </Tbody>
      </Table>
    </>
  );
};


const Performance = ({scenarioParams, trackerParams}) => {
  const [evaluationData, setEvaluationData] = useState({})
  const [historyPlotData, setHistoryPlotData] = useState({})
  const [interpretationPlots, setInterpretationPlots] = useState([])
  const [overviewStats, setOverviewStats] = useState([])
  const [currentView, setCurrentView] = useState('main'); // State to manage the current view
  const [detailData, setDetailData] = useState(null); // State to manage the additional data
  const [forecastData, setForecastData] = useState([])
  const [selectedForecastIndex, setSelectedForecastIndex] = useState()

  const makeCumulative = (data) => {
    return data.reduce((acc, d) => {
      if (acc.length === 0) {
        acc.push(d.value);
      } else {
        acc.push(d.value + acc[acc.length - 1]);
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (Object.keys(evaluationData).length == 0) {
        return;
    }

    const aggregatedData = evaluationData.interpretation.aggregated_value;
    const realizedData = evaluationData.interpretation.realized_values;

    realizedData.sort((a, b) => a.timestamp - b.timestamp);

    const aggregatedDates = aggregatedData.map(d => new Date(d.timestamp * 1000).toISOString());
    const aggregatedValues = aggregatedData.map(d => d.value);

    const realizedDates = realizedData.map(d => new Date(d.timestamp * 1000).toISOString());
    const realizedValues = realizedData.map(d => d.value);

    const aggregatedLineChart = {
        x: aggregatedDates,
        y: aggregatedValues,
        name: 'Forecast',
        type: 'scatter',
        marker: { color: 'blue' }
    };

    const realizedLineChart = {
        x: realizedDates,
        y: realizedValues,
        name: 'Data',
        type: 'scatter',
    };

    const mergedChart = { plot: [aggregatedLineChart, realizedLineChart], title: 'Fitted Forecast', analyze_trend: false };

    const trendData = evaluationData.interpretation.trend.data['changepoints'];

    const dates = trendData.map(point => point.ds);
    const values = trendData.map(point => point.trend_value);

    const trendLine = {
        plot: [{
            x: dates,
            y: values,
            name: 'Average Revenue Per Day',
            type: 'scatter',
            marker: { color: 'blue' },
        }],
        title: 'Trend for Average Revenue',
        analyze_trend: true,
        additional_data: {
          'type': 'per_day', 
          'data': evaluationData.interpretation.trend
        }
    };

    const weeklyData = evaluationData.interpretation.weekly.data;
    const weeklyDates = Object.keys(weeklyData);
    const weeklyValues = Object.values(weeklyData);

    const weeklyBarChart = {
        plot: [{
            x: weeklyDates,
            y: weeklyValues,
            name: 'Weekly Data',
            type: 'bar',
            marker: { color: 'blue' },
        }],
        title: 'Day of Week Impact',
        analyze_trend: true,
        additional_data: {
          'type': 'weekly', 
          'data': evaluationData.interpretation.weekly
        },
    };

    const dailyData = evaluationData.interpretation.daily.data;
    const dailyDates = Object.keys(dailyData);

    const sortedDates = dailyDates.sort((a, b) => new Date(a) - new Date(b));
    const sortedValues = sortedDates.map(date => dailyData[date]);

    const dailyBarChart = {
        plot: [{
            x: sortedDates,
            y: sortedValues,
            name: 'Daily Data',
            type: 'bar',
            marker: { color: 'blue' }
        }],
        title: 'Day of Year Impact',
        analyze_trend: true,
        additional_data: {
          'type': 'daily',
          'data': evaluationData.interpretation.daily
        }
    };

    const noiseDatesPositive = Object.keys(evaluationData.interpretation.noise.positive);
    const noiseValuesPositive = Object.values(evaluationData.interpretation.noise.positive);

    const noiseDatesNegative = Object.keys(evaluationData.interpretation.noise.negative);
    const noiseValuesNegative = Object.values(evaluationData.interpretation.noise.negative); // Invert negative values

    const noiseLineChart = {
      plot: [{
        x: noiseDatesPositive,
        y: noiseValuesPositive,
        name: 'Positive Noise',
        type: 'scatter',
        mode: 'lines+markers',
      }, {
        x: noiseDatesNegative,
        y: noiseValuesNegative,
        name: 'Negative Noise',
        type: 'scatter',
        mode: 'lines+markers',
      }],
      title: 'Noise Signals',
      yaxis: {
        zeroline: true,
      },
      additional_data: {
        'type': 'noise',
        'data': evaluationData.interpretation.noise
      }
    };

    setInterpretationPlots([mergedChart, trendLine, weeklyBarChart, dailyBarChart]);

    const filteredAggregatedData = aggregatedData.filter(item =>
        item.timestamp >= trackerParams.start_date && item.timestamp <= trackerParams.end_date
    );
    const forecastSum = filteredAggregatedData.reduce((sum, value) => sum + value.value, 0);

    setOverviewStats([
        {
            title: 'Forecast Total',
            value: forecastSum,
            period: [trackerParams.start_date, trackerParams.end_date]
        }
    ]);


    const forecastRangeDates = evaluationData.forecast.map(item => new Date(item.timestamp * 1000).toISOString().split('T')[0]); // Convert timestamp to date string
    const forecastRangeValues = evaluationData.forecast.map(item => item.value);

    // Create the bar chart object
    const aggregatedBarChart = {
        x: forecastRangeDates,
        y: forecastRangeValues,
        name: 'Forecast',
        type: 'bar',
        marker: { color: 'blue' }
    };
    setForecastData([aggregatedBarChart])

  }, [evaluationData]);



  useEffect(() => {
    if (scenarioParams.forecast_details) {
      let url = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/downloadFile?file_path=${scenarioParams.forecast_details}`
      url = `https://us-central1-circleops-5f8c7.cloudfunctions.net/downloadFile?file_path=${scenarioParams.forecast_details}`

      fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
      }).then((response) => response.json())
        .then((results) => {
          setEvaluationData(results);
        })
      
    }
  }, [scenarioParams])

  const handleAnalyzeClick = (additionalData) => {
    setDetailData(additionalData);
    setCurrentView('detail');
  };

  const handleBackClick = () => {
    setCurrentView('main'); 
  };

  const handleClick = (data) => {
    const pointIndex = data.points[0].pointIndex;
    setSelectedForecastIndex(pointIndex);
    setCurrentView('day_detail')
  };

  return (
    <>
        {currentView == 'main' && 
          <>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '0px' }}>
            {overviewStats.map((component, index) => (
              <NumberCard
                key={index}
                title={component.title}
                period={component.period}
                value={component.value}
              />
            ))}
          </div>
        <Plot 
          layout={{title: "Forecast Breakdown"}}
          data={forecastData}
          useResizeHandler
          style={{ width: '100%' }}
          onClick={handleClick}
        />
        <BasicParagraphStrong>Model Factors</BasicParagraphStrong>
        {interpretationPlots.map((interpretationData, index) => (
          <>
            <Plot
              layout={{ title: interpretationData.title }}
              key={index}
              data={interpretationData.plot}
              useResizeHandler
              style={{ width: '100%' }}
            />
            {interpretationData.analyze_trend && (
                <StandardButton2 onClick={() => handleAnalyzeClick(interpretationData.additional_data)}>Analyze Parameters</StandardButton2>
            )}
          </>
        ))}
        </>}
        {
          currentView == 'detail' && 
          <>
            <StandardButton2 onClick={handleBackClick}>Back to Summary</StandardButton2>
            <PerformanceDetails details={detailData} />
          </>
        }
        {
          currentView == 'day_detail' && 
          <>
            <StandardButton2 onClick={handleBackClick}>Back to Summary</StandardButton2>
            <DayDetails data={evaluationData} index={selectedForecastIndex} />
          </>
        }
    </>
  );
};

export {Performance, PerformanceDetails};
