import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { BasicParagraph2, Container, Content2Xl, InternalPageHeader, PrimaryButton, Table, Tbody, Thead, Trow , Th, Td, StandardButton, MenuOption, StandardButton2, StyledSpinner, NiceText, BasicParagraphStrong, CodeBlock} from "components/misc/Layouts";
import HeaderInternal from "landing_components/HeaderInternal";
import Dropdown from "components/parts/Dropdown";
import Plot from 'react-plotly.js';
import { PlainText } from "components/misc/Layouts";
import AppHeaderInternal from "landing_page_2024/AppHeaderInternal";
import MultiSelectDropdown from "components/parts/MultiSelectDropdown";
import SourceMenu  from "components/SubComponents/SourceMenu";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";


function MenuButton({ children, isActive, onClick }) {
  return (
    <button
      onClick={onClick}
      className={`relative flex-grow px-4 py-2 items-center justify-center text-sm font-semibold transition duration-300 before:absolute before:inset-0 before:rounded-lg before:border before:transition before:duration-300 ${isActive ? 'before:bg-primary text-white' : 'before:bg-primary/10 text-black'} hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max`}
    >
      {children}
    </button>
  );
}

const renderEventDescription = (event) => {
  const codeStyle = {
    fontFamily: 'monospace',
    backgroundColor: '#f6f8fa',
    padding: '2px 4px',
    borderRadius: '4px',
    fontSize: '90%',
    border: '1px solid #e1e4e8',
  };

  if (event.type == 'forecast') {
    // Generalizing to any keyword
    let keyword; 
    if (event.measure_option.type === 'SUM') {
      keyword = <span style={codeStyle}>{event.measure_option.variable}</span>;
    } else {
      keyword = <span style={codeStyle}>row count</span>;
    }
  
    let status;
    if (event.status == 'lower') {
      status = <span style={{color: 'red'}}>lower</span>;
    } else if (event.status == 'upper') {
      status = <span style={{color: 'green'}}>higher</span>;
    } else {
      status = <span style={{color: 'orange'}}>higher variance</span>;
    }
  
    // Dynamically generate tags for each key-value pair in event.metadata
    const keyValues = event.keys.map((meta, index) => (
      <span key={index} style={codeStyle}>{meta.field_name}={meta.value}</span>
    ));
  
    return (
      <>
          {keyword} is {status} than expected{event.keys.length > 0 && <> for: {keyValues}</>}
      </>
    );
  } else if (event.type == 'drops') {
    let keyword; 
    if (event.measure_option.type === 'SUM') {
      keyword = <span style={codeStyle}>{event.measure_option.variable}</span>;
    } else {
      keyword = <span style={codeStyle}>row count</span>;
    }

    const keyValues = event.keys.map((meta, index) => (
      <span key={index} style={codeStyle}>{meta.field_name}={meta.value}</span>
    ));

    return (
      <>
        {keyword} dropped to zero for: {keyValues}
      </>
    );
  } else if (event.type == 'booking_pace') {

    let status;
    if (event.status == 'lower') {
      status = <span style={{color: 'red'}}>slower</span>;
    } else if (event.status == 'higher') {
      status = <span style={{color: 'green'}}>faster</span>;
    } else {
      status = <span style={{color: 'orange'}}>higher variance</span>;
    }
    const keyValues = event.keys.map((meta, index) => (
      <span key={index} style={codeStyle}>{meta.field_name}={meta.value}</span>
    ));

    return (
      <>
        <span style={codeStyle}>booking pace</span> is {status} than expected for: {keyValues}
    </>
    )
  } else if (event.type == 'kpi_tracker') {
    let keyword; 
    if (event.measure_option.type === 'SUM') {
      keyword = <span style={codeStyle}>{event.measure_option.variable}</span>;
    } else {
      keyword = <span style={codeStyle}>row count</span>;
    }

    const keyValues = event.keys.map((meta, index) => (
      <span key={index} style={codeStyle}>{meta.key}={meta.value}</span>
    ));

    const statusValue = event.status.value;
    const guidanceStatus = statusValue > 0 ? 'above' : 'below';
    const percentage = (Math.abs(statusValue) * 100).toFixed(2);
    const colorStyle = { color: statusValue > 0 ? 'green' : 'red' };

    return (
      <>
        Your target for {keyword} on {keyValues} is <span style={colorStyle}>{guidanceStatus}</span> guidance by: <span style={colorStyle}>{percentage}%</span>
      </>
    );
  } else {
    return (
      <>
        {event.status}
      </>
    )
  }
};

const PopupModal = ({onClose, event }) => {
  const [showOptions, setShowOptions] = useState(false)
  const [plotData, setPlotData] = useState([]);
  const [showLegend, setShowLegend] = useState(false);
  const [xAxis, setXAxis] = useState('');
  const [yAxis, setYAxis] = useState('');
  const [popupTitle, setPopupTitle] = useState('');
  const [importance, setImportance] = useState('None')
  const [comment, setComment] = useState('')
  const [message, setMessage] = useState('')
  const [spinning, setSpinning] = useState(false);
  const [layoutData, setLayoutData] = useState({
    showlegend: showLegend,
    xaxis: { title: xAxis },
    yaxis: { title: yAxis },
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
  })
  const [showTable, setShowTable] = useState(false)
  const [showCreateIssueModal, setShowCreateIssueModal] = useState(false)
  const [showShareInsightsModal, setShowShareInsightsModal] = useState(false)
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1050',
    backgroundColor: '#fff',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: '50%',
    maxWidth: '600px'
  };
  
  const textareaStyle = {
    width: '100%',
    height: '100px',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px'
  };
  
  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px'
  };

  const codeStyle = {
    fontFamily: 'monospace',
    backgroundColor: '#f6f8fa',
    padding: '2px 4px',
    borderRadius: '4px',
    fontSize: '90%',
    border: '1px solid #e1e4e8',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between'
  };

  useEffect(() => {
    setComment(event.comment);
    setImportance(event.importance)
  }, [event])

  const unixToGMT = (unixTime) => {
    return new Date(unixTime * 1000).toISOString();
  };

  useEffect(() => {

    const keyValues = event.keys.map(meta => `${meta.field_name || meta.key}=${meta.value}`).join(', ');

    setPopupTitle(keyValues) 
  
    const generateDateRange = (start, end, unit) => {
      const dateArray = [];
      let currentDate = new Date(start);
      // Ensure starting from the beginning of the day in UTC
      currentDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), 0, 0, 0));
    
      // Convert end date to UTC midnight as well for consistent comparison
      end = new Date(Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate(), 0, 0, 0));
      console.log('start:', currentDate, 'end:', end, 'unit:', unit)
    
      while (currentDate < end) {
        dateArray.push(currentDate.getTime()); // Store time in milliseconds
    
        if (unit === 'day') {
          // Increment day in UTC
          currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        } else if (unit === 'hour') {
          // Increment hour in UTC
          currentDate.setUTCHours(currentDate.getUTCHours() + 1);
        }
      }
      return dateArray;
    };
    

    const fetchData = async () => {
      if (event.type == 'forecast') {
        const { wrapper_id, keys, unit, lookback } = event;
        const dateOption = event.date_option;
        const measureOption = event.measure_option;
        const initiallyStarted = event.initially_started;
        const lastActive = event.last_active;
        let filters = [];
        if (event.wrapper_id == 'f8174e5632999cc49df72f9b3c4a3649') {
          filters = [{field: 'status', value: 'cancelled', comparer: '!='}]
        }
        const {type, variable} = measureOption;

        const startDate = initiallyStarted - lookback * 86400;

        setXAxis(dateOption);
        setYAxis(measureOption);
      
        const dateFilter = `start_date=${startDate}&end_date=${lastActive}`;
        const rawFilterOptions = keys.map(key => `${encodeURIComponent(key.field_name)}=${encodeURIComponent(key.value)}`).join(';');
        let filterOptions = rawFilterOptions; 

        const filterClauses = filters.map(filter => {
          const { field, value, comparer } = filter;
          return `${encodeURIComponent(field)}${encodeURIComponent(comparer)}${encodeURIComponent(value)}`;
        });
        if (filterOptions && filterClauses.length > 0) {
          filterOptions += ';' + filterClauses.join(';');
        } else if (!filterOptions && filterClauses.length > 0) {
          filterOptions = filterClauses.join(';');
        }
        filterOptions = encodeURIComponent(filterOptions);

        let requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&timeUnit=${unit}&filterOptions=${filterOptions}&${dateFilter}&user_id=${user.uid}`
        // let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&timeUnit=${unit}&filterOptions=${filterOptions}&${dateFilter}`


        if (type === 'SUM') {
          requestUrl += `&variable=${variable}`;
        }
        const response = await fetch(requestUrl);
        const data = await response.json();

        const metadata = event.metadata || [];
        const dateRange = generateDateRange(new Date(startDate * 1000), new Date(lastActive * 1000), unit);
        console.log(dateRange, initiallyStarted, lastActive, unit)
        const mainLineData = dateRange.map(dateTimeInMillis => {
          const found = data.find(d => {
            const dateInMilliseconds = new Date(d.date).getTime();
            console.log('data date value in milliseconds:', d.date, dateInMilliseconds);
            return dateInMilliseconds === dateTimeInMillis;
          });
          return {
            date: new Date(dateTimeInMillis).toISOString().split('T')[0], // Convert back to 'YYYY-MM-DD' for display
            value: found ? parseFloat(found.value) : 0,
          };
        });

        mainLineData.sort((a, b) => new Date(a.date) - new Date(b.date));

        const mainLine = {
          x: mainLineData.map(item => item.date),
          y: mainLineData.map(item => item.value),
          type: 'scatter',
          mode: 'lines+markers', // Use both lines and markers for clarity
          line: { color: '#adb0ff' }, // Soft lavender blue
          marker: {
            color: '#c2c4ff', // Lighter lavender blue for a subtle differentiation
            size: 8, // A bit smaller than before for subtlety
            symbol: 'circle', // Circle-shaped markers for simplicity
            opacity: 0.7 // Semi-transparent markers
          },
          name: 'Main Data'
        };
        
        const lowerBoundLine = {
          x: metadata.map(meta => meta.ds),
          y: metadata.map(meta => parseFloat(meta.yhat_lower)),
          type: 'scatter',
          mode: 'lines+markers',
          fill: 'none',
          line: { color: '#ffbb78' }, // Soft sky blue
          marker: {
            color: '#ffbb78',
            size: 6, // Slightly smaller for a subtle appearance
            opacity: 0.7, // Semi-transparent for subtlety
          },
          name: 'Lower Bound'
        };
        
        
        const upperBoundLine = {
          x: metadata.map(meta => meta.ds),
          y: metadata.map(meta => parseFloat(meta.yhat_upper)),
          type: 'scatter',
          mode: 'lines+markers',
          fill: 'tonexty',
          fillcolor: 'rgba(254, 224, 144, 0.5)', // A soft, warm fill for visual warmth and clarity
          line: { color: '#ffbb78' }, // Soft orange
          marker: {
            color: '#ffbb78',
            size: 6, // Keeping the markers subtle
            opacity: 0.7, // Semi-transparent for a gentle appearance
          },
          name: 'Upper Bound'
        };
    
        // Order is important here to make sure fill works correctly
        const transformedData = [lowerBoundLine, upperBoundLine, mainLine];
        setPlotData(transformedData);
      } else if (event.type == 'drops') { 
        const { wrapper_id, keys, unit, lookback } = event;
        const measureOption = event.measure_option;
        const initiallyStarted = event.initially_started;
        const lastActive = event.last_active;
        const dateOption = event.date_option;
        const filters = [{field: 'status', value: 'cancelled', comparer: '!='}] // event.filters
        const {type, variable} = measureOption;
        console.log(initiallyStarted, lastActive)
        const startDate = initiallyStarted - lookback * 86400;
        // const formattedStartDate = startDate.toISOString().split('T')[0];
        // const formattedLastUpdated = new Date(lastActive * 1000).toISOString().split('T')[0];
      
        const dateFilter = `start_date=${startDate}&end_date=${lastActive}`;
        const rawFilterOptions = keys.map(key => `${key.field_name}=${key.value}`).join(';');
        let filterOptions = rawFilterOptions; 

        const filterClauses = filters.map(filter => {
          const { field, value, comparer } = filter;
          return `${encodeURIComponent(field)}${encodeURIComponent(comparer)}${encodeURIComponent(value)}`;
        });
        if (filterOptions && filterClauses.length > 0) {
          filterOptions += ';' + filterClauses.join(';');
        } else if (!filterOptions && filterClauses.length > 0) {
          filterOptions = filterClauses.join(';');
        }
        filterOptions = encodeURIComponent(filterOptions);
      
        let requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&timeUnit=${unit}&filterOptions=${filterOptions}&${dateFilter}&user_id=${user.uid}`;
        // let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&timeUnit=${unit}&filterOptions=${filterOptions}&${dateFilter}`
      
        if (type === 'SUM') {
          requestUrl += `&variable=${variable}`;
        }
      
        const response = await fetch(requestUrl);
        const data = await response.json();

        console.log('drops data', data);
      
        const dateRange = generateDateRange(new Date(startDate * 1000), new Date(lastActive * 1000), unit);
        const mainLineData = dateRange.map(dateTimeInMillis => {
          const found = data.find(d => new Date(d.date).getTime() === dateTimeInMillis);
          return {
            date: new Date(dateTimeInMillis).toISOString().split('T')[0], // Convert back to 'YYYY-MM-DD' for display
            value: found ? parseFloat(found.value) : 0,
          };
        });
      
        mainLineData.sort((a, b) => new Date(a.date) - new Date(b.date));
      
        const mainLine = {
          x: mainLineData.map(item => item.date),
          y: mainLineData.map(item => item.value),
          type: 'scatter',
          mode: 'lines+markers',
          line: { color: '#adb0ff' },
          marker: {
            color: '#c2c4ff',
            size: 8,
            symbol: 'circle',
            opacity: 0.7
          },
          name: 'Main Data'
        };
      
        setPlotData([mainLine]);
      } else if (event.type == 'booking_pace') {
        const lengthDifference = event.metadata.baseline.length - event.metadata.data.length;
    
        const baselineXValues = Array.from(event.metadata.baseline.keys());
        const dataXValues = Array.from(event.metadata.data.keys()).map(x => x + Math.max(0, lengthDifference));
      
        const newPlotData = [
          {
            x: baselineXValues,
            y: event.metadata.baseline,
            type: 'scatter',
            mode: 'lines',
            name: 'Baseline',
            marker: {color: 'blue'},
          },
          {
            x: dataXValues,
            y: event.metadata.data,
            type: 'scatter',
            mode: 'lines',
            name: 'Data',
            marker: {color: 'red'},
          },
        ];
        setPlotData(newPlotData);
        setShowLegend(true);
        setXAxis('days before reservation');
        setYAxis('number of bookings');
      } else {
        console.log('event', event.initially_started, event.last_active)
        const layoutConfig = {
          title: 'Event Timeline',
          xaxis: {
            title: 'Date',
            type: 'date'
          },
          yaxis: {
            title: 'Value'
          },
          shapes: [
            // Line Vertical initially_started
            {
              type: 'line',
              x0: unixToGMT(event.initially_started),
              y0: 0,
              x1: unixToGMT(event.initially_started),
              y1: 1,
              line: {
                color: 'red',
                width: 2,
                dash: 'dashdot',
              },
              yref: 'paper',
            },
            // Line Vertical last_active
            {
              type: 'line',
              x0: unixToGMT(event.last_active),
              y0: 0,
              x1: unixToGMT(event.last_active),
              y1: 1,
              line: {
                color: 'green',
                width: 2,
                dash: 'dashdot',
              },
              yref: 'paper',
            }
          ]
        };


        setLayoutData(layoutConfig)

        let url = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/fetchKPIEvent?event_id=${event.id}` 
        url = `https://us-central1-circleops-5f8c7.cloudfunctions.net/fetchKPIEvent?event_id=${event.id}`

        fetch(url)
          .then((response) => {
            return response.json()
          }).then((response) => {
            console.log(response)
            const newPlotData = [
              {
                x: response.guidance.map(item => new Date(item.timestamp * 1000)), // Convert timestamp to 'YYYY-MM-DD'
                y: response.guidance.reduce((acc, item, index) => {
                  if (index === 0) {
                    acc.push(parseFloat(item.value));
                  } else {
                    acc.push(acc[acc.length - 1] + parseFloat(item.value));
                  }
                  return acc;
                }, []),
                type: 'scatter',
                mode: 'lines',
                name: 'Guidance',
                line: { color: 'blue' }, 
                marker: { size: 6, opacity: 0.5 }
              },
              {
                x: response.data.map(item => item.date),
                y: response.data.reduce((acc, item, index) => {
                  if (index === 0) {
                    acc.push(parseFloat(item.value));
                  } else {
                    acc.push(acc[acc.length - 1] + parseFloat(item.value));
                  }
                  return acc;
                }, []),
                type: 'scatter',
                mode: 'lines',
                name: 'Data',
                line: { color: 'red' }, 
                marker: { size: 8, opacity: 0.7 }
              }
            ];
            setPlotData(newPlotData)

          })
      }
    }
  
    fetchData();
  }, [event]);

  const handleSave = () => {
    setMessage('')
    setSpinning(true);
    const url = 'https://us-central1-circleops-5f8c7.cloudfunctions.net/updateEvent';

    const data = {
      event_id: event.id,
      params: {
        comment:  comment, 
        importance: importance
      }
    }

    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }).then((_) => {
      setMessage('saved successfully')
      setSpinning(false);
    }).catch((error) => {
      setMessage('error saving changes')
      setSpinning(false);
    })
  }

  const CreateIssueModal = ({ onClose }) => (
    <div style={modalStyle}>
      <BasicParagraphStrong>Create Issue</BasicParagraphStrong>
      <BasicParagraph2>Title</BasicParagraph2>
      <input 
        id="issueTitle"
        type="text" 
        placeholder="Enter issue title" 
        style={inputStyle}
      />
      <BasicParagraph2>Assign To:</BasicParagraph2>
      <input 
        id="assignTo"
        type="text" 
        placeholder="Assignee name or ID" 
        style={inputStyle}
      />
      <BasicParagraph2>Description</BasicParagraph2>
      <textarea 
        placeholder="Describe the issue..." 
        style={textareaStyle} 
      />
      <div style={buttonContainerStyle}>
        <StandardButton2 onClick={onClose}>Submit</StandardButton2>
        <StandardButton2 onClick={onClose}>Cancel</StandardButton2>
      </div>
    </div>
  );  

  const ShareInsightsModal = ({ onClose }) => (
    <div style={modalStyle}>
      <BasicParagraphStrong>Share Insight</BasicParagraphStrong>
      <input type="email" placeholder="Enter email address" style={inputStyle} />
      <textarea placeholder="Enter message..." style={textareaStyle} />
      <div style={buttonContainerStyle}>
        <StandardButton2 onClick={onClose}>Send</StandardButton2>
        <StandardButton2 onClick={onClose}>Cancel</StandardButton2>
      </div>
    </div>
  );

  const brainstormInsights = () => {
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false)
      setShowTable(true);
    }, 5000); 
  };


  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'flex-start',
    justifyContent: 'center', padding: '50px 0',  zIndex: 1000 }}>
      <div style={{ position: 'relative', width: '90%', maxHeight: 'calc(100% - 100px)',  backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', overflowY: 'auto' }}>
        <BasicParagraph2>{popupTitle}</BasicParagraph2>
        <BasicParagraph2>{event.id}</BasicParagraph2> <br /><br />
        <BasicParagraph2>{renderEventDescription(event)}</BasicParagraph2><br />
        <Plot
          style={{ width: '100%' }}
          data={plotData}
          layout={layoutData}
        />

        <div>
        <StandardButton onClick={() => setShowOptions(true)}>Submit Feedback</StandardButton>
        {/* <div style={{ display: 'flex'}}><StandardButton onClick={() => setShowCreateIssueModal(true)}>Create Issue</StandardButton> <StandardButton  onClick={() => setShowShareInsightsModal(true)}>Share Insight</StandardButton> <StandardButton onClick={brainstormInsights}>Generate Root Cause Report ✨</StandardButton> <StandardButton onClick={() => setShowOptions(true)}>Submit Feedback</StandardButton></div> */}
        <br />
        {showOptions && 
        <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          {['Important', 'Interesting', 'Not Interesting', 'None'].map((option) => (
            <button
              key={option}
              onClick={() => setImportance(option)}
              style={{
                padding: '10px 20px',
                flexGrow: 1,
                backgroundColor: importance === option ? 'rgba(147, 51, 234, 1)' : 'rgba(147, 51, 234, 0.1)', // Uses the primary color with full opacity for active and 10% for inactive
                color: importance === option ? 'white' : 'black', // Text color white for active and black for inactive
                border: '1px solid #dee2e6',
                borderRadius: '5px',
                cursor: 'pointer',
                outline: 'none'
              }}
            >
              {option}
            </button>
          ))}
        </div>
        <textarea
          placeholder="Add a comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{
            display: 'block',
            width: '100%',
            height: '100px',
            padding: '10px',
            marginBottom: '10px',
            color: 'black', // This sets the text color
            backgroundColor: '#fff', // Optionally set the background color to white for better contrast
            borderColor: '#ccc', // Optionally add a border color
            borderRadius: '4px', // Optionally round the corners
            borderWidth: '1px',
            outline: 'none' // Removes the outline to modernize the look (keep accessibility in mind)
          }}
        />

        <StandardButton onClick={handleSave}>
          Save
        </StandardButton>
        {spinning && <StyledSpinner />}
        <BasicParagraph2>{message}</BasicParagraph2>
        </>}
        {spinning && <StyledSpinner />}
        {showTable && <Table border="1">
            <Thead>
              <Th>Description</Th>
              <Th>Impact</Th>
            </Thead>
            <Tbody>
              <Trow>
                <Td>surge in <CodeBlock>average size of transaction</CodeBlock></Td>
                <Td>25%</Td>
              </Trow>
              <Trow>
                <Td>surge in <CodeBlock># of transactions</CodeBlock>,<CodeBlock>time=5pm-6pm</CodeBlock></Td>
                <Td>30%</Td>
              </Trow>
            </Tbody>
          </Table>}
      </div>

        <button onClick={() => { onClose(); setPlotData([]); }} style={{ position: 'absolute', top: '10px', right: '10px' }}>Close</button>      </div>
        {showCreateIssueModal && <CreateIssueModal onClose={() => setShowCreateIssueModal(false)} />}
        {showShareInsightsModal && <ShareInsightsModal onClose={() => setShowShareInsightsModal(false)} />}
    </div>
  );
};

const RootCauseReport = ({onClose }) => {
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1050',
    backgroundColor: '#fff',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: '50%',
    maxWidth: '600px'
  };
  
  const textareaStyle = {
    width: '100%',
    height: '100px',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px'
  };
  
  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px'
  };

  const codeStyle = {
    fontFamily: 'monospace',
    backgroundColor: '#f6f8fa',
    padding: '2px 4px',
    borderRadius: '4px',
    fontSize: '90%',
    border: '1px solid #e1e4e8',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between'
  };

  const unixToGMT = (unixTime) => {
    return new Date(unixTime * 1000).toISOString();
  };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'flex-start',
    justifyContent: 'center', padding: '50px 0',  zIndex: 1000 }}>
      <div style={{ position: 'relative', width: '90%', maxHeight: 'calc(100% - 100px)',  backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', overflowY: 'auto' }}>
      <BasicParagraphStrong>Root Cause Report</BasicParagraphStrong>
      <BasicParagraph2>Triggered By: <CodeBlock>Tom Jenkins</CodeBlock></BasicParagraph2>
      <BasicParagraph2>Date Ran: 4/28/2024 3:36pm PT</BasicParagraph2>
      <BasicParagraph2>Event Description: Your target for revenue in Boston is below guidance by 35.04%.</BasicParagraph2>
      <br /><br />
      <BasicParagraph2>Major factors: </BasicParagraph2>

      <Table border="1">
        <Thead>
          <Th>Description</Th>
          <Th>Attribution</Th>
          <Th></Th>
        </Thead>
        <Tbody>
          <Trow>
            <Td>Online Sales for Boston is down 20% from last quarter.</Td>
            <Td>10%</Td>
            <Td><StandardButton2>Visualize</StandardButton2></Td>
          </Trow>
          <Trow>
            <Td>Total Sales for Boston between 12:00pm - 2pm PT are down 10% from last quarter.</Td>
            <Td>5%</Td>
            <Td><StandardButton2>Visualize</StandardButton2></Td>
          </Trow>
          <Trow>
            <Td>Boston's overall growth rate is 10th percentile amongst all locations.</Td>
            <Td>5%</Td>
            <Td><StandardButton2>Visualize</StandardButton2></Td>
          </Trow>
        </Tbody>
      </Table>
      <br />
      <StandardButton>Save Report</StandardButton>
      <button onClick={() => { onClose()}} style={{ position: 'absolute', top: '10px', right: '10px' }}>Close</button> 
      </div>
    </div>
  );
};


const Alerts = () => {
  const navigate = useNavigate();
  const [sourceName, setSourceName] = useState('');
  const [template, setTemplate] = useState('');
  const [events, setEvents] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [stillActive, setStillActive] = useState("True");
  const [startedDuring, setStartedDuring] = useState();
  const [activeWithin, setActiveWithin] = useState();
  const [category, setCategory] = useState("All");
  const [selectedCategories, setSelectedCategories] = useState(['Important', 'Interesting', 'None']);
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const wrapper_id = queryParams.get('id');

  useEffect(() => {
    // Fetch the basic information of the source wrapper
    const docRef = doc(db, 'source_wrappers', wrapper_id);
    getDoc(docRef).then(docSnapshot => {
      if (docSnapshot.exists()) {
        let data = docSnapshot.data();
        setSourceName(data.name);
        setTemplate(data.source_type);
      }
    });
  }, [wrapper_id]);

  useEffect(() => {
    if (user) {
      handleFetchDocuments();
      const url = new URL('https://activitylog-2m4x72kr4q-uc.a.run.app');
      url.searchParams.append('user_id', user.uid);
      url.searchParams.append('activity_type', 'loaded_notifications');
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => console.log('Activity logged:', data))
      .catch(error => console.error('Error logging activity:', error));
    }
  }, [user]);
  
  
  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleAddFile = () => {
    navigate('/setup', { state: { id: wrapper_id } });
  };

  const handleFetchDocuments = () => {
    setSpinning(true);
    let requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/fetchEvents?wrapper_id=${wrapper_id}` + 
                                `&stillActive=${stillActive}&category=${category}&importance=${encodeURIComponent(selectedCategories.join(','))}&user_id=${user.uid}`
    // let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/fetchEvents?wrapper_id=${wrapper_id}` + 
    //                              `&stillActive=${stillActive}&category=${category}&importance=${encodeURIComponent(selectedCategories.join(','))}&user_id=${user.uid}`

    if (activeWithin) {
      requestUrl += `&activeWithin=${activeWithin}`;
    }

    if (startedDuring) {
      requestUrl += `&startedDuring=${startedDuring}`;
    }

    fetch(requestUrl)
      .then(response => response.json())
      .then(data => {
        console.log('events', data)
        setEvents(data);
        setSpinning(false);
      })
    
    // const eventsRef = collection(db, 'events');
    // const eventsQuery = query(eventsRef, where('wrapper_id', '==', wrapper_id), where('isOpen', '==', true));
    // getDocs(eventsQuery).then(querySnapshot => {
    //   const events = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //   setEvents(events);
    // });
  };

  return (
    <AnimationRevealPage disabled>
      <AppHeaderInternal />
      <Container>
          <InternalPageHeader>{sourceName}</InternalPageHeader>
          <BasicParagraph2><b>Source Type: {template}</b></BasicParagraph2>
          <div>
            <SourceMenu id={wrapper_id} />
            {/* <PrimaryButton onClick={handleAddFile}>Add New File</PrimaryButton>
            <PrimaryButton onClick={() => navigate('/settings?id=' + wrapper_id)}>Settings</PrimaryButton> */}
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
              <MenuOption>Still Active</MenuOption>
              <Dropdown options={["True", "False", "Either"]} selectedOption={stillActive} onSelect={(value) => setStillActive(value)} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
              <MenuOption>Started Within</MenuOption>
              <input type="number" min="0" placeholder="Days" value={startedDuring} onChange={(e) => setStartedDuring(e.target.value)} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
              <MenuOption>Active Within</MenuOption>
              <input type="number" min="0" placeholder="Days" value={activeWithin} onChange={(e) => setActiveWithin(e.target.value)} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
              <MenuOption>Category</MenuOption>
              <Dropdown options={["All", "Forecast", "Drops", "Reservations"]} selectedOption={category} onSelect={(value) => setCategory(value)} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
              <MenuOption>User Flags</MenuOption>
              <MultiSelectDropdown options={["Important", "Interesting", "Not Interesting", "None"]} selectedOptions={selectedCategories} onChange={setSelectedCategories} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
              <StandardButton onClick={handleFetchDocuments}>Fetch Documents</StandardButton>
            </div>
          </div>
          {spinning && <StyledSpinner /> }

          <Table border="1">
            <Thead>
              <Th>Description</Th>
              <Th>Date Started</Th>
              <Th>Last Updated</Th>
              <Th>Is Active</Th>
              <Th>Alert ID</Th>
            </Thead>
            <Tbody>
              {events.map((event) => {
                const dateStarted = new Date(event.initially_started * 1000).toGMTString();
                const lastUpdated = new Date(event.last_active * 1000).toGMTString();
                const isOpen = event.is_open ? 'Yes' : 'No';
                return (
                  <Trow key={event.id}>
                    <Td><a href="#" onClick={() => handleEventClick(event)}>{renderEventDescription(event)}</a></Td>
                    <Td>{dateStarted}</Td>
                    <Td>{lastUpdated}</Td>
                    <Td>{isOpen}</Td>
                    <Td>{event.alert_id}</Td>
                  </Trow>
                );
              })}
            </Tbody>
          </Table>
      </Container>
      {isModalOpen && <PopupModal onClose={() => setIsModalOpen(false)} event={selectedEvent} />}
      {/* <RootCauseReport /> */}
    </AnimationRevealPage>
  );
};

export default Alerts;
