import React from 'react';
import Plot from 'react-plotly.js';
import { useState, useEffect } from 'react';
import ViewToggle from './ViewToggle';  // Import the ViewToggle component
import { BasicParagraphStrong, Table, Trow, Thead, Tbody, Td, Th, StandardButton2, SecondaryButton } from 'components/misc/Layouts';
import NumberCard from 'components/NumberCard';
import { PerformanceDetails } from './Performance';

const ForecastInterpretation = ({ scenarioParams, trackerParams }) => {
  const [overviewStats, setOverviewStats] = useState([])
  const [evaluationData, setEvaluationData] = useState({})
  const [forecastYoyData, setForecastYoyData] = useState([])
  const [forecastPopData, setForecastPopData] = useState([]);
  const [isCumulative, setIsCumulative] = useState(false)
  const [factorData, setFactorData] = useState([])
  const [periodFactorData, setPeriodFactorData] = useState([])
  const [currentView, setCurrentView] = useState('main_view')
  const [detailData, setDetailData] = useState(null)

  useEffect(() => {
    console.log('scenarioParams', scenarioParams)
    if (scenarioParams.forecast_details) {
      const url = `https://us-central1-circleops-5f8c7.cloudfunctions.net/downloadFile?file_path=${scenarioParams.forecast_details}`
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((results) => {
          setEvaluationData(results);
        });
    }
  }, [scenarioParams]);

  const handleBackClick = () => {
    setCurrentView('main_view'); 
  };

  useEffect(() => {
    if (Object.keys(evaluationData).length == 0) {
      return;
    }
    
    const yoyTimestamps = Object.keys(evaluationData.comparison_data.raw_data.yoy.data).sort((a, b) => a - b);
    const yoyValues = yoyTimestamps.map(timestamp => evaluationData.comparison_data.raw_data.yoy.data[timestamp]);
  
    const offset = trackerParams.start_date - yoyTimestamps[0];
    const xValues = yoyTimestamps.map(timestamp => new Date((Number(timestamp) + offset) * 1000).toISOString());    
    let cumulativeForecast = 0
    let cumulativeYoy = 0
    setForecastYoyData([{
      x: evaluationData.forecast.map(item => new Date(item.timestamp * 1000).toISOString()), 
      y: evaluationData.forecast.map(a => {
        if (isCumulative) {
          cumulativeForecast += a.value;
          return cumulativeForecast;
        } else {
          return a.value;
        }
      }),
      type: 'scatter',
      name: 'Forecast'
    }, 
    {
      x: xValues, 
      y: yoyValues.map(value => {
        if (isCumulative) {
          cumulativeYoy += value;
          return cumulativeYoy;
        } else {
          return value;
        }
      }),
      type: 'scatter',
      name: 'One Year Ago'
    }])

    const popTimestamps = Object.keys(evaluationData.comparison_data.raw_data.pop.data).sort((a, b) => a - b);
    const popValues = popTimestamps.map(timestamp => evaluationData.comparison_data.raw_data.pop.data[timestamp]);
  
    const popOffset = trackerParams.start_date - popTimestamps[0];
    const xValuesPop = popTimestamps.map(timestamp => new Date((Number(timestamp) + popOffset) * 1000).toISOString());    

    cumulativeForecast = 0;
    let cumulativePop = 0;

    setForecastPopData([{
      x: evaluationData.forecast.map(item => new Date(item.timestamp * 1000).toISOString()), 
      y: evaluationData.forecast.map(a => {
        if (isCumulative) {
          cumulativeForecast += a.value;
          return cumulativeForecast;
        } else {
          return a.value;
        }
      }),
      type: 'scatter',
      name: 'Forecast'
    }, 
    {
      x: xValuesPop, 
      y: popValues.map(value => {
        if (isCumulative) {
          cumulativePop += value;
          return cumulativePop;
        } else {
          return value;
        }
      }),
      type: 'scatter', 
      name: 'Last Period'
    }])
  
    const aggregatedData = evaluationData.interpretation.aggregated_value;
    
    const filteredAggregatedData = aggregatedData.filter(item =>
      item.timestamp >= trackerParams.start_date && item.timestamp <= trackerParams.end_date
    );
    const forecastSum = filteredAggregatedData.reduce((sum, value) => sum + value.value, 0);
    const duration = (trackerParams.end_date - trackerParams.start_date) /86400 + 1
    console.log('duration', duration)

    setOverviewStats([
      {
        title: 'Original Forecast',
        value: forecastSum,
        per_day: forecastSum / duration,
        period: [trackerParams.start_date, trackerParams.end_date]
      },
      {
        title: 'Last Year',
        value: Object.values(evaluationData.comparison_data.raw_data.yoy.data).reduce((sum, value) => sum + value, 0),
        per_day: Object.values(evaluationData.comparison_data.raw_data.yoy.data).reduce((sum, value) => sum + value, 0) / Object.keys(evaluationData.comparison_data.raw_data.yoy.data).length,
        period: [evaluationData.comparison_data.raw_data.yoy.date_range.start, evaluationData.comparison_data.raw_data.yoy.date_range.end]
      },
      {
        title: 'Last Period',
        value: Object.values(evaluationData.comparison_data.raw_data.pop.data).reduce((sum, value) => sum + value, 0),
        per_day: Object.values(evaluationData.comparison_data.raw_data.pop.data).reduce((sum, value) => sum + value, 0) / Object.keys(evaluationData.comparison_data.raw_data.pop.data).length,
        period: [evaluationData.comparison_data.raw_data.pop.date_range.start, evaluationData.comparison_data.raw_data.pop.date_range.end]
      }
    ]);
    
  }, [evaluationData, isCumulative])

  const showDetails = (type) => {
    if (type == 'per_day') {
      setDetailData({
        'type': 'per_day',
        'data': evaluationData.interpretation.trend
      });
    } else if (type == 'weekly') {
      setDetailData({
        'type': 'weekly', 
        'data': evaluationData.interpretation.weekly
      });
    } else if (type == 'daily') {
      setDetailData({
        'type': 'daily',
        'data': evaluationData.interpretation.daily
      })
    }

    setCurrentView('detail');
  }

  useEffect(() => {
    if (Object.keys(evaluationData).length == 0) {
        return;
    }
    console.log('evaluation data', evaluationData)

    const aiContributions = {
        'baseline': 0, 
        'dayOfWeek': 0,
        'seasonal': 0
    };
    for (const item of evaluationData.forecast) {
        const date = item.timestamp;
        const dateObject = new Date(new Date(date * 1000).toUTCString());
        const baselineFormattedDate = dateObject.toISOString().split('T')[0];
        const dayOfWeek = dateObject.toLocaleString('en-US', { weekday: 'long', timeZone: 'UTC' });
        const shortDate = dateObject.toLocaleDateString('en-US', { month: 'short', day: '2-digit', timeZone: 'UTC' }).replace(',', '');
        const baselineValue = evaluationData.interpretation.trend.data.trends[baselineFormattedDate];
        const dayOfWeekValue = evaluationData.interpretation.weekly.data[dayOfWeek];
        const seasonalValue = evaluationData.interpretation.daily.data[shortDate];

        aiContributions['baseline'] += baselineValue;
        aiContributions['dayOfWeek'] += dayOfWeekValue;
        aiContributions['seasonal'] += seasonalValue;
    }

    const numDaysAi = evaluationData.forecast.length;
    for (const key in aiContributions) {
        aiContributions[key] /= numDaysAi;
    }
    console.log('aiContributions2', aiContributions, numDaysAi)
    aiContributions['total'] = Object.values(aiContributions).reduce((a, b) => a + b);

    const pastContributions = {
        'baseline': 0,
        'dayOfWeek': 0, 
        'seasonal': 0, 
        'positiveNoise': 0, 
        'negativeNoise': 0, 
        'positiveAnomalies': 0, 
        'negativeAnomalies': 0
    };

    const rawDataYoy = evaluationData.comparison_data.raw_data.yoy.data;
    const numDaysYoy = Object.keys(rawDataYoy).length;

    for (const timestamp of Object.keys(rawDataYoy)) {
        const dateObject = new Date(new Date(timestamp * 1000).toUTCString());
        const baselineFormattedDate = dateObject.toISOString().split('T')[0];
        const dayOfWeek = dateObject.toLocaleString('en-US', { weekday: 'long', timeZone: 'UTC' });
        const shortDate = dateObject.toLocaleDateString('en-US', { month: 'short', day: '2-digit', timeZone: 'UTC' }).replace(',', '');

        const baselineValue = evaluationData.interpretation.trend.data.trends[baselineFormattedDate];
        const dayOfWeekValue = evaluationData.interpretation.weekly.data[dayOfWeek];
        const seasonalValue = evaluationData.interpretation.daily.data[shortDate];

        const delta = rawDataYoy[timestamp] - (baselineValue + dayOfWeekValue + seasonalValue);
        if (delta > 0) {
            if (delta > evaluationData.interpretation.noise.positive[baselineFormattedDate]) {
                pastContributions['positiveAnomalies'] += delta;
            } else {
                pastContributions['positiveNoise'] += delta;
            }
        } else {
            if (delta < evaluationData.interpretation.noise.negative[baselineFormattedDate]) {
                pastContributions['negativeAnomalies'] += delta;
            } else {
                pastContributions['negativeNoise'] += delta;
            }
        }

        pastContributions['baseline'] += baselineValue;
        pastContributions['dayOfWeek'] += dayOfWeekValue;
        pastContributions['seasonal'] += seasonalValue;
    }

    for (const key in pastContributions) {
        pastContributions[key] /= numDaysYoy;
    }
    pastContributions['total'] = Object.values(pastContributions).reduce((a, b) => a + b);

    const desiredOrder = [
        'baseline',
        'dayOfWeek',
        'seasonal',
        'negativeNoise',
        'positiveNoise',
        'negativeAnomalies',
        'positiveAnomalies',
        'total'
    ];

    const orderData = (contributions) => {
        return desiredOrder.map(key => ({ key, value: contributions[key] || 0 }));
    };

    const sortedAiPairs = orderData(aiContributions);
    const aiX = sortedAiPairs.map(pair => pair.key);
    const aiY = sortedAiPairs.map(pair => pair.value);

    const sortedPastPairs = orderData(pastContributions);
    const pastX = sortedPastPairs.map(pair => pair.key);
    const pastY = sortedPastPairs.map(pair => pair.value);

    const factorDataTemp = [
        {
            x: aiX,
            y: aiY,
            type: 'bar',
            name: 'Forecast',
            marker: {
                color: 'blue'
            }
        }, 
        {
            x: pastX,
            y: pastY,
            type: 'bar', 
            name: 'One Year Ago'
        }
    ];

    setFactorData(factorDataTemp);

    const pastPopContributions = {
        'baseline': 0,
        'dayOfWeek': 0, 
        'seasonal': 0, 
        'positiveNoise': 0, 
        'negativeNoise': 0, 
        'positiveAnomalies': 0, 
        'negativeAnomalies': 0
    };

    const rawDataPop = evaluationData.comparison_data.raw_data.pop.data;
    const numDaysPop = Object.keys(rawDataPop).length;

    for (const timestamp of Object.keys(rawDataPop)) {
        const dateObject = new Date(timestamp * 1000);
        const baselineFormattedDate = dateObject.toISOString().split('T')[0];
        const dayOfWeek = dateObject.toLocaleString('en-US', { weekday: 'long', timeZone: 'UTC'  });
        const shortDate = dateObject.toLocaleDateString('en-US', { month: 'short', day: '2-digit', timeZone: 'UTC'  }).replace(',', '');

        const baselineValue = evaluationData.interpretation.trend.data.trends[baselineFormattedDate];
        const dayOfWeekValue = evaluationData.interpretation.weekly.data[dayOfWeek];
        const seasonalValue = evaluationData.interpretation.daily.data[shortDate];

        const delta = rawDataPop[timestamp] - (baselineValue + dayOfWeekValue + seasonalValue);
        if (delta > 0) {
            if (delta > evaluationData.interpretation.noise.positive[baselineFormattedDate]) {
                pastPopContributions['positiveAnomalies'] += delta;
            } else {
                pastPopContributions['positiveNoise'] += delta;
            }
        } else {
            if (delta < evaluationData.interpretation.noise.negative[baselineFormattedDate]) {
                pastPopContributions['negativeAnomalies'] += delta;
            } else {
                pastPopContributions['negativeNoise'] += delta;
            }
        }

        pastPopContributions['baseline'] += baselineValue;
        pastPopContributions['dayOfWeek'] += dayOfWeekValue;
        pastPopContributions['seasonal'] += seasonalValue;
    }

    for (const key in pastPopContributions) {
        pastPopContributions[key] /= numDaysPop;
    }
    pastPopContributions['total'] = Object.values(pastPopContributions).reduce((a, b) => a + b);

    const sortedPastPopPairs = orderData(pastPopContributions);
    const pastPopX = sortedPastPopPairs.map(pair => pair.key);
    const pastPopY = sortedPastPopPairs.map(pair => pair.value);

    const factorDataPopTemp = [
        {
            x: aiX,
            y: aiY,
            type: 'bar',
            name: 'Forecast',
            marker: {
                color: 'blue'
            }
        }, 
        {
            x: pastPopX,
            y: pastPopY,
            type: 'bar', 
            name: 'Previous Period'
        }
    ];

    setPeriodFactorData(factorDataPopTemp);
}, [evaluationData]);

  return (
    <>
    {currentView == 'main_view' && <div style={{ width: '100%', boxSizing: 'border-box', padding: '0 20px' }}>
      <BasicParagraphStrong>Forecast Interpretation</BasicParagraphStrong>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '0px' }}>
        {overviewStats.map((component, index) => (
          <NumberCard
            key={index}
            title={component.title}
            period={component.period}
            value={component.value}
            per_day={component.per_day}
          />
        ))}
      </div>
      <ViewToggle isCumulative={isCumulative} onChange={setIsCumulative} />

      <Plot 
        data={forecastYoyData}
        layout={{ 
          title: 'Forecast vs. One Year Ago',
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
      />
      <Plot 
        data={factorData}
        layout={{
          title: 'Explanation vs. One Year Ago'
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
      />
      <Table>
        <Thead>
          <Th>Factor</Th>
          <Th></Th>
        </Thead>
        <Tbody>
          <Trow>
            <Td>Baseline</Td>
            <Td><StandardButton2 onClick={() => showDetails('per_day')}>Show</StandardButton2></Td>
          </Trow>
          <Trow>
            <Td>Day of Week</Td>
            <Td><StandardButton2 onClick={() => showDetails('weekly')}>Show</StandardButton2></Td>
          </Trow>
          <Trow>
            <Td>Seasonal</Td>
            <Td><StandardButton2 onClick={() => showDetails('daily')}>Show</StandardButton2></Td>
          </Trow>
        </Tbody>
      </Table>

      <Plot 
        data={forecastPopData}
        layout={{ 
          title: 'Forecast vs. One Period Ago',
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
      />
      <Plot 
        data={periodFactorData}
        layout={{
          title: 'Explanation vs. One Period Ago'
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
      />
      <Table>
        <Thead>
          <Th>Factor</Th>
          <Th></Th>
        </Thead>
        <Tbody>
          <Trow>
            <Td>Baseline</Td>
            <Td><StandardButton2 onClick={() => showDetails('per_day')}>Show</StandardButton2></Td>
          </Trow>
          <Trow>
            <Td>Day of Week</Td>
            <Td><StandardButton2 onClick={() => showDetails('weekly')}>Show</StandardButton2></Td>
          </Trow>
          <Trow>
            <Td>Seasonal</Td>
            <Td><StandardButton2 onClick={() => showDetails('daily')}>Show</StandardButton2></Td>
          </Trow>
        </Tbody>
      </Table>
    </div>}
    {
          currentView == 'detail' && 
          <>
            <StandardButton2 onClick={handleBackClick}>Back to Summary</StandardButton2>
            <PerformanceDetails details={detailData} />
          </>
        }
    </>
  );
};

export default ForecastInterpretation;