import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithPaddingXl, ContentWithVerticalPadding, PlainText, PrimaryButton, SecondaryButton, Description, InputContainer, PreviewButton } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Link, useNavigate } from "react-router-dom";
import HeaderInternal from "landing_components/HeaderInternal";
import { motion } from "framer-motion";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import hex_md5 from 'md5-hex';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation } from 'react-router-dom';
import {storage} from '../firebase'


const Stage = ({ label, isActive }) => {
  const stageStyle = {
    padding: '10px 20px',
    border: '1px solid #ccc',
    backgroundColor: isActive ? '#3498db' : '#ddd', // Sky blue color
    color: isActive ? 'white' : 'black',
    cursor: 'pointer',
    flex: 1,
    textAlign: 'center'
  };

  return (
    <div style={stageStyle}>
      {label}
    </div>
  );
};

// Stages bar component
const StagesBar = ({ currentStage }) => {
  const stages = ['1. Upload Data', '2. Review'];
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px'
  };

  return (
    <div style={containerStyle}>
      {stages.map((stage, index) => (
        <Stage 
          key={index} 
          label={stage} 
          isActive={currentStage === index}
        />
      ))}
    </div>
  );
};


const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}
  .resizeHandleWrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;
export default ({
  features = null,
  primaryButtonUrl = "/getting_started",
  primaryButtonText = "Get Started",
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks,
  heading = "Add a source",
  description = "Designed for teams of scale - Speed optimizes your HR workflows so your teams can focus on the work that most interests you. Upload your HR documentation and references and reap benefits immediately."
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [step, setStep] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [tablePreview, setTablePreview] = useState([])
  const [contextValues, setContextValues] = useState({});
  const [sheetDescription, setSheetDescription] = useState("")
  const [error_message, setErrorMessage] = useState("")
  const [setupOption, setSetupOption] = useState("uploadCSV")

  const [ipAddress, setIpAddress] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [port, setPort] = useState("");
  const [queryFrequency, setQueryFrequency] = useState("once/hour");
  const [query, setQuery] = useState("");
  const [database, setDatabase] = useState("");

  const location = useLocation();

  // Accessing file_id from location state
  const state = location.state || {};
  console.log('state', state)


  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    console.log('user', user)
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      navigate('/sign_in')
    }
  });
  const [user, loading, error] = useAuthState(auth);

  const handleContextChange = (columnName, value) => {
    setContextValues(prevState => ({
        ...prevState,
        [columnName]: value
    }));
  };

  function fileChange(event) {
    setSelectedFile(event.target.files[0]);
  }

  function submitData() {

    if (setupOption == "uploadCSV") {
      if (!selectedFile) {
        console.log('no selected filename')
        setErrorMessage("Error: there is no file attached")
        return 
      }
      setIsUploading(true)
      setErrorMessage("")
      let current_timestamp = Math.floor(Date.now() / 1000).toString();
      let file_id = hex_md5(current_timestamp + selectedFile.filename);
      const storageRef = ref(storage, 'data_sources/' + file_id);
      const url = 'http://127.0.0.1:5001/circleops-5f8c7/us-central1/addNewDataSource'

      uploadBytes(storageRef, selectedFile).then((snapshot) => {
        const formData = new FormData();
        formData.append('type', "uploadCSV")
        formData.append('name', name);
        formData.append('fileName', file_id);
        formData.append('userId', user.uid)
        console.log('state', state)
        if (Object.keys(state).length > 0) {
          formData.append('existingId', state.id);
        } else {
          formData.append('existingId', '');
        }

        return fetch(url, {
          method: 'POST',
          body: formData
        }).then(() => {
          navigate('/dashboard')
        }).catch((error) => {
          setErrorMessage("Error: " + error)
          setIsUploading(false)
        })
      })
    } else if (setupOption === "connectDB") {
      const url = 'http://127.0.0.1:5001/circleops-5f8c7/us-central1/addNewDataSource';
    
      const formData = new FormData();
      formData.append('type', "connectDB");
      formData.append('name', name);
      formData.append('ipAddress', ipAddress);
      formData.append('username', username);
      formData.append('password', password);
      formData.append('port', port);
      formData.append('queryFrequency', queryFrequency);
      formData.append('query', query);
      formData.append('userId', user.uid);
      formData.append('database', database)
    
      if (Object.keys(state).length > 0) {
        formData.append('existingId', state.id);
      } else {
        formData.append('existingId', '');
      }
    
      return fetch(url, {
        method: 'POST',
        body: formData
      }).then(() => {
        navigate('/dashboard')
      }).catch((error) => {
        setErrorMessage("Error: " + error)
        setIsUploading(false)
      })
    }
  } 

  return (
    <AnimationRevealPage disabled>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <HeaderInternal />
        <br /> <br />
          <Content2Xl>
            <h1>Add a Source</h1>

            <PrimaryButton onClick={() => {navigate('/source?id=' + state.id)}}> Alert Templates </PrimaryButton> <PrimaryButton onClick={() => {navigate('/bots?id=' + state.id)}}>Overview</PrimaryButton> <PrimaryButton onClick={() => {navigate('/alerts?id=' + state.id)}}>Notifications</PrimaryButton>  <PrimaryButton>Add New File</PrimaryButton> <PrimaryButton onClick={() => {navigate('/settings?id=' + state.id)}}>Settings</PrimaryButton>

            {state.id ? <p>You are adding a file to collection: {state.id} </p> : <p>Creating a new source from scratch</p>}
            
              {step === 0 && 
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Description> Source Name </Description> 
                  <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Enter name" style={{ border: '1px solid black' }}  />
                  <br /> <br />
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <input 
                      type="checkbox" 
                      value="uploadCSV"
                      checked={setupOption === "uploadCSV"}
                      onChange={e => setSetupOption(e.target.value)} 
                      style={{ verticalAlign: 'middle', marginRight: '5px', width: 'auto'}} // Reduced margin-right
                    />
                    <label style={{ verticalAlign: 'middle', fontSize: '14px', lineHeight: '1' }}>Upload your CSV now:</label>
                  </div>
                  <InputContainer>
                    <form>
                      <input type="file" accept="application/csv" onChange={fileChange} /> 
                    </form>
                  </InputContainer>

                  {!state && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <input 
                      type="checkbox" 
                      value="withoutFile"
                      checked={setupOption == "withoutFile"} 
                      onChange={e => setSetupOption(e.target.value)} 
                      style={{ verticalAlign: 'middle', marginRight: '5px', width: 'auto'}} // Reduced margin-right
                    />
                    <label style={{ verticalAlign: 'middle', fontSize: '14px', lineHeight: '1' }}>Set-Up Without File</label>
                  </div>}

                  {/* Add this block for the database connection option */}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <input 
                      type="checkbox" 
                      value="connectDB"
                      checked={setupOption == "connectDB"} 
                      onChange={e => setSetupOption(e.target.value)} 
                      style={{ verticalAlign: 'middle', marginRight: '5px', width: 'auto'}} // Reduced margin-right
                    />
                    <label style={{ verticalAlign: 'middle', fontSize: '14px', lineHeight: '1' }}>Connect to a Database</label>
                  </div>
                  {setupOption === "connectDB" && (
                    <div>
                      <label>IP Address:</label>
                      <input type="text" onChange={e => setIpAddress(e.target.value)} />

                      <label>Port:</label>
                      <input type="number" onChange={e => setPort(e.target.value)} />

                      <label>Database:</label>
                      <input type="text" onChange={e => setDatabase(e.target.value)} />
                      
                      <label>Username:</label>
                      <input type="text" onChange={e => setUsername(e.target.value)} />

                      <label>Password:</label>
                      <input type="password" onChange={e => setPassword(e.target.value)} />

                      <label>Query Frequency:</label>
                      <div>
                      <select value={queryFrequency} onChange={e => setQueryFrequency(e.target.value)}>
                        <option value="once/hour">Once/Hour</option>
                        <option value="once/day">Once/Day</option>
                      </select>
                      </div>
                      <br />

                      <label>Query:</label>
                      <textarea rows="4" cols="50" onChange={e => setQuery(e.target.value)} />                    </div>
                  )}
                  <br /> <br />
                  <PreviewButton onClick={() => submitData()} disabled={isUploading}> Submit </PreviewButton> <br />
                  <p style={{ color: 'red' }}> {error_message} </p>
                  {isUploading && <p> Uploading data... </p>}
                </div>}
          </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
}