import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import default styles
import chroma from 'chroma-js';

const CustomCalendar = ({ calendar_data }) => {
  const [value, setValue] = useState(new Date());
  const [data, setData] = useState({});

  useEffect(() => {
    const processedData = {};

    calendar_data.forEach((entry) => {
      const dateISO = new Date(entry.timestamp * 1000).toISOString().split('T')[0];
      const previousValue = entry.previous_value;
      const value = entry.value;
      const percentage = previousValue ? ((value - previousValue) / previousValue) * 100 : 0;

      processedData[dateISO] = {
        value,
        percentage
      };
    });

    setData(processedData);
  }, [calendar_data]);

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const dateString = date.toISOString().split('T')[0];
      const tileData = data[dateString];

      if (tileData) {
        const colorScale = chroma.scale(['blue', 'white', 'red']).domain([-100, 0, 100]);
        const backgroundColor = colorScale(tileData.percentage).hex();

        return (
          <div style={{ 
            width: '100%', 
            height: '100%', 
            backgroundColor: backgroundColor, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            padding: '5px',
            boxSizing: 'border-box',
            color: 'black'
          }}>
            <div>{tileData.value}</div>
            <div>{tileData.percentage >= 0 ? `+${tileData.percentage.toFixed(2)}%` : `${tileData.percentage.toFixed(2)}%`}</div>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div style={{ width: '100%' }}>
      <Calendar
        onChange={setValue}
        value={value}
        tileContent={tileContent}
        tileClassName="custom-tile"
        style={{ width: '100%', color: 'black' }}
      />
      <style jsx>{`
        .react-calendar {
          width: 100%;
          color: black;
        }

        .react-calendar .react-calendar__tile {
          flex-grow: 1;
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
        }
      `}</style>
    </div>
  );
};

export default CustomCalendar;
