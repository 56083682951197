import React from 'react';
import { Card } from 'antd';

function NumberCard(props) {
    const cardStyle = {
        width: 300,
        marginTop: 16,
        border: '1px solid #e8e8e8',
        borderRadius: '8px',
        padding: '16px',
        textAlign: 'center',
    };


    const headerStyle = {
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'black',
        textAlign: 'center'
    };

    const formatPeriod = (period) => {
        const start = new Date(period[0] * 1000).toUTCString().split(' ').slice(0, 4).join(' ');
        const end = new Date(period[1] * 1000).toUTCString().split(' ').slice(0, 4).join(' ');
        return `${start} - ${end}`;
    };

    return (
        <Card style={cardStyle} title={<div style={headerStyle}>{props.title}</div>}>
            <div style={{ color: 'black'}}>{props.value.toFixed(2)}</div>
            {props.per_day !== undefined && (
                <div style={{ color: 'black' }}>Per Day: {props.per_day.toFixed(2)}</div>
            )}
            <div style={{ color: 'black' }}>{formatPeriod(props.period)}</div>
        </Card>
    );
}

export default NumberCard;