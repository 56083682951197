import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { Slide, Zoom } from "react-awesome-reveal";
import { ContactProps, ValidationTypeProps } from "./types";
import { useForm } from "../../common/utils/useForm";
import validate from "../../common/utils/validationRules";
import { Button } from "../../common/Button";
import Block from "../Block";
import Input from "../../common/Input";
import TextArea from "../../common/TextArea";
import { ContactContainer, FormGroup, Span, ButtonContainer } from "./styles";
import { BasicParagraph, BasicParagraph2, MainHeader, StandardButton, SubHeader } from "components/misc/Layouts";
import { Subheading } from "internal_pages/AssetSource";
import { Container } from "components/misc/Layouts";

const Contact = ({ title, content, id, t }: ContactProps) => {
  const { values, errors, handleChange, handleSubmit } = useForm(
    validate
  ) as any;

  const ValidationType = ({ type }: ValidationTypeProps) => {
    const ErrorMessage = errors[type];
    return (
      <Zoom direction="left">
        <Span erros={errors[type]}>{ErrorMessage}</Span>
      </Zoom>
    );
  };

  return (
    <Container>
        <MainHeader>Want to Learn More or Ready to Get Started?</MainHeader><br />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <BasicParagraph>Send us an email at ai@circleops.ai. We'd be happy to do a free consultation for you.</BasicParagraph> <br />
        </div>
    </Container>
  );
};

export default withTranslation()(Contact);
