import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { BasicParagraph2, Container, Content2Xl, ContentWithPaddingXl, InternalPageHeader, PreviewButton } from "components/misc/Layouts";
import { useLocation } from 'react-router-dom';
import { doc, getDoc} from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from "components/misc/Buttons";
import AppHeaderInternal from "landing_page_2024/AppHeaderInternal";
import { EntryComponent } from "components/parts/EntryComponent";
import { PopupModal } from "components/SubComponents/TargetCreator";
import SourceMenu from "components/SubComponents/SourceMenu";
import { StandardButton } from "components/misc/Layouts";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

export default ({
}) => {  
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [properties, setProperties] = useState([]);
  const [sourceName, setSourceName] = useState("temproary"); 
  const [template, setTemplate] = useState("temproary"); 
  const [trackerData, setTrackerData] = useState([])
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    const docRef = doc(db, 'source_wrappers', id);

    getDoc(docRef)
    .then(docSnapshot => {
        let data = docSnapshot.data()
        setSourceName(data.name)
        setTemplate(data.source_type)
        setProperties(data.field_context)
        setTrackerData(data.kpi_trackers2)
    })

    if (user) {
      console.log('user id', user.uid);
  
      const url = new URL('https://activitylog-2m4x72kr4q-uc.a.run.app');
      url.searchParams.append('user_id', user.uid);
      url.searchParams.append('id', id);
      url.searchParams.append('activity_type', 'loaded_targets');
  
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => console.log('Activity logged:', data))
      .catch(error => console.error('Error logging activity:', error));
    }
  }, [id, user])

  return (
    <AnimationRevealPage disabled>
      <AppHeaderInternal />
      <Container>
        <br /> <br />
        <InternalPageHeader>{sourceName}</InternalPageHeader>
        <BasicParagraph2>Source Type: {template} </BasicParagraph2>
        <SourceMenu id={id} />

        <div>        
        {
          trackerData.map((component_id) => {
            return (
              <>
              <EntryComponent component_id={component_id} kpi_tracker={true} />
              <hr />
              </>
            ); 
          })
        }
      </div> 
      </Container>
    </AnimationRevealPage>
  );
};
