import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Button from '@mui/material/Button';
import { ContentBlockProps } from "./types";
import { Fade } from "react-awesome-reveal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; 
import { TiVendorMicrosoft } from 'react-icons/ti';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from "react";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

import {
  RightBlockContainer,
  Content,
  ContentWrapper,
  ButtonWrapper,
} from "./styles";
import { PlainText } from "components/misc/Layouts";

const GetStarted = ({
  t,
  id,
}: ContentBlockProps) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false); 
  const [errorMessage, setErrorMessage] = useState("")

  const handleMicrosoftLogin = () => {
    setLoader(true);
    const provider = new OAuthProvider('microsoft.com');
    const auth = getAuth();
    setErrorMessage("")

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);

        if (credential) {
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            console.log(accessToken, idToken)
            navigate('/dashboard')
        } else {
            console.warn("Credential is null. Cannot extract tokens.");
        }
        
        setLoader(false);
      })
      .catch((error) => {
        // Handle error.
        console.error(error);
        setErrorMessage("Sorry. The email you selected has not been preapproved. If you don't have an account, please reach out to info@circleops.ai to get started.")
        setLoader(false);
      });
  }


  return (
    <RightBlockContainer>
      <Row justify="space-between" align="middle" id={id}>
        <Col lg={11} md={11} sm={11} xs={24}>
          <ContentWrapper>
            <h6>Schedule a Demo </h6>
            <PlainText>What you can expect: </PlainText>
            <PlainText>Discuss your current data workflows and how we can improve them</PlainText>
            <PlainText>Live walkthrough of the platform + available tools</PlainText>
            <PlainText>Get you set up with some initial data sources immediately after the call.</PlainText>
          </ContentWrapper>
        </Col>
        <Col lg={11} md={11} sm={12} xs={24}>
          <Button
              variant="outlined"
              startIcon={<TiVendorMicrosoft style={{ color: '#F25022'}} />}
              fullWidth
              size="large"
              onClick={() => handleMicrosoftLogin()}
          >
              {loader ? <CircularProgress style={{ color: 'black'}}  size={26} />: 'Login with Microsoft'}
          </Button>
          <p> {errorMessage} </p>
        </Col>
      </Row>
    </RightBlockContainer>
  );
};

export default withTranslation()(GetStarted);