import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useRef, useEffect, useState } from 'react';
import "mapbox-gl/dist/mapbox-gl.css";
// import converted_mock_data from './convert_mock_data_trends.json'
import { PlainText, SecondaryButton } from 'components/misc/Layouts';
import { doc, getDoc} from 'firebase/firestore';
import Dropdown from 'components/parts/Dropdown';
import { db } from '../../firebase';

mapboxgl.accessToken = 'pk.eyJ1IjoibWNoYW5nY2lyY2xlb3BzIiwiYSI6ImNscmxhdjFjdjBtOWsybGtkY2trMjVvdGMifQ.L9iV0efDIolzkizB89zmBw';


const MapDisplay2 = ({id, symbol, visualize_attribute, properties}) => {
    const mapContainerRef = useRef(null);
    const map = useRef(null);

    const [lng] = useState(-97.7431);
    const [lat] = useState(30.2672);
    const [zoom] = useState(3);

    useEffect(() => {
        console.log('properties', properties)
    }, [properties])

    function calculateThresholds(dataArray, numberOfThresholds) {
        const max = Math.max(dataArray);
        const min = Math.min(dataArray);
        const step = (max - min) / numberOfThresholds;
        let thresholds = [];
        for (let i = 1; i <= numberOfThresholds; i++) {
            thresholds.push(Math.round(min + i * step));
        }
        return thresholds;
    }

    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/navigation-night-v1',
            center: [lng, lat],
            zoom: zoom,
            attributionControl: false
          });
      
        // Add our navigation control (the +/- zoom buttons)
        map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      
        // Map onload event 
        map.current.on("load", ()=> {
            // Nifty code to force map to fit inside container when it loads
            map.current.resize();
            map.current.loadImage('https://cdn0.iconfinder.com/data/icons/social-messaging-ui-color-shapes/128/alert-triangle-red-512.png', (error, image) => {
                if (map.current.getLayer('data-values')) {
                    map.current.removeLayer('data-values')
                }
                
                if (map.current.getLayer('data-pins')) {
                    map.current.removeLayer('data-pins')
                }
    
                const dataSource = map.current.getSource('data')
                
                let data_object = {}
                let data_properties = []
                if (!visualize_attribute) {
                    data_properties = properties
                    data_object = {
                        'id': 'data-pins',
                        'type': 'circle',
                        'source': 'data',
                        'paint': {
                            'circle-radius': 4,
                            'circle-stroke-width': 2,
                            'circle-color': 'red',
                            'circle-stroke-color': 'white'
                        }
                    }
                } else if (visualize_attribute == 'trends') {
                    data_properties = properties.filter(item =>
                        item.associations && item.associations.some(assoc =>
                          assoc.name === 'trends'
                        )
                    );
                    console.log('data properties', data_properties)
                    data_object = {
                        'id': 'data-pins',
                        'type': 'circle',
                        'source': 'data', // Ensure this is the ID of the source that contains your GeoJSON data
                        'paint': {
                            'circle-radius': [
                                'interpolate', ['linear'], ['abs', ['get', 'trend_magnitude']],
                                1, 5,  // Absolute magnitude of 1 results in a circle radius of 5
                                100, 15 // Absolute magnitude of 100 results in a circle radius of 15
                            ],
                            'circle-color': [
                                'case',
                                ['==', ['get', 'trend_magnitude'], 0], '#808080', // Grey for no change
                                ['<', ['get', 'trend_magnitude'], 0],
                                  // Negative values: Gradient from light red to dark red
                                  ['interpolate', ['linear'], ['get', 'trend_magnitude'],
                                    -100, '#8b0000', // Light red8b0000
                                    0, '#ffcccc'     // Dark red
                                  ],
                                // Default case for positive values: Gradient from light green to dark green
                                ['interpolate', ['linear'], ['get', 'trend_magnitude'],
                                  0, '#b3ff66',   // Light green
                                  100, '#006400'  // Dark green
                                ]
                              ]
                              
                        }
                    }
                } else {
                    map.current.addImage('alert', image);
                    console.log('metadata', properties)
                    data_properties = properties.filter(item =>
                        item.associations && item.associations.some(assoc =>
                          assoc.name === 'anomalies_list' && assoc.value && assoc.value.length > 0
                        )
                    );
                      
                    data_object = {
                        'id': 'data-pins',
                        'type': 'symbol',
                        'source': 'data',
                        'layout': {
                            'icon-image': 'alert', // reference the image
                            'icon-size': 0.05
                        }
                    }
                }

                if (dataSource) {
                    dataSource.setData(convertToGeoJSON(data_properties))
                } else {
                    map.current.addSource('data', {
                        type: 'geojson',
                        data: convertToGeoJSON(data_properties)
                    });
                }

                map.current.addLayer(data_object)
    
                const popup = new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false
                });
        
                
                map.current.on('mouseleave', 'data-pins', () => {
                    popup.remove();
                    map.current.getCanvas().style.cursor = '';
                })
    
                map.current.on('mouseenter', 'data-pins', (e) => {
                    map.current.getCanvas().style.cursor = 'pointer';
                    
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const properties = e.features[0].properties;
    
                    let popupHtml = `ID: ${properties.id} <br /> <br />`
                    Object.keys(properties).forEach(key => {
                        if (key == 'trend_magnitude') {
                            return 
                        }
                        let data = properties[key]
                        console.log('key', data)
                        
                        if (key == 'anomalies_list') {
                            let parsed_anomaly = JSON.parse(data)
                            popupHtml += `<strong>anomalies:</strong> ${parsed_anomaly.length} instances found. <br />`
                        } else if (key == 'trends') {
                            let parsed_trend = JSON.parse(data)
                            popupHtml += `<strong>Trend Data</strong>: <br />Previous Value: ${parsed_trend[2]} <br />New Value: ${parsed_trend[3]} <br />Percent Change: ${parsed_trend[4]}%  <br />`
                        } else {
                            popupHtml += `<strong>${key}</strong>: ${data} <br />`
                        }
                    })
    
    
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }
                    
                    popup.setLngLat(coordinates).setHTML(popupHtml).addTo(map.current);
                });
                
                map.current.on('mouseleave', 'data-pins', () => {
                    popup.remove();
                    map.current.getCanvas().style.cursor = '';
                })
            })
        })
    }, [visualize_attribute, properties])


    function convertToGeoJSON(convertedData) {
        let features = []
        let tempAssetData = {}
        console.log('metadata', convertedData)
        convertedData.forEach(metadata => {
            if (!(metadata.key in tempAssetData)) {
                tempAssetData[metadata.key] = { coords: metadata.coords, properties: [] };
            }
            metadata.associations.forEach((association) => {
                let property = { name: association.name };
                property.value = association.value;
                tempAssetData[metadata.key].properties.push(property);
            });
        })

        features = Object.keys(tempAssetData).map(id => {
            const propertiesObject = tempAssetData[id].properties.reduce((obj, property) => {
                obj[property.name] = property.value;
                return obj;
            }, {});
            return {
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [tempAssetData[id].coords.lat, tempAssetData[id].coords.long] // Use the found coordinates
                },
                "properties": {...propertiesObject, id: id}
            };
        });
        console.log('features', features)
        return {
            "type": "FeatureCollection",
            "features": features
        };
    }


    return (
        <div className="settings-section">
            <div className='map-container' ref={mapContainerRef} />
        </div>
    );
};

export default MapDisplay2;