import React, { useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithPaddingXl, Heading, PreviewButton, PlansContainer, MainHeader, InternalPageHeader, Thead, Tbody } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { collection , query, or, onSnapshot, where, getDocs,serverTimestamp, orderBy, limit} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import HeaderInternal from "landing_components/HeaderInternal";
import { PrimaryButton } from "components/misc/Buttons";
import AppHeaderInternal from "landing_page_2024/AppHeaderInternal";
import { Table, Trow, Th, Td } from "components/misc/Layouts";

export default ({
  features = null,
  primaryButtonUrl = "/getting_started",
  primaryButtonText = "Get Started",
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks,
  heading = "Data Sources",
  description = "Designed for teams of scale - Speed optimizes your HR workflows so your teams can focus on the work that most interests you. Upload your HR documentation and references and reap benefits immediately."
}) => {
  const navigate = useNavigate();
  const [sources, setSources] = useState([])
  const [assets, setAssets] = useState([])
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    console.log('user', user)
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      navigate('/sign_in')
    }
  });
  const [user, loading, error] = useAuthState(auth);

  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  useEffect(() => {
    if (user) {
      const data_sources = query(
        collection(db, 'source_wrappers'),
        or(
          where('user_id', '==', user.uid),
          where('user_ids', 'array-contains', user.uid)
        )
      );

      getDocs(data_sources).then((sources) => {
        const data_source_details = sources.docs.map(el => {
          let data = el.data();
          data.last_updated = new Date(data.last_updated * 1000).toLocaleString();
          return { ...data, id: el.id };
        });

        data_source_details.sort((a, b) => {
          return new Date(b.last_updated) - new Date(a.last_updated);
        });
        setSources(data_source_details);
      })
    }
 
  }, [user]);

  const previewImageAnimationVariants = {
    rest: {
      backgroundPositionY: "0%"
    },
    hover: {
      backgroundPositionY: "100%",
      transition: { type: "tween", ease: "linear", duration: 5 }
    }
  };

  const noOfLandingPages = Object.keys(landingPages).length;
  const noOfInnerPages = Object.keys(innerPages).length;
  const noOfComponentBlocks = Object.values(blocks).reduce((acc, block) => acc + Object.keys(block.elements).length, 0);

  features = features || [
    "Get started in < 10 minutes",
    "Free to test and try out."  ];

  return (
    <AnimationRevealPage disabled>
      <AppHeaderInternal />
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <br /> <br />
        <Content2Xl>
            <InternalPageHeader>Dashboard</InternalPageHeader>
            <ContentWithPaddingXl>
              <PlansContainer>
                <Table>
                  <Thead className="border-b font-medium dark:border-neutral-500"> 
                      <Th className='table_header'>Name</Th>
                      <Th className='table_header'>Last Updated</Th>
                  </Thead>
                  <Tbody>
                    {sources.map(source => (
                      <Trow
                        key={source.id}
                        className="clickable-row border-b font-medium dark:border-neutral-500"
                        onClick={() => navigate('/bots?id=' + source.id)}
                        style={{color: 'black'}}
                      >
                        <Td>{source.name}</Td>
                        <Td>{source.last_updated}</Td>
                      </Trow>
                    ))}
                  </Tbody>
                </Table>      
              </PlansContainer>
            </ContentWithPaddingXl>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

