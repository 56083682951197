import { useState, useEffect } from 'react';
import Dropdown from '../parts/Dropdown';
import AnomaliesPlot from 'components/parts/AnomaliesPlot';
import { BasicParagraph2, BasicParagraphStrong, PlainText, SecondaryButton, StandardButton, StandardButton2, StyledInput, StyledSpinner } from 'components/misc/Layouts';
import MapDisplay2 from './MapDisplay2';
import Plot from 'react-plotly.js';
import { SimpleStyledInput } from 'components/misc/Layouts';
import { useNavigate } from 'react-router-dom';

const AggregatedTracker = ({ params, wrapper_id }) => {
  const [plotData, setPlotData] = useState([]);
  const [showLegend, setShowLegend] = useState(false); 

  useEffect(() => {
    const fetchData = async () => {
      const dateOption = params.tracker_params.date_option;
      const changeOption = params.tracker_params.change_option;
      const groupBy = params.tracker_params.group_by;
      const timeScale = params.tracker_params.time_scale;
      const filters = params.tracker_params.filters;
      const {type, variable} = changeOption;

      let requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&changeOption=${changeOption}&groupByOptions=${groupBy}&timeUnit=${timeScale}`;
      // requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&changeOption=${changeOption}&groupByOptions=${groupBy}&timeUnit=${timeScale}`
      if (type === 'SUM') {
        requestUrl += `&variable=${variable}`;
      }

      if (filters.length > 0) {
        const filterClauses = filters.map(filter => {
          const { field, value, comparer } = filter;
          return `${encodeURIComponent(field)}${encodeURIComponent(comparer)}${encodeURIComponent(value)}`;
        });
        requestUrl += `&filterOptions=${filterClauses.join(';')}`;
      }

      const response = await fetch(requestUrl);
      const data = await response.json();
      const potentialGroupingKeys = Object.keys(data[0] || {}).filter(key => key !== 'date' && key !== 'value');

      setShowLegend(potentialGroupingKeys.length > 0);

      if (potentialGroupingKeys.length === 0) {
        const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));

        const transformedData = [{
          x: sortedData.map(item => item.date),
          y: sortedData.map(item => parseFloat(item.value)),
          type: 'scatter',
          mode: 'lines+points',
          marker: { color: 'red' },
        }];
        setPlotData(transformedData);
      } else {
        const groupedData = {};
        data.forEach(item => {
          const groupKey = potentialGroupingKeys.map(key => item[key]).join('-');
          if (!groupedData[groupKey]) {
            groupedData[groupKey] = [];
          }
          groupedData[groupKey].push(item);
        });

        const transformedData = Object.entries(groupedData).map(([groupKey, items]) => {
          items.sort((a, b) => new Date(a.date) - new Date(b.date));

          return {
              x: items.map(item => item.date),
              y: items.map(item => parseFloat(item.value)),
              type: 'scatter',
              mode: 'lines+points',
              marker: { color: Math.floor(Math.random() * 16777215).toString(16) },
              name: groupKey
          };
        });

        setPlotData(transformedData);
      }
    };

    fetchData();
  }, [params, wrapper_id]);

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      timeZoneName: 'short'
    };
    return date.toLocaleString(undefined, options);
  };
  

  return (
    <div>
      <BasicParagraph2>Last Scanned: {formatDate(params.state.date_ran)} </BasicParagraph2>

      <Plot
        data={plotData}
        layout={{
          autosize: true,
          margin: { l: 50, r: 50, b: 50, t: 50, pad: 4 },
          xaxis: { title: 'Date (GMT)' },
          yaxis: { title: 'Value' },
          showlegend: showLegend, // Use state to control legend visibility
        }}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

const DetailedAnalysisPopup = ({ show, onClose, plotData, explanation, tableData }) => {
  if (!show) {
    return null;
  }

  // Use the plotData object directly to define the data for the plot
  const plotForecastData = [
    {
      x: plotData.dates,
      y: plotData.upperBounds,
      type: 'scatter',
      mode: 'lines',
      name: 'Upper Bound',
      line: {color: 'rgba(31, 119, 180, 0.5)'},
    },
    {
      x: plotData.dates,
      y: plotData.lowerBounds,
      fill: 'tonexty',
      fillcolor: 'rgba(31, 119, 180, 0.2)',
      type: 'scatter',
      mode: 'lines',
      name: 'Lower Bound',
      line: {color: 'rgba(31, 119, 180, 0.5)'},
    },
    {
      x: plotData.dates,
      y: plotData.values,
      type: 'scatter',
      mode: 'markers',
      name: 'Actual',
      marker: {
        size: 8,
        color: plotData.values.map((value, index) => {
          // Determine if the actual value is outside the forecasted upper or lower bounds
          return value < plotData.lowerBounds[index] || value > plotData.upperBounds[index] ? 'red' : 'rgba(31, 119, 180, 0.9)';
        }),
      },
    }
  ];

  return (
    <div className="modal-backdrop" onClick={onClose}>
        <div className="modal-content">
          <div className="explanation-pane">
            <p><b>Anomaly Analysis</b></p>
            <p><u>AI Interpretation</u>: <i>{explanation}</i></p>
            <div className="plot-pane">
              <Plot
                data={plotForecastData}
                layout={{
                  title: 'Analysis',
                  autosize: true,
                  margin: { l: 50, r: 50, b: 100, t: 50, pad: 4 },
                  xaxis: { title: 'Date', tickformat: '%Y-%m-%d', tickangle: -45, automargin: true },
                  yaxis: { title: 'Value' },
                  showlegend: true,
                  legend: { orientation: 'h', y: -0.2 },
                }}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
            <p>Factors:</p>
            <table>
              <thead>
                <tr>
                  <th>Sub-Group</th>
                  <th>Rate of Change</th>
                  <th>Impact on Overall Rate of Change</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.value}</td>
                    <td>{item.explanation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br /><br />
            <div>
              <p>Events:</p>
              <p>Baseball Game @ Oracle Park 1:00pm, 2/18/2021</p>
            </div>
            <br />
          </div>
        </div>
    </div>
  );
};



const AnomaliesTable = ({ tableData, onClick }) => {
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          {/* Render table headers */}
          {["date", "value", "lower_bound", "upper_bound", "differential"].map((header, index) => (
            <th key={index} style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{header}</th>
          ))}
          {tableData[0] && tableData[0].category && <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Category</th>}
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => (
          <tr key={index}>
            <td style={{
              padding: '8px',
              borderBottom: '1px solid #ddd',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px' // Adjust this based on your needs
            }}>
              {row.date}
            </td>
            <td style={{
              padding: '8px',
              borderBottom: '1px solid #ddd',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px' // Adjust this based on your needs
            }}>
              {row.value}
            </td>
            <td style={{
              padding: '8px',
              borderBottom: '1px solid #ddd',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px' // Adjust this based on your needs
            }}>
              {row.lower_bound}
            </td>
            <td style={{
              padding: '8px',
              borderBottom: '1px solid #ddd',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px' // Adjust this based on your needs
            }}>
              {row.upper_bound}
            </td>
            <td style={{
              padding: '8px',
              borderBottom: '1px solid #ddd',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px', // Adjust this based on your needs
              color: row.differential > 0 ? 'green' : row.differential < 0 ? 'red' : 'black'
            }}>
              {row.differential}
            </td>
            {row.category && <td style={{
              padding: '8px',
              borderBottom: '1px solid #ddd',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px' // Adjust this based on your needs
            }}>
              {row.category}
            </td>
            }
            <td style={{
              padding: '8px',
              borderBottom: '1px solid #ddd',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '150px' // Adjust this based on your needs
            }}>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const Anomalies = ({ id, wrapper_id, columnOptions, dataProperties, data_seed={}, user_id, demo, on_change, bot_creation=false, hide_menu=false}) => {
  const navigate = useNavigate();
  const [dateOption, setDateOption] = useState(null)
  const [changeOption, setChangeOption] = useState(null)
  const [spinning, setSpinning] = useState(false)
  const anomalyTypes = ['forecast', 'drops']
  const timeUnits = ['day', 'week', 'month']
  const [error, setError] = useState('')

  const [lowerPercentile, setLowerPercentile] = useState(1)
  const [lowerValue, setLowerValue] = useState(0)
  const [lowerBoundOption, setLowerBoundOption] = useState('None')

  const [upperPercentile, setUpperPercentile] = useState(99)
  const [upperValue, setUpperValue] = useState(0)
  const [upperBoundOption, setUpperBoundOption] = useState('None')
  const [groupTypes, setGroupTypes] = useState([]);
  const [breachThreshold, setBreachThreshold] = useState('');
  const [breachThresholdUnit, setBreachThresholdUnit] = useState('')
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [processingDelay, setProcessingDelay] = useState('');
  const [processingDelayUnit, setProcessingDelayUnit] = useState()
  const [aggregationMethod, setAggregationMethod] = useState('COUNT')

  const [dateColumns, setDateColumns] = useState([])
  const [numericColumns, setNumericColumns] = useState([])
  const [allColumns, setAllColumns] = useState([])
  const [alerts, setAlerts] = useState([])
  const [createMessage, setCreateMessage] = useState('')

  useEffect(() => {
    const dateTypes = ['date', 'time', 'timestamp', 'interval', 'timestamp without time zone'];
    const numericTypes = ['smallint', 'integer', 'bigint', 'smallserial', 'serial', 'bigserial', 'numeric', 'decimal', 'real', 'double precision', 'money', 'numeric(10,2)'];

    const dateCols = columnOptions.filter(col => dateTypes.includes(col.type)).map(col => col.name);
    const numericCols = columnOptions.filter(col => numericTypes.includes(col.type)).map(col => col.name);
    const allCols = columnOptions.map(col => col.name);
    console.log('columnOptions', columnOptions)
    setDateColumns(dateCols);
    setNumericColumns(numericCols);
    setAllColumns(allCols);
  }, [columnOptions]);

  const addGroupType = (newGroupType) => {
    console.log('adding grouptype', newGroupType)
    if (newGroupType && !groupTypes.includes(newGroupType)) {
      setGroupTypes([...groupTypes, newGroupType]);
    }
  };

  // Function to remove a selected group type
  const removeGroupType = (indexToRemove) => {
    setGroupTypes(groupTypes.filter((_, index) => index !== indexToRemove));
  };

  function saveComponent() {
    let url = 'http://127.0.0.1:5001/circleops-5f8c7/us-central1/saveOverviewComponent';
    url = 'https://us-central1-circleops-5f8c7.cloudfunctions.net/saveOverviewComponent';
    setSpinning(true);

    let processedAlerts = alerts.map(alert => {
      let object = {}

      object.anomaly_type = alert.anomalyType; 

      if (alert.anomalyType === 'forecast') {
        object.min_delta = alert.minDelta || 0;
      } else if (alert.anomalyType === 'drops') {
        object.consecutive_days = alert.consecutiveDays || 1;
        object.min_threshold = alert.minThreshold || 1;
      }

      return object;
    })

    const tracker_params = {
      date_option: dateOption, 
      change_option: {
        type: aggregationMethod,
        variable: aggregationMethod === 'SUM' ? changeOption : null
      },
      processing_delay: {
        value: processingDelay,
        unit: processingDelayUnit
      },
      group_by: groupTypes,
      filters: []
    }

    const requestBody = {
      wrapper_id: wrapper_id,
      action: 'add', 
      name: name,
      description: description, 
      type: 'anomalies',
      tracker_params: tracker_params,
      alerts: processedAlerts
    };

    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody)
    }).then((_) => {
      setSpinning(false);
      setCreateMessage("Created Successfully...")
      navigate(`/bots?id=${wrapper_id}`)
    })
    .catch(error => {
      setSpinning(false)
      setCreateMessage("Failed to Create Successfully...")
      console.error('Error saving report:', error);
    });
  }

  const addAlert = () => {
    setAlerts([...alerts, defaultAlert()]);
  };

  const updateAlert = (index, field, value) => {
    const newAlerts = alerts.map((alert, i) => (
      i === index ? { ...alert, [field]: value } : alert
    ));
    setAlerts(newAlerts);
  };

  const removeAlert = (index) => {
    const newAlerts = alerts.filter((_, i) => i !== index);
    setAlerts(newAlerts);
  };

  const defaultAlert = () => ({
    anomalyType: null,
    delta: 0,
    consecutiveDays: 0,
    minThreshold: 0
  });

  return (
    <div>
      {!hide_menu && 
      <div>
        {demo && <div><PlainText>Hint: Use "Date" for Date and "Home Sales" for Analysis Variable.</PlainText><br /><br /></div>}
        <div>
          <BasicParagraph2>Name:</BasicParagraph2>
          <StyledInput type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div> <br />

        <div>
          <BasicParagraph2>Description: </BasicParagraph2>
          <StyledInput type="text" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div><br />

        <hr />
        <BasicParagraphStrong><b>Tracker Details</b></BasicParagraphStrong> <br /><br />
        <div 
          style={{ 
            display: 'flex', 
            justifyContent: 'flex-start', 
            alignItems: 'flex-start', 
            flexDirection: 'column' // Add this line
            }}
          >
            <div>
            <BasicParagraph2>Date (*)</BasicParagraph2>
            <Dropdown 
              options={dateColumns} 
              selectedOption={dateOption} 
              onSelect={(option) => setDateOption(option)} 
            />
            </div><br />

            <div>
            <BasicParagraph2>Aggregation Method (*)</BasicParagraph2>
            <Dropdown
              options={['SUM', 'COUNT']}
              selectedOption={aggregationMethod}
              onSelect={(option) => setAggregationMethod(option)}
            />
            </div><br />

            {aggregationMethod === 'SUM' && (
            <>
              <div>
              <BasicParagraph2>Analysis Variable (*)</BasicParagraph2>
              <Dropdown
                options={numericColumns} 
                selectedOption={changeOption}
                onSelect={(option) => setChangeOption(option)}
              /> 
              </div>
              <br />
            </>
            )}
            
            <div>
              <BasicParagraph2>Group Type (optional)</BasicParagraph2>
              <Dropdown
                options={[...allColumns].filter(option => !groupTypes.includes(option.value))}
                onSelect={(option) => addGroupType(option)}
              />
              <div 
                style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                marginTop: '10px' 
                }}
              >
                {groupTypes.map((groupType, index) => (
                <div 
                  key={index} 
                  style={{ margin: '5px 0' }}
                >
                  <BasicParagraph2>{groupType} <button onClick={() => removeGroupType(index)}>Remove</button></BasicParagraph2> 
                </div>
                ))}
              </div>
            </div>
            <div>
              <BasicParagraph2>Processing Delay (*)</BasicParagraph2>
              <div style={{ display: 'flex'}}>
                <SimpleStyledInput 
                  type="number" 
                  value={processingDelay} 
                  onChange={(e) => setProcessingDelay(e.target.value)} 
                  style={{ marginRight: '10px', width: '100px' }} 
                />
                <Dropdown
                  options={[
                    'hours',
                  ]}
                  selectedOption={processingDelayUnit}
                  onSelect={setProcessingDelayUnit} 
                />
              </div>
            </div>
            <br />
          </div>

          <hr />

          <div><BasicParagraphStrong><b>Alerts</b></BasicParagraphStrong> <StandardButton2 onClick={addAlert}>Add Alert</StandardButton2></div>
          <hr />


          <div>
                {
                  alerts.map((alert, index) => (
                    <div key={index}>
                      <div>
                        <BasicParagraph2>Anomaly Type (*)</BasicParagraph2>
                        <Dropdown options={anomalyTypes} selectedOption={alert.anomalyType} onSelect={(option) => updateAlert(index, 'anomalyType', option)} />
                      </div> <br />

                      {alert.anomalyType === 'forecast' && (
                        <div>
                          <BasicParagraph2>Minimum Delta</BasicParagraph2>
                          <SimpleStyledInput 
                              type="number" 
                              value={alert.minDelta} // Assuming a different state variable for schedule value
                              onChange={(e) => updateAlert(index, 'minDelta', e.target.value)} // Assuming a different handler for schedule value
                              style={{ marginRight: '10px', width: '100px' }} // Adjusted width here
                            />
                          <br />
                        </div>
                      )}
                      {alert.anomalyType === 'drops' && (
                        <>
                        <div>
                          <BasicParagraph2>Consecutive Days Prior</BasicParagraph2>
                          <SimpleStyledInput 
                            type="number" 
                            value={alert.consecutiveDays} // Assuming a different state variable for schedule value
                            onChange={(e) => updateAlert(index, 'consecutiveDays', e.target.value)} // Assuming a different handler for schedule value
                            style={{ marginRight: '10px', width: '100px' }} // Adjusted width here
                          />
                        </div><br />
                        <div style={{ marginBottom: '20px' }}>
                          <BasicParagraph2>Min Threshold</BasicParagraph2>
                          <SimpleStyledInput 
                            type="number" 
                            value={alert.minThreshold} // Assuming a different state variable for schedule value
                            onChange={(e) => updateAlert(index, 'minThreshold', e.target.value)} // Assuming a different handler for schedule value
                            style={{ marginRight: '10px', width: '100px' }} // Adjusted width here
                          />
                        </div>
                        </>
                      )}
                      <StandardButton2 onClick={() => removeAlert(index)}>Remove Alert</StandardButton2>
                      <hr />
                    </div>
                  ))
                }
          </div>
        {
            !bot_creation && <>
            <StandardButton onClick={saveComponent}>Create</StandardButton>
            {spinning && <StyledSpinner /> }
            <BasicParagraph2>{createMessage}</BasicParagraph2>
            </>
        }
      </div>}
      <br /><br />
    </div>
  );
};

export {Anomalies, AggregatedTracker};