import { useState, useEffect } from "react";
import { notification } from "antd";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


export const useForm = (validate: any) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const navigate = useNavigate(); // Call this inside the component or hook where you're using useForm

  useEffect(() => {
    if (shouldNavigate) {
      navigate('/');
    }
  }, [shouldNavigate, navigate]);


  const openNotificationWithIcon = () => {
    const url = "https://us-central1-circleops-5f8c7.cloudfunctions.net/receivedNewEmail";
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Set content type to JSON
      },
      body: JSON.stringify(values), // Send values as JSON string
    }).then(() => {
      navigate('/');
      notification["success"]({
        message: "Success",
        description: "Your message has been sent!",
      });
    });
  };

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(validate(values));
    // Your url for API
    const url = "";
    if (Object.keys(values).length === 3) {
      setShouldSubmit(true);
    }
  };

  useEffect(() => {
    console.log('submitting', Object.keys(errors).length, shouldSubmit)
    if (Object.keys(errors).length === 0 && shouldSubmit) {
      console.log('multiple values')
      // setValues("");
      openNotificationWithIcon();
    }
  }, [errors, shouldSubmit]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
    setErrors((errors) => ({ ...errors, [event.target.name]: "" }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  };
};
