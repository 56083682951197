import { useState, useRef } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, OAuthProvider, signOut } from "firebase/auth";


const HeaderInternal = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const navigate = useNavigate();

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  function userSignOut() {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }
  

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      navigate(`/${id}`);
    };
    
    return (
      <>
        <CustomNavLinkSmall onClick={() => navigate('/dashboard')}>
          <Span>{t("Data Sources")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => userSignOut()}>
          <Span>{t("Sign Out")}</Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  const MenuItemExpand = () => {
    const scrollTo = (id: string) => {
      navigate(`/${id}`);
    };
    
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("how_it_works")}>
          <Span>{t("Data Sources")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => navigate('/sign_in')}>
          <Span>{t("Sign Out")}</Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="200px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItemExpand />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(HeaderInternal);