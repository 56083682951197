import React from 'react';
import Plot from 'react-plotly.js';
import { useState, useEffect } from 'react';
import ViewToggle from './ViewToggle';  // Import the ViewToggle component
import { BasicParagraphStrong } from 'components/misc/Layouts';

const PerformanceHistory = ({ historyData, scenarioParams, targetValue }) => {
  const [historyPlotData, setHistoryPlotData] = useState([]);
  const [forecastPlotData, setForecastPlotData] = useState([]);
  const [evaluationData, setEvaluationData] = useState(null);
  const [isCumulative, setIsCumulative] = useState(false);  // State for cumulative toggle
  const [xaxisRange, setXaxisRange] = useState(null);

  useEffect(() => {
    if (scenarioParams.forecast_details) {
      // const url = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/downloadFile?file_path=${scenarioParams.forecast_details}`;
      const url = `https://us-central1-circleops-5f8c7.cloudfunctions.net/downloadFile?file_path=${scenarioParams.forecast_details}`
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((results) => {
          setEvaluationData(results);
        });
    }
  }, [scenarioParams]);

  useEffect(() => {
    console.log('evaluation data', evaluationData)
    if (evaluationData && evaluationData.forecast && evaluationData.forecast.length > 0) {
      const sortedForecast = evaluationData.forecast.sort((a, b) => a.timestamp - b.timestamp);
      const sortedActuals = evaluationData.realized_values.sort((a, b) => a.timestamp - b.timestamp);

      let cumulativeActuals = 0;
      let cumulativeForecast = 0;

      const actualsPlotData = {
        x: sortedActuals.map(a => new Date(a.timestamp * 1000).toISOString()),
        y: sortedActuals.map(a => {
          if (isCumulative) {
            cumulativeActuals += a.value;
            return cumulativeActuals;
          } else {
            return a.value;
          }
        }),
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: 'blue' },
        name: 'Actuals'
      };

      let extendedPlotData = {};

      if (isCumulative) {
        let extendedData = [];
        if (sortedActuals.length > 0) {
          extendedData.push({
            x: sortedActuals[sortedActuals.length - 1].timestamp,
            y: cumulativeActuals
          });
          const lastTimestamp = sortedActuals[sortedActuals.length - 1].timestamp;
          extendedData = extendedData.concat(sortedForecast.filter(item => item.timestamp > lastTimestamp).map(item => ({
            x: item.timestamp,
            y: cumulativeActuals + sortedForecast.filter(fc => fc.timestamp <= item.timestamp && fc.timestamp > lastTimestamp).reduce((sum, fc) => sum + fc.value, 0)
          })));
          extendedPlotData = {
            x: extendedData.map(item => new Date(item.x * 1000).toISOString()),
            y: extendedData.map(item => item.y),
            name: 'Forecasted Actuals',
            type: 'scatter',
            mode: 'lines',
            line: {
              dash: 'dot',
              color: 'green'
            }
          };
        }
      }

      const forecastPlotData = {
        x: sortedForecast.map(f => new Date(f.timestamp * 1000).toISOString()),
        y: sortedForecast.map(f => {
          if (isCumulative) {
            cumulativeForecast += f.value;
            return cumulativeForecast;
          } else {
            return f.value;
          }
        }),
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: 'red' },
        name: 'Forecast'
      };

      setForecastPlotData([forecastPlotData, actualsPlotData, extendedPlotData]);

      let accumulatedActuals = 0;
      const combinedData = sortedActuals.map(actual => {
        accumulatedActuals += actual.value;
        const actualSum = sortedActuals.reduce((sum, a) => a.timestamp <= actual.timestamp ? sum + a.value : sum, 0);
        const forecastSum = sortedForecast.reduce((sum, forecast) => forecast.timestamp > actual.timestamp ? sum + forecast.value : sum, 0);
        return {
          timestamp: actual.timestamp,
          value: actualSum + forecastSum
        };
      });

      const combinedPlotData = {
        x: combinedData.map(c => new Date(c.timestamp * 1000).toISOString()),
        y: combinedData.map(c => c.value),
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: 'green' },
        name: 'Combined Estimate'
      };

      setHistoryPlotData([combinedPlotData]);

      // Determine the min and max timestamps
      const allTimestamps = [...sortedActuals, ...sortedForecast].map(item => item.timestamp);
      const minTimestamp = new Date(Math.min(...allTimestamps) * 1000).toISOString();
      const maxTimestamp = new Date(Math.max(...allTimestamps) * 1000).toISOString();
      setXaxisRange([minTimestamp, maxTimestamp]);
    }
  }, [evaluationData, isCumulative]);

  const targetLine = {
    type: 'line',
    xref: 'paper',
    x0: 0,
    x1: 1,
    yref: 'y',
    y0: targetValue,
    y1: targetValue,
    line: {
      color: 'red',
      width: 2,
      dash: 'dot'
    }
  };

  const layoutProps = {
    autosize: true,
    xaxis: {
      title: 'Time',
      range: xaxisRange,  // Set the x-axis range
      type: 'date',       // Ensure it's treated as date
      tickmode: 'auto',   // Automatic tick interval
      nticks: 10,         // Number of ticks, adjust as needed
      tickformat: '%Y-%m-%d'  // Date format for ticks
    },
    yaxis: {
      title: 'Value'
    },
    legend: {
      orientation: 'h',   // Horizontal alignment of legend
      y: -0.2,            // Position legend below the plot
    },
  };

  return (
    <div style={{ width: '100%', boxSizing: 'border-box', padding: '0 20px' }}>
      <BasicParagraphStrong>Performance Details</BasicParagraphStrong>
      <Plot 
        data={historyPlotData}
        layout={{ ...layoutProps, title: 'Updated Predictions Over Time', shapes: [targetLine] }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
      />
      <ViewToggle isCumulative={isCumulative} onChange={setIsCumulative} />
      <Plot 
        data={forecastPlotData}
        layout={{ 
          ...layoutProps, 
          title: 'Forecast vs. Actuals',
          shapes: isCumulative ? [targetLine] : []
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
      />
    </div>
  );
};

export default PerformanceHistory;