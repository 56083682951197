import IntroContent from "../content/IntroContent.json";
import Header from "../landing_components/Header";
// import Header from "components/headers/dark.js";
import Container from "../common/Container";
import SignIn from "../landing_components/SignIn";
import AppHeader from "landing_page_2024/AppHeader";

const Home = () => {
  return (
    <Container>      
      <SignIn
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="developer.svg"
        id="intro"
      />
    </Container>
  );
};

export default Home;
