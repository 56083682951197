import tw from "twin.macro";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import styled from '@emotion/styled';


// export const Container = tw.div`relative`;
export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
export const ContentWithPaddingLg= tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const Content2Xl= tw.div`max-w-screen-2xl mx-auto`;
export const PlainText = tw.p`text-2xl mb-0`;
export const BoldPlainText = tw.p`text-4xl font-extrabold text-center my-4`;
export const SubHeader = tw.p`text-2xl`; 
export const Heading = tw(HeadingBase)`lg:text-left text-[rgb(24,33,109)] leading-snug`;
export const Heading2 = tw(HeadingBase)`text-center md:text-left text-primary leading-snug`;
export const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
export const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
export const ActionButton = tw.button`px-8 py-3 font-bold rounded bg-primary text-gray-100 hocus:bg-primary hocus:text-gray-200 focus:outline focus:outline-none transition duration-300 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
export const PrimaryButton = tw(ActionButton)``;
export const SecondaryButton = tw(
    ActionButton
  )`bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900 px-4 py-2`;
  
export const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
export const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;

export const SectionContainer = tw(ContentWithVerticalPadding)``;
export const SectionHeading = tw(HeadingBase)`text-primary`;
export const PreviewButton = tw(PrimaryButtonBase)`w-full rounded-b-lg rounded-t-none py-5 font-semibold`;
export const SectionDescription = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-4xl`;
export const InputContainer = tw.div`relative py-5 mt-6`;
export const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
export const Input = tw.input``;
export const TextArea = tw.textarea`w-full px-4 py-2 mt-2 text-base border border-gray-300 rounded-md focus:outline-none focus:border-primary`;
export const KPIContainer = styled.div`
  display: flex;
  flex-direction: column; // Changed to column to stack elements vertically
  align-items: start; // Align items to the start of the container
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

export const KPIHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; // Ensure the header takes full width
`;

export const KPIName = styled.div`
  font-weight: bold;
  color: #333333;
  font-size: 18px;
`;

export function TitleHeader({ children }) {
  return (
    <h1 className="text-gray-900 font-bold text-5xl md:text-6xl xl:text-7xl">
      {children}
    </h1>
  );
}

export function MainHeader({ children }) {
  return (
    <h1 class="text-center text-4xl font-bold text-gray-800 md:text-5xl">
      {children}
    </h1>
  );
}

export function InternalPageHeader({ children }) {
  return (
    <h1 class="text-4xl font-bold text-gray-800 md:text-5xl">
      {children}
    </h1>
  );
}
// BasicParagraph.js
export function BasicParagraph({ children }) {
  return (
    <p className="text-center text-xl text-gray-600">
      {children}
    </p>
  );
}



export function BasicParagraph2({ children }) {
  return (
    <p className="text-xl text-gray-600">
      {children}
    </p>
  );
}

export function FrontPageParagraph({ children, className }) {
  return (
    <p className={`text-2xl ${className || ''}`}>
      {children}
    </p>
  );
}

export function StandardButton({ children, onClick = () => {} }) {
  return (
    <button
      className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:border before:border-transparent before:bg-primary/10 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
      onClick={onClick} // onClick is now truly optional
      role="button" // For accessibility, as we're using an <a> tag for button behavior
      tabIndex={0} // Makes the element focusable for better keyboard navigation
    >
      <span className="relative text-base font-semibold text-primary">
        {children}
      </span>
    </button>
  );
}

export function WebsiteHeader({ children }) {
  return (
    <div
      className="relative flex h-12 w-full justify-center items-center px-6 before:absolute before:inset-0 before:rounded-lg before:bg-primary sm:w-max"
    >
      <span className="relative text-lg font-semibold text-white">
        {children}
      </span>
    </div>
  );
}



export function StandardButton2({ children, onClick = () => {} }) {
  return (
    <button
      onClick={onClick}
      className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
      >
        <span className="relative text-sm font-semibold text-white">
          {children}
        </span>
    </button>
  )
}

export function Container({ children }) {
  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
        <div className="relative pt-36 ml-auto">
          {children}
        </div>
      </div>
    </div>);
}

export function ContainerInternal({ children }) {
  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
          {children}
      </div>
    </div>);
}

export function BasicParagraphStrong({children}) {
  return (
    <h5 class="text-xl font-semibold text-gray-700 transition group-hover:text-secondary">
      {children}
    </h5>
  )
}

export function MenuOption({children}) {
  return (
    <p class="text-gray-600">
      {children}
    </p>
  )
}

export function DropdownOption({ children, onClick }) {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer text-gray-600 py-2 hover:bg-gray-100"
      style={{ padding: '8px 0' }} // Adjust the padding as needed
    >
      {children}
    </div>
  );
}

export const ButtonContainer = ({ children }) => (
  <div style={{
    display: 'flex',
    gap: '0.75rem',
    marginBottom: 'calc(var(--card-padding) * -1)',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderTop: '1px solid var(--ui-light-border-color)'
  }}>
    {children}
  </div>
);

export const Table = tw.table`min-w-full overflow-hidden shadow-md sm:rounded-lg`;
export const Thead = tw.thead`bg-gray-50`;
export const Trow = tw.tr`border-b cursor-pointer  hover:bg-gray-100`;
export const Th = tw.th`px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider`;
export const Td = tw.td`px-6 py-4 whitespace-nowrap text-sm font-medium text-center text-gray-900`;
export const Tbody = tw.tbody`bg-white divide-y divide-gray-200`;

export const NiceText = tw.p`whitespace-nowrap text-sm font-medium text-gray-900`

export const StyledInput = styled.input`
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;

    &:focus {
        background: none;
        box-shadow: #2e186a 0px 0px 0px 1px;
    }
`;

export const SimpleStyledInput = styled.input`
    border: 1px solid black; /* Thin black border */
    border-radius: 0; /* No rounding on corners */
    background: transparent; /* No background color */
    outline: none; /* No outline on focus */
    width: 100%; /* Full width of the container */
    padding: 0.5rem; /* Padding for text inside the input */
    max-width: 300px; /* Maximum width of the input */
    display: block; /* Make the input a block element */

    &:focus {
        border: 1px solid black; /* Maintain the same border on focus */
        outline: none; /* No outline on focus */
    }
`;

export const StyledSpinner = styled.div`
  ${tw`relative`};
  width: 30px;
  height: 30px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
  margin-left: 10px;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const StyledBlock = styled.span`
  font-family: 'monospace';
  background-color: #f6f8fa;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 90%;
  border: 1px solid #e1e4e8;
`;

// Action component for interactive actions like 'delete'
export const Action = styled.span`
  color: grey; // Set the color to grey
  text-decoration: underline; // Add an underline
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: darkgrey; // Darken the color on hover for visual feedback
  }
`;

export const Modal = ({ children, onClose }) => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1050 // Ensure it's above other fixed elements
    }}>
      <div style={{
        position: 'relative',
        width: 'auto',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1051
      }}>
        <button onClick={onClose} style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'none',
          border: 'none',
          fontSize: '1.5rem',
          fontWeight: 'bold'
        }}>×</button>
        {children}
      </div>
    </div>
  );
};

export const CodeBlock = styled.span`
  color: grey; // Set the color to grey
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: darkgrey; // Darken the color on hover for visual feedback
  }

  /* Inline styles */
  font-family: monospace;
  background-color: #f6f8fa;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 90%;
  border: 1px solid #e1e4e8;
  color: black; // Text color is black inside the code blocks
  font-size: 16px; // Adjusting font size for visibility
  line-height: 1.5; // Improving readability
`;

export function ActivityBox({ children }) {
  return (
      <div style={{
          marginBottom: '20px',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '8px'
      }}>
          {children}
      </div>
  );
}

export const StyledDiv = styled.div`
  text-align: center;
  border: 2px solid green;
  border-radius: 10px;
  padding: 15px;
`;
