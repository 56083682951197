import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { getAuth, signInWithPopup, OAuthProvider, GoogleAuthProvider } from "firebase/auth";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TiVendorMicrosoft } from 'react-icons/ti';
import { FaGoogle } from 'react-icons/fa';
import { MainHeader, BasicParagraph } from "components/misc/Layouts";

interface SignInProps extends WithTranslation {}

const SignIn: React.FC<SignInProps> = ({ t }) => {
  const navigate = useNavigate();
  const [msftLoader, setMsftLoader] = useState(false);
  const [googleLoader, setGoogleLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.minHeight = '100vh';
    document.body.style.background = 'linear-gradient(to top right, #f0f8ff, #e6f7ff)';
    
    return () => {
      document.body.style.margin = '';
      document.body.style.padding = '';
      document.body.style.minHeight = '';
      document.body.style.background = '';
    };
  }, []);

  const handleMicrosoftLogin = () => {
    setMsftLoader(true);
    const provider = new OAuthProvider('microsoft.com');
    const auth = getAuth();
    setErrorMessage("");

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        if (credential) {
          const { accessToken, idToken } = credential;
          console.log(accessToken, idToken);
          navigate('/dashboard');
        } else {
          console.warn("Credential is null. Cannot extract tokens.");
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage("Sorry. The email you selected has not been preapproved. If you don't have an account, please reach out to info@circleops.ai to get started.");
      })
      .finally(() => setMsftLoader(false));
  };

  const handleGoogleLogin = () => {
    setGoogleLoader(true);
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    setErrorMessage("");

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          const { accessToken, idToken } = credential;
          navigate('/dashboard');
        } else {
          console.warn("Credential is null. Cannot extract tokens.");
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage("Sorry. The email you selected has not been preapproved. If you don't have an account, please reach out to info@circleops.ai to get started.");
      })
      .finally(() => setGoogleLoader(false));
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 3,
        color: '#333',
      }}
    >
      <Box sx={{ color: '#1a5f7a' }}>
        <MainHeader>{t('CircleOps Login')}</MainHeader>
      </Box>
      
      <Box sx={{ height: 40 }} />
      
      <Paper 
        elevation={3} 
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 400,
          width: '100%',
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="body1" align="center" sx={{ mb: 4, color: 'text.primary' }}>
          {t('Sign in to get started. If you don\'t have an account activated, please reach out to info@circleops.ai to get access.')}
        </Typography>
        
        <Box sx={{ height: 20 }} />
        
        <Button
          variant="outlined"
          startIcon={<TiVendorMicrosoft style={{ color: '#F25022' }} />}
          fullWidth
          size="large"
          onClick={handleMicrosoftLogin}
          sx={{ mb: 2, bgcolor: 'white', '&:hover': { bgcolor: '#f5f5f5' } }}
        >
          {msftLoader ? <CircularProgress size={24} /> : t('Login with Microsoft')}
        </Button>
        
        <Button
          variant="outlined"
          startIcon={<FaGoogle style={{ color: '#4285F4' }} />}
          fullWidth
          size="large"
          onClick={handleGoogleLogin}
          sx={{ bgcolor: 'white', '&:hover': { bgcolor: '#f5f5f5' } }}
        >
          {googleLoader ? <CircularProgress size={24} /> : t('Login with Google')}
        </Button>
      </Paper>
      
      <Box sx={{ height: 20 }} />
      
      {errorMessage && (
        <Typography 
          color="error" 
          sx={{ 
            mt: 2, 
            bgcolor: 'rgba(255, 255, 255, 0.9)', 
            p: 2, 
            borderRadius: 1,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default withTranslation()(SignIn);