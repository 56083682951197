import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithPaddingXl, PlainText, PrimaryButton, SecondaryButton } from "components/misc/Layouts";
import SidePanel from '../components/SidePanel'; // Import the SidePanel component
import MainPanel from '../components/MainPanel';
import HeaderInternal from "landing_components/HeaderInternal";
import { useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc} from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Dropdown from "components/parts/Dropdown";


const Popup = ({ index, data, onClose, source_id }) => {
  console.log('popup', data[index].metadata)
  const [entries, setEntries] = useState(data[index].metadata || {});
  const [description, setDescription] = useState(data[index].description)
  const [isLocationIdentifier, setIsLocationIdentifier] = useState(data[index].is_identifier);

  useEffect(() => {
    console.log('entries', entries)
  }, [entries])

  const addEntry = () => {
    setEntries([...entries, { key: '', coords: {'long': '', 'lat': ''}, associations: [] }]);
  };

  const addAssociation = (index) => {
    const newEntries = entries.map((entry, idx) => idx === index ? { ...entry, associations: [...entry.associations, { name: '', type: '', value: '' }] } : entry);
    setEntries(newEntries);
  };

  const deleteAssociation = (entryIndex, associationIndex) => {
    const updatedEntries = entries.map((entry, idx) => {
      if (idx === entryIndex) {
        // Remove the specified association from this entry
        const updatedAssociations = entry.associations.filter((_, aIdx) => aIdx !== associationIndex);
        return { ...entry, associations: updatedAssociations };
      }
      return entry;
    });
    setEntries(updatedEntries);
  };

  const handleCoordsChange = (entryIndex, field, value) => {
    console.log('coords', field, value)
    const updatedEntries = entries.map((entry, idx) => {
      if (idx === entryIndex) {
        entry.coords[field] = value 
      }
      return entry;
    });
    setEntries(updatedEntries);
  }

  const handleAssociationChange = (entryIndex, associationIndex, field, value) => {
    const updatedEntries = entries.map((entry, idx) => {
      if (idx === entryIndex) {
        const updatedAssociations = entry.associations.map((assoc, aIdx) => {
          if (aIdx === associationIndex) {
            let updatedAssoc = { ...assoc };
            updatedAssoc[field] = value;
            return updatedAssoc;
          }
          return assoc;
        });
  
        return { ...entry, associations: updatedAssociations };
      }
      return entry;
    });
    setEntries(updatedEntries);
  };

  const handleKeyChange = (entryIndex, value) => {
    const updatedEntries = entries.map((entry, idx) => idx === entryIndex ? { ...entry, key: value } : entry);
    setEntries(updatedEntries);
  };

  const saveEntry = () => {
    //save url endpoint

    // data => update index `index`  with entries 
    let updatedData = [...data];
    updatedData[index].metadata = entries;
    updatedData[index].description = description;
    updatedData[index].is_identifier = isLocationIdentifier;
    const payload = {
      field_context: updatedData,
      source_wrapper_id: source_id
    }
    const url_endpoint = 'http://127.0.0.1:5001/circleops-5f8c7/us-central1/saveEntryMetadata'
    fetch(url_endpoint, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload) // Sending data in the request body
    })
    .then(_ => {
      onClose();
    })
    .catch(error => {
        console.error('Error:', error);
    });
  }

  return (
    <div className="overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="popup" style={{ position: 'relative', padding: '20px', background: 'white', borderRadius: '5px', width: 'auto',  overflow: 'hidden', overflowY: 'auto', maxHeight: '80vh'}}>
        <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px', border: 'none', background: 'transparent', cursor: 'pointer', fontSize: '20px' }}>&times;</button>
        <PlainText>{data[index].name}</PlainText>

        <b>Description:</b>

        <textarea 
                placeholder="Add context for this column."
                value={description || ''} 
                onChange={(e) => setDescription(e.target.value)}
        />

        <div style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
          <input
            type="checkbox"
            id="is_location_identifier"
            style={{ width: 'auto', margin: 0 }}
            checked={isLocationIdentifier}
            onChange={(event) => { setIsLocationIdentifier(event.target.checked); }}
          />
          <label htmlFor="is_location_identifier" style={{ marginLeft: '8px' }}>Is Location Identifier</label>
        </div>

        {isLocationIdentifier && 
          <div>
            <b>Metadata:</b> <br />
            {entries.length > 0 && (
              <table className="popup-table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Value</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {entries.map((entry, entryIndex) => (
                    <>
                      <tr>
                          <td rowSpan={entry.associations.length + 1}>
                            <input 
                              type="text"
                              value={entry.key}
                              onChange={(e) => handleKeyChange(entryIndex, e.target.value)}
                              placeholder="key"
                            />
                          </td>
                          <td>coordinates</td>
                          <td>coordinates</td>
                          <td>
                            <input
                                  type="text"
                                  value={entry.coords.lat}
                                  onChange={(e) => handleCoordsChange(entryIndex, 'lat', e.target.value)}
                                  placeholder="Latitude"
                                />
                                <input
                                  type="text"
                                  value={entry.coords.long}
                                  onChange={(e) => handleCoordsChange(entryIndex, 'long', e.target.value)}
                                  placeholder="Longitude"
                                />
                          </td>
                          <td></td>
                      </tr>
                      {entry.associations.map((assoc, assocIndex) => (
                        <tr key={`${entryIndex}-${assocIndex}`}>
                          <td>
                              <input
                                type="text"
                                value={assoc.name}
                                onChange={(e) => handleAssociationChange(entryIndex, assocIndex, 'name', e.target.value)}
                                placeholder="Name"
                              />
                          </td>
                          <td>
                            <select
                              value={assoc.type}
                              onChange={(e) => handleAssociationChange(entryIndex, assocIndex, 'type', e.target.value)}
                            >
                              <option value="">Select Type</option>
                              <option value="string">Text</option>
                              <option value="number">Number</option>
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={assoc.value}
                              onChange={(e) => handleAssociationChange(entryIndex, assocIndex, 'value', e.target.value)}
                              placeholder="Value"
                            />
                          </td>
                          <td>
                                <button onClick={() => deleteAssociation(entryIndex, assocIndex)}>Delete</button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="5" style={{ textAlign: 'right' }}>
                          <div style={{ textAlign: 'right' }}>
                            <button onClick={() => addAssociation(entryIndex)}>(+)</button>
                          </div>
                        </td>
                      </tr> 
                    </>
                  ))}
                </tbody>
              </table>
            )}
            <button onClick={addEntry}>Add New Key</button><br /><br />
          </div>
        }
        <button onClick={saveEntry}>Save</button>
      </div>
    </div>
  );
};


export default ({
}) => {  
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [sourceName, setSourceName] = useState("temproary"); 
  const [template, setTemplate] = useState("temproary"); 
  const [sheetDescription, setSheetDescription] = useState('')
  const [dataDictionary, setDataDictionary] = useState([])
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [currentDataEntry, setCurrentDataEntry] = useState(null);
  const [emailToken, setEmailToken] = useState(null)
  const [assetMetadata, setAssetMetadata] = useState({})
  const [syncToDb, setSyncToDb] = useState(false)
  const [mySqlData, setMySqlData] = useState({})

  const handleButtonClick = (idx) => {
    setCurrentDataEntry(idx);
    setIsPopupVisible(true);
  };

  useEffect(() => {
    const docRef = doc(db, 'source_wrappers', id);

    getDoc(docRef)
    .then(docSnapshot => {
        let data = docSnapshot.data()
        setSourceName(data.name)
        setTemplate(data.source_type)
        if (data.asset_metadata) {
          setAssetMetadata(data.asset_metadata)
        }
        if (data.field_context) {
          setDataDictionary(data.field_context)
        }
        if (data.source_description) {
          setSheetDescription(data.source_description)
        }
        if (data.email_token) {
          setEmailToken(data.email_token)
        }
        if (data.sync_to_db) {
          setSyncToDb(data.sync_to_db)
        }
        if (data.source_type == 'mysql') {
          setMySqlData(data.connection_data)
        }
    })
  }, [id])

  const handleDescriptionChange = (entryName, value) => {
    setDataDictionary(prevDataDictionary =>
      prevDataDictionary.map(entry =>
        entry.name === entryName ? { ...entry, description: value } : entry
      )
    );
  };

  const handleAddFile = () => {
    navigate('/setup', { state: { id: id } });
  };

  const handleSave = () => {
    const updatedData = {
      field_context: dataDictionary,
      source_description: sheetDescription,
    };
  
    // Reference to the document
    const docRef = doc(db, 'source_wrappers', id);
  
    // Update the document
    updateDoc(docRef, updatedData)
      .then(() => {
        console.log("Document successfully updated");
        // Handle any post-update logic here (like showing a success message)
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        // Handle any errors here
      });
  };

  return (
    <AnimationRevealPage disabled>
      <HeaderInternal />
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <br /> <br />
        <Content2Xl>
        <h1>{sourceName}</h1>
        <p><b> Source Type: {template} </b></p>
        <PrimaryButton onClick={() => {navigate('/source?id=' + id)}}> Alert Templates </PrimaryButton> <PrimaryButton onClick={() => {navigate('/bots?id=' + id)}}>Overview</PrimaryButton> <PrimaryButton onClick={() => {navigate('/alerts?id=' + id)}}>Notifications</PrimaryButton> <PrimaryButton onClick={handleAddFile}> Add New File </PrimaryButton> <PrimaryButton onClick={() => {navigate('/settings?id=' + id)}}>Settings</PrimaryButton>
        <p><b> Settings </b></p>
        {template === "mysql" && (
          <div>
            <p><b>IP Address:</b> {mySqlData.ipAddress}</p>
            <p><b>Username:</b> {mySqlData.username}</p>
            <p><b>Password:</b> {mySqlData.password}</p>
            <p><b>Port:</b> {mySqlData.port}</p>
            <p><b>Query Frequency:</b> {mySqlData.queryFrequency}</p>
            <p><b>Query:</b> {mySqlData.query}</p>
          </div>
        )}

        {/* <SecondaryButton 
            onClick={handleSave}
        >
            Save Changes
        </SecondaryButton> */}
        <div>

        {/* <PlainText><b>Email Matching</b></PlainText>
        <div className="email-matching">
          <div className="switch-container">
            <label htmlFor="emailMatchingSwitch" className="switch-label">Enable Email Matching</label> 
            <label className="switch">
              <input type="checkbox" id="emailMatchingSwitch" name="emailMatchingSwitch" />
              <span className="slider round"></span>
            </label>
          </div>
          <br /><br />
          <div className="fields">
            <div className="field">
              <label htmlFor="originalSender">Original Sender:</label>
              <input type="text" id="originalSender" name="originalSender" />
            </div>
            <div className="field">
              <label htmlFor="bodyContains">Body Contains:</label>
              <input type="text" id="bodyContains" name="bodyContains" />
            </div>
            <div className="field">
              <label htmlFor="subjectContains">Subject Contains:</label>
              <input type="text" id="subjectContains" name="subjectContains" />
            </div>
            <div className="field">
              <label htmlFor="attachmentNamed">Attachment Named:</label>
              <input type="text" id="attachmentNamed" name="attachmentNamed" />
            </div>
          </div>
        </div> */}
        <br /><br />

        <div className="merge-settings">
          <p><b> Explain what this data is.</b> </p>
          <textarea 
              id="message-input" 
              name="description" 
              placeholder="E.g. Details about your sheet." 
              value={sheetDescription} 
              onChange={(e) => setSheetDescription(e.target.value)}
          />
          <PlainText>Overall Data Dictionary </PlainText>

          {dataDictionary.map((dataEntry, index) => (
              <div key={index}>
                <p><b>{dataEntry.name}</b></p> <button onClick={() => handleButtonClick(index)}>Edit Metadata</button> <br />
                <b>Description: </b>{dataEntry.description ? dataEntry.description : 'no description'} <br />

                <b>Type:</b> {dataEntry.type} <br />

                <b>Business Identifier:</b> {dataEntry.is_identifier ? "true" : "false"} <br />

                <b>Metadata: </b>
                {
                  dataEntry.metadata && dataEntry.metadata.length > 0 ? (
                    <div>{dataEntry.metadata.length} attached metadata values</div>
                  ) : '0 attached metadata values'
                }
                <br /><br />

                <hr />
              </div>
          ))}
        </div>
        {isPopupVisible && <Popup index={currentDataEntry} data={dataDictionary} onClose={() => setIsPopupVisible(false)} source_id={id} />}
        </div>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
