import { VisualizeCard, TableCard, TrendsCard, ForecastCard } from "components/SubComponents/DisplayCards";
import { useState, useEffect } from "react";
import { doc, getDoc} from 'firebase/firestore';
import { db } from '../../firebase';
import {BasicParagraph2, BasicParagraph, BasicParagraphStrong, PlainText, Table, Tbody, Thead, Trow, Td, Th, NiceText} from "components/misc/Layouts";
import Trends from "components/SubComponents/Trends";
import {Anomalies, AggregatedTracker} from "components/SubComponents/Anomalies";
import Plot from 'react-plotly.js';
import { PrimaryButton } from "components/misc/Buttons";
import { render } from "preact/compat";
import NumberCard from "components/NumberCard";
import { InternalPageHeader } from "components/misc/Layouts";
import { KPIEntryComponent, KPIVisualizer, KPIEntryComponent2, KPIInventoryEntryComponent } from "components/SubComponents/KPIVisualizer";
import { StandardButton } from "components/misc/Layouts";

const PopupModal = ({ isVisible, metadata, content, onClose}) => {
  const [plotData, setPlotData] = useState([]);
  const [showLegend, setShowLegend] = useState(true);
  const [xAxis, setXAxis] = useState('days before reservation');
  const [yAxis, setYAxis] = useState('number of bookings');
  const [popupTitle, setPopupTitle] = useState('');

  useEffect(() => {
    // Calculate the difference in lengths
    if (isVisible && content) {
      const lengthDifference = content.baseline.length - content.data.length;
    
      const baselineXValues = Array.from(content.baseline.keys());
      const dataXValues = Array.from(content.data.keys()).map(x => x + Math.max(0, lengthDifference));
    
      const newPlotData = [
        {
          x: baselineXValues,
          y: content.baseline,
          type: 'scatter',
          mode: 'lines',
          name: 'Baseline',
          marker: {color: 'blue'},
        },
        {
          x: dataXValues,
          y: content.data,
          type: 'scatter',
          mode: 'lines',
          name: 'Data',
          marker: {color: 'red'},
        },
      ];
      setPlotData(newPlotData);
      setXAxis()
    }
  }, [isVisible, content]);
  
  if (!isVisible) return null;

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
      <div style={{ position: 'relative', width: '80%', maxWidth: '720px', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <BasicParagraph2>{popupTitle}</BasicParagraph2>

        {metadata && (
          <>
            <BasicParagraph2>{metadata.category} on {metadata.date}</BasicParagraph2> <br />
            <BasicParagraph2>
              <span>
                Currently{' '}
                <span
                  style={{
                    color: metadata.value >= 0 ? 'green' : 'red',
                  }}
                >
                  {Math.abs(metadata.value)}%
                </span>
                {` ${metadata.value >= 0 ? 'above' : 'below'} baseline`}
              </span> 
            </BasicParagraph2>
            <BasicParagraph2>Last Updated: {metadata.last_updated} </BasicParagraph2>
            <BasicParagraph><i>Baseline is created based on historic patterns for the same day of week and category section.</i></BasicParagraph>
          </>
        )}

        <Plot
          data={plotData}
          layout={{
            width: 720, // Adjust the size as needed, or make it responsive
            height: 440,
            showlegend: showLegend,
            xaxis: { title: xAxis },
            yaxis: { title: yAxis },
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
          }}
        />
        <button onClick={() => { onClose(); setPlotData([]); }} style={{ position: 'absolute', top: '10px', right: '10px' }}>Close</button>
      </div>
    </div>
  );
};

const BookingPaceTracker = ({ params, wrapper_id }) => {
  const [gridData, setGridData] = useState([])
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [allData, setAllData] = useState({})
  const [modalMetadata, setModalMetadata] = useState({});
  const [groupBy, setGroupBy] = useState('');
  const [lastProcessedTimestamp, setLastProcessedTimestamp] = useState('');
  const [dateRanTimestamp, setDateRanTimestamp] = useState('');

  const processData = (data) => {
    const firstKey = Object.keys(data)[0];

    if (data[firstKey].baseline) {
      const formattedData = Object.keys(data).map((date) => {
        const current_booking_data = data[date].data[0];
        const baseline_value = data[date].baseline[data[date].baseline.length - data[date].data.length];

        const delta = (current_booking_data - baseline_value)/baseline_value * 100;
        return { date, categories: { 'all' : delta }};
      });
      setAllData(data)
      setGridData(formattedData)
    } else {
      let transformedData = {};

      Object.keys(data).forEach((originalCategory) => {
        let parsedCategoryTopLevel = parseToObjects(originalCategory).map(obj => obj.value).join(', ');
        transformedData[parsedCategoryTopLevel] = data[originalCategory];
      });

      let categoriesData = {};

      Object.keys(transformedData).forEach((category) => {
        const categoryData = transformedData[category];
        Object.keys(categoryData).forEach((date) => {
          const current_booking_data = categoryData[date].data[0];
          const baseline_value =  categoryData[date].baseline[categoryData[date].baseline.length -  categoryData[date].data.length];
          const delta = baseline_value !== 0 ? (current_booking_data - baseline_value) / baseline_value * 100 : (current_booking_data !== 0 ? Infinity : 0);

          if (!categoriesData[date]) {
            categoriesData[date] = {};
          }
          categoriesData[date][category] = delta.toFixed(2);
        });
      });

      let formattedData = Object.keys(categoriesData).map((date) => {
        return { date, categories: categoriesData[date] };
      });

      setAllData(transformedData); 
      setGridData(formattedData); 
    }
  }


  useEffect(() => {
    let requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/booking_pace?tracker_id=${encodeURIComponent(params.id)}`;
    // let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/booking_pace?tracker_id=${encodeURIComponent(params.id)}`;
    fetch(requestUrl)
      .then(response => response.json())
      .then(data => {
        console.log('data', data  )
        const lastProcessedTimestamp = new Date(data.last_processed_timestamp * 1000);
        const dateRanTimestamp = new Date(data.date_ran * 1000);
        const humanReadableLastProcessedTimestamp = lastProcessedTimestamp.toLocaleString(undefined, { timeZoneName: 'short' });
        const humanReadableDateRanTimestamp = dateRanTimestamp.toLocaleString(undefined, { timeZoneName: 'short' });
        setLastProcessedTimestamp(humanReadableLastProcessedTimestamp);
        setDateRanTimestamp(humanReadableDateRanTimestamp);
        processData(data.data)
        setGroupBy(data.group_by.length === 0 ? 'all categories' : data.group_by.join(', '));
      });
  }, [params, wrapper_id])


  // Function to determine cell color and opacity based on the value
  const getColorForValue = (value) => {
    const opacity = Math.min(Math.abs(value) / 20, 1); // Ensure opacity is between 0 and 1
    if (value >= 0) {
      return `rgba(0, 128, 0, ${opacity})`; // Green for positive values
    } else {
      return `rgba(255, 0, 0, ${opacity})`; // Red for negative values
    }
  };
  

  const handleCellClick = (category, date, value) => {
    const firstKey = Object.keys(allData)[0];

    if (allData[firstKey].baseline) {
      setModalContent(allData[date]);
    } else {
      setModalContent(allData[category][date])
    }

    setModalMetadata({ category, date, value});
    setModalVisible(true);
  };

  function parseToObjects(str) {
    const tuples = str.split("),");

    const result = tuples
        .map(tuple => {
            let cleanedTuple = tuple.replace(/[()]/g, '').trim();

            if (cleanedTuple !== '') {
                let [field, value] = cleanedTuple.split(', ').map(part => part.trim()); // Trim white space around the field and value

                field = field.replace(/^'+|'+$/g, '');
                value = value.replace(/^'+|'+$/g, '');

                return { field_name: field, value: value };
            }
            return null; 
        })
        .filter(tuple => tuple !== null); 
    
    return result;
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <BasicParagraph2>Transaction Data Up to: {lastProcessedTimestamp} </BasicParagraph2>
      <BasicParagraph2>Last Scanned: {dateRanTimestamp} </BasicParagraph2>
      <BasicParagraph2>Categories: {groupBy} </BasicParagraph2>
      {gridData.length > 0 && <>
      <Table>
        <Thead>
          <Trow>
            <Th>Date</Th>
            {Object.keys(gridData[0].categories).map((category) => (
              <Th key={category}>{category}</Th>
            ))}
          </Trow>
        </Thead>
        <Tbody>
          {gridData.map((row) => (
            <Trow key={row.date}>
              <Td>{row.date}</Td>
              {Object.entries(row.categories).map(([category, value], index) => (
                <Td key={index} style={{ backgroundColor: getColorForValue(value) }} onClick={() => handleCellClick(category, row.date, value)}>
                  {value}%
                </Td>
              ))}
            </Trow>
          ))}
        </Tbody>
      </Table>
      
      <PopupModal isVisible={modalVisible} metadata={modalMetadata} content={modalContent} onClose={() => setModalVisible(false)} />
      </>}

    </div>
  );
};

const KPITracker = ({ params, wrapper_id }) => {
  const [plotData, setPlotData] = useState([]);
  const [showLegend, setShowLegend] = useState(false); 
  const [selectedModel, setSelectedModel] = useState({})
  const [baseData, setBaseData] = useState([]);
  const [targetData, setTargetData] = useState({});
  const [modelData, setModelData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [simulatedModel, setSimulatedModel] = useState({})
  const [lastEvaluated, setLastEvaluated] = useState(0);
  const [lastTimestampEvaluated, setLastTimestampEvaluated] = useState(0);
  const [category, setCategory] = useState();
  const [modelTitle, setModelTitle] = useState('');
  const [targetValues, setTargetValues] = useState({});
  const [trackerType, setTrackerType] = useState('single')
  const [base, setBase] = useState();
  const [projection, setProjection] = useState();
  const [delta, setDelta] = useState();
  const [guidance, setGuidance] = useState([])

  const [models, setModels] = useState({})

  const generateDatesBetween = (startSeconds, endSeconds) => {
    const dates = [];
    let currentDate = new Date(startSeconds * 1000);
    const lastDate = new Date(endSeconds * 1000);
  
    while (currentDate <= lastDate) {
      dates.push(currentDate.toISOString().split('T')[0]);  
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }  
    return dates;
  };  
  
  useEffect(() => {
    const dateOption = params.tracker_params.date_option;
    const changeOption = params.tracker_params.change_option;
    const timeScale = params.tracker_params.time_scale;
    const groupBy = params.tracker_params.group_by;
    const keys = params.tracker_params.keys;
    const dateRange = params.tracker_params.date_range;
    const {type, variable} = changeOption;
    const {start_date, end_date} = dateRange;

    const state = params.state;

    setStartDate(start_date);
    setEndDate(end_date);
    setTrackerType(keys);

    if (state && state.data_path) {
      // let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/downloadFile?file_path=${state.data_path}`
      let requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/downloadFile?file_path=${state.data_path}`
      fetch(requestUrl)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setModels(data);
        });
    }

    setLastEvaluated(params.state.last_evaluated);
    setLastTimestampEvaluated(params.state.last_timestamp_evaluated);
  } , [params, wrapper_id]);

  useEffect(() => {
    if (Object.keys(models).length === 0) return;

    setTargetValues(models.metadata.targets);
    const dateOption = params.tracker_params.date_option;
    const changeOption = params.tracker_params.change_option;
    const timeScale = params.tracker_params.time_scale;
    const dateRange = params.tracker_params.date_range;
    const keys = params.tracker_params.keys;
    const group_by = params.tracker_params.group_by;
    const {type, variable} = changeOption;
    const {start_date, end_date} = dateRange;


    // let requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&changeOption=${changeOption}&groupByOptions=${groupBy}&timeUnit=${timeScale}`;
    let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&changeOption=${changeOption}&timeUnit=${timeScale}&start_date=${start_date}&end_date=${end_date}`;
    if (category) {
      requestUrl += `&filterOptions=${encodeURIComponent(category)}`;
    } else {
      console.log(models)
      if (models.metadata['all_filters'] != '*') {
        console.log('category2', models.metadata['all_filters'])
        requestUrl += `&filterOptions=${encodeURIComponent(models.metadata['all_filters'])}&joinType=OR`;
      }
    }

    console.log('requesting', requestUrl)

    if (type === 'SUM') {
      requestUrl += `&variable=${variable}`;
    }

    fetch(requestUrl).then((response) => response.json()).then((data) => {
      const potentialGroupingKeys = Object.keys(data[0] || {}).filter(key => key !== 'date' && key !== 'value');

      setShowLegend(potentialGroupingKeys.length > 0);
  
      if (potentialGroupingKeys.length === 0) {
        const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
        const transformedData = {
          x: sortedData.map(item => item.date),
          y: sortedData.reduce((acc, curr) => {
            if (acc.length > 0) {
              acc.push(acc[acc.length - 1] + parseFloat(curr.value));
            } else {
              acc.push(parseFloat(curr.value));
            }
            return acc;
          }, []),
          type: 'scatter',
          mode: 'lines+points',
          marker: { color: 'red' },
          name: 'Current Data' // Add the desired name for the legend here
        };
        setBaseData([transformedData]);
      } else {
        const groupedData = {};
        data.forEach(item => {
          const groupKey = potentialGroupingKeys.map(key => item[key]).join('-');
          if (!groupedData[groupKey]) {
            groupedData[groupKey] = [];
          }
          groupedData[groupKey].push(item);
        });
  
        const transformedData = Object.entries(groupedData).map(([groupKey, items]) => {
          items.sort((a, b) => new Date(a.date) - new Date(b.date));
  
          const cumulativeValues = items.reduce((acc, curr, index) => {
            if (index > 0) {
              acc.push(acc[index - 1] + parseFloat(curr.value));
            } else {
              acc.push(parseFloat(curr.value));
            }
            return acc;
          }, []);
  
          return {
              x: items.map(item => item.date),
              y: cumulativeValues,
              type: 'scatter',
              mode: 'lines+points',
              marker: { color: Math.floor(Math.random() * 16777215).toString(16) },
              name: groupKey 
          };
        });
        setBaseData(transformedData);
      }
    });
  }, [models, category]);

  useEffect(() => {
    let layout = {
      shapes: [
        {
          type: 'line',
          x0: new Date(startDate * 1000),
          y0: 0,
          x1: new Date(startDate * 1000),
          y1: 1,
          xref: 'x',
          yref: 'paper',
          line: {
            color: 'green', 
            width: 2,
            dash: 'dot' 
          }
        },
        {
          type: 'line',
          x0: new Date(endDate * 1000),
          y0: 0,
          x1: new Date(endDate * 1000),
          y1: 1,
          xref: 'x',
          yref: 'paper', 
          line: {
            color: 'green', 
            width: 2,
            dash: 'dot'
          }
        }
      ],
    };

    if (trackerType === 'single') {
      if (targetValues.length > 0) {
        layout.shapes.push({
          type: 'line',
          x0: 0,
          y0: targetValues[0]['target'],
          x1: 1,
          y1: targetValues[0]['target'],
          xref: 'paper',
          yref: 'y',
          line: {
            color: 'orange',
            width: 2,
            dash: 'dot'
          }
        });
      }
    } else {
      if (targetValues.length > 0) {
        let targetValue;

        if (category) {
          targetValue = targetValues.find(item => item.value === category)['target'];
        } else {
          targetValue = targetValues.reduce((acc, currentValue) => acc + currentValue.target, 0);          
        }
        layout.shapes.push({
          type: 'line',
          x0: 0,
          y0: targetValue,
          x1: 1,
          y1: targetValue,
          xref: 'paper',
          yref: 'y',
          line: {
            color: 'orange',
            width: 2,
            dash: 'dot'
          }
        });
      }
    }

    setTargetData(layout);
  }, [targetValues, category, startDate, endDate]);
  

  useEffect(() => {
    setPlotData([...baseData, ...modelData])
  }, [baseData, modelData]) 

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/queryData?wrapper_id=${params.wrapper_id}&dateOption=${params.tracker_params.date_option}&timeUnit=day&start_date=${params.tracker_params.date_range.start_date}&end_date=${params.tracker_params.date_range.end_date}`
    if (params.tracker_params.change_option.type === 'SUM') {
      requestUrl += `&variable=${params.tracker_params.change_option.variable}`;
    }

    let now_timestamp = 1710111600
  
    fetch(requestUrl)
      .then(response => response.json())
      .then(data => {
        const convertedData = data.map(item => ({
          timestamp: new Date(item.date).getTime() / 1000, // Convert date to UNIX timestamp in seconds
          value: parseFloat(item.value)
        }));
  
        const fakeData = convertedData.map(item => ({
          timestamp: item.timestamp,
          value: item.value + Math.random() * (5000 + 1000) - 1000
        }));

        const filteredConvertedData = convertedData
        .filter(item => item.timestamp <= now_timestamp)
  
        setGuidance(currentGuidance => [...currentGuidance, {data: filteredConvertedData, name: 'Current Data'}, {data: fakeData, name: 'Guidance'}]);
      });
  
    let forecastUrl = `http://127.0.0.1:5002/circleops-5f8c7/us-central1/forecast_target_planning_simulate_wrapper?` + 
    `data_source=${params.wrapper_id}&date_option=${params.tracker_params.date_option}&change_option=${params.tracker_params.change_option.variable}&time_scale=day` +
    `&forecast_start_time=${now_timestamp}&forecast_end_time=${endDate}` +
    `&confidence_interval=${0.5}`;
  
    fetch(forecastUrl)
      .then(response => response.json())
      .then(data => {
        setGuidance(currentGuidance => [...currentGuidance, {data: data, name: 'Forecast'}]);
      });
  
  }, [wrapper_id, startDate, endDate]);
  

  useEffect(() => {
    setSimulatedModel(selectedModel);
    const modelTitle = selectedModel && selectedModel.name
  ? `${selectedModel.name}${selectedModel.category ? ', ' + selectedModel.category : ''}`
  : '';
    setModelTitle(modelTitle);

    if (selectedModel.type === 'linear') {
      const dates = generateDatesBetween(selectedModel.params.start_time, endDate);
      
      setModelData([{
        x: dates,
        y: dates.map((_, index) => selectedModel.params.base + selectedModel.params.rate * index),
        type: 'scatter',
        mode: 'lines',
        name: 'Linear Model',
        line: { color: 'blue' },
      }]);

    } else if (selectedModel.type == 'date_range') {
      if (!startDate) {
        return;
      }
      const { dateOption, changeOption, groupBy, timeScale, date_range, target_value} = params.tracker_params;
      const {type, variable} = changeOption;
  
      setLastEvaluated(params.state.last_evaluated);
      setLastTimestampEvaluated(params.state.last_timestamp_evaluated);
      // let requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&changeOption=${changeOption}&groupByOptions=${groupBy}&timeUnit=${timeScale}`;
      let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/queryData?wrapper_id=${wrapper_id}&dateOption=${dateOption}&changeOption=${changeOption}&groupByOptions=${groupBy}&timeUnit=${timeScale}&start_date=${selectedModel.params.start_time}&end_date=${selectedModel.params.end_time}`;
  
      if (selectedModel.category) {
        requestUrl += `&filterOptions=${encodeURIComponent(selectedModel.category)}`;
      } else if (groupBy.length > 0) {
        console.log('groupby', groupBy)
        requestUrl += `&groupByOptions=${encodeURIComponent(groupBy)}`;
      }
  
      if (type === 'SUM') {
        requestUrl += `&variable=${variable}`;
      }
  
      fetch(requestUrl).then((response) => response.json()).then((data) => {
        const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
          const transformedData = [{
            x:  sortedData.map(item => {
              const itemDate = new Date(item.date);
              const timeDifference = (startDate * 1000) - (selectedModel.params.start_time * 1000);
              const newDate = new Date(itemDate.getTime() + timeDifference);
              return newDate.toISOString().split('T')[0];
            }),
            y: sortedData.reduce((acc, curr) => {
              if (acc.length > 0) {
                acc.push(acc[acc.length - 1] + parseFloat(curr.value));
              } else {
                acc.push(parseFloat(curr.value));
              }
              return acc;
            }, []),
            type: 'scatter',
            mode: 'lines+points',
            marker: { color: 'blue' },
            name: 'Baseline'
          }];
          setModelData(transformedData);
      });
    } else if (selectedModel.type == 'ai_forecast') {
      console.log(('ai_forecast', selectedModel.results))
      const dates = selectedModel.results.forecast_data.dates.map(item => new Date(item * 1000));
      const upper_bound = selectedModel.results.forecast_data.yhat_upper;
      const lower_bound = selectedModel.results.forecast_data.yhat_lower;

      const forecastTraceUpper = {
        x: dates,
        y: upper_bound,
        type: 'scatter', 
        mode: 'lines',
        name: 'Upper Forecast Limit',
        line: {
          dash: 'dot', 
          color: 'rgba(255,0,0,0.5)' 
        }
      };

      const forecastTraceLower = {
        x: dates,
        y: lower_bound,
        type: 'scatter',
        mode: 'lines',
        name: 'Lower Forecast Limit',
        fill: 'tonexty',
        fillcolor: 'rgba(255,0,0,0.2)',
        line: {
          dash: 'dot',
          color: 'rgba(255,0,0,0.5)'
        }
      }
      setModelData([forecastTraceUpper, forecastTraceLower]);
    } else if (selectedModel.type == 'checkpoints') {
      const dates = selectedModel.params.checkpoints.dates.map(date => new Date(date * 1000).toISOString().split('T')[0]);
      const values = selectedModel.params.checkpoints.values;

      setModelData([{
        x: dates,
        y: values,
        type: 'scatter',
        mode: 'lines+markers',
        line: {dash: 'dot', color: 'blue'}, 
        marker: {symbol: 'star', color: 'blue', line: {'width': 2}}, 
        name: 'Checkpoints'
      }]);
    }
  }, [selectedModel, startDate]);

  const getColorForValue = (value) => {
    const opacity = Math.min(Math.abs(value) / 20, 1); // Ensure opacity is between 0 and 1
    if (value >= 0) {
      return `rgba(0, 128, 0, ${opacity})`; // Green for positive values
    } else {
      return `rgba(255, 0, 0, ${opacity})`; // Red for negative values
    }
  }

  const getColorForPercentage = (value) => {
    const red = Math.floor((1 - value) * 255);
    const green = Math.floor(value * 255);
  
    return `rgba(${red}, ${green}, 0, 1)`; // Fully opaque, with varying color
  };

  const handleCellClick = (id, key) => {
    console.log('clicking', id)
    if (key) {
      if (trackerType === 'single') {
        const foundModel = models['values'].all_keys.find(model => model.id === id);
        setSelectedModel({...foundModel});
        setCategory()
        console.log('foundModel', foundModel)
        setBase(foundModel.results.final_value);
        setProjection(foundModel.results.target);
        const percentage = ((foundModel.results.final_value - foundModel.results.target) / foundModel.results.target * 100);
        const formattedPercentage = `${percentage > 0 ? '+' : ''}${percentage.toFixed(2)}%`;
        setDelta(formattedPercentage);
      } else {
        const foundModel = models['values'][key].find(model => model.id === id);
        setSelectedModel({...foundModel, category: key});
        setCategory(key)
        setBase(foundModel.results.final_value);
        setProjection(foundModel.results.target);
        const percentage = ((foundModel.results.final_value - foundModel.results.target) / foundModel.results.target * 100);
        const formattedPercentage = `${percentage > 0 ? '+' : ''}${percentage.toFixed(2)}%`;
        setDelta(formattedPercentage);
      }
    } else {
      setSelectedModel(models['overall'][id]);
      setCategory()
    } 
  }

  function calculateExpectedValue(currentTimestamp, checkpoints) {
    for (let idx = 0; idx < checkpoints.dates.length; idx++) {
      const checkpoint = checkpoints.dates[idx];
      if (currentTimestamp === checkpoint) {
        return checkpoints.values[idx];
      }
  
      if (idx + 1 < checkpoints.dates.length && currentTimestamp < checkpoints.dates[idx + 1]) {
        const startValue = checkpoints.values[idx];
        const endValue = checkpoints.values[idx + 1];
        const startTime = checkpoints.dates[idx];
        const endTime = checkpoints.dates[idx + 1];
        const interpolatedValue = startValue + (endValue - startValue) * ((currentTimestamp - startTime) / (endTime - startTime));
        return interpolatedValue;
      }
    }
  
    return checkpoints.values[checkpoints.values.length - 1];
  }
  

  const simulate = () => {
    if (selectedModel.type === 'linear') {
      let offset = (1710892800 - startDate) / 86400
      console.log('simulated model', offset)
      let expected_value = simulatedModel.params.rate * offset + simulatedModel.params.base
      setProjection(expected_value);
      const percentage = ((base - expected_value) / expected_value * 100);
      const formattedPercentage = `${percentage > 0 ? '+' : ''}${percentage.toFixed(2)}%`;
      setDelta(formattedPercentage);
      setSelectedModel(simulatedModel);
    }

    if (selectedModel.type == 'date_range') {

      setSelectedModel(simulatedModel);
    }

    if (selectedModel.type == 'checkpoints') {
      const expected_value = calculateExpectedValue(1710892800, simulatedModel.params.checkpoints);
      setProjection(expected_value);
      const percentage = ((base - expected_value) / expected_value * 100);
      const formattedPercentage = `${percentage > 0 ? '+' : ''}${percentage.toFixed(2)}%`;
      setDelta(formattedPercentage);
      setSelectedModel(simulatedModel)
    }

    if (selectedModel.type == 'ai_forecast') {
      const { dateOption, changeOption, groupBy, timeScale, date_range} = params.tracker_params;
      const {type, variable} = changeOption;
      const {start_date, end_date} = date_range;

      let requestUrl = `http://127.0.0.1:5002/circleops-5f8c7/us-central1/forecast_target_planning_simulate_wrapper?` + 
                `data_source=${params.wrapper_id}&date_option=${dateOption}&change_option=${variable}&time_scale=${timeScale}` +
                `&period_start_time=${startDate}&forecast_start_time=${simulatedModel.params.forecast_start_time}&period_end_time=${endDate}` +
                `&confidence_interval=${simulatedModel.params.confidence_interval}&user_id=${params.user_id}&id=${params.id}`;
      if (category.length > 0) {
        requestUrl += `&filter_options=${category}`;
      }

      fetch(requestUrl)
        .then((response) => response.json())
        .then((data) => {
          const dates = data.all_keys.map(item => item.ds);
          const upper_bound = data.all_keys.map(item => item.yhat_upper);
          const lower_bound = data.all_keys.map(item => item.yhat_lower);

          const forecastTraceUpper = {
            x: dates,
            y: upper_bound,
            type: 'scatter', 
            mode: 'lines',
            name: 'Upper Forecast Limit',
            line: {
              dash: 'dot', 
              color: 'rgba(255,0,0,0.5)' 
            }
          };

          const forecastTraceLower = {
            x: dates,
            y: lower_bound,
            type: 'scatter',
            mode: 'lines',
            name: 'Lower Forecast Limit',
            fill: 'tonexty',
            fillcolor: 'rgba(255,0,0,0.2)',
            line: {
              dash: 'dot',
              color: 'rgba(255,0,0,0.5)'
            }
          };
          setModelData([forecastTraceUpper, forecastTraceLower]);
        })
    }
  }

  const renderModelsTable = (modelsData) => {
    if (Object.keys(modelsData).length === 0) {
      return;
    }
  
    const getColorForValue = (value) => {
      const opacity = Math.min(Math.abs(value) / 20, 1); 
      if (value >= 0) {
        return `rgba(0, 128, 0, ${opacity})`; 
      } else {
        return `rgba(255, 0, 0, ${opacity})`; 
      }
    };
  
    const allModels = [];
    Object.values(modelsData['values']).forEach(modelsArray => {
      modelsArray.forEach(model => {
        if (allModels.findIndex(m => m.id === model.id) === -1) {
          allModels.push(model);
        }
      });
    });
  
    allModels.sort((a, b) => {
      if (a.type === 'ai_forecast' && b.type !== 'ai_forecast') {
        return 1; 
      }
      if (a.type !== 'ai_forecast' && b.type === 'ai_forecast') {
        return -1; 
      }
      return 0; 
    });
  
    const headerCells = allModels.map((model) => (
      <Th key={model.id} style={{ backgroundColor: model.type === 'ai_forecast' ? '#d0d0f0' : undefined }}>
        <b>{model.name}</b>
      </Th>
    ));
  
    let tableRows = Object.entries(modelsData['values']).map(([key, models], index) => (
      <Trow key={index}>
        <Td>{key.replace('_', ' ')}</Td>
        {allModels.map((headerModel) => {
          const model = models.find(m => m.id === headerModel.id);
          if (model) {
            const percentage = ((model.results.final_value - model.results.target) / model.results.target * 100);
            const formattedPercentage = `${percentage > 0 ? '+' : ''}${percentage.toFixed(2)}%`;
            return (
              <Td key={model.id} style={{ backgroundColor: getColorForValue(percentage), cursor: 'pointer' }} onClick={() => { handleCellClick(model.id, key) }}>
                {formattedPercentage}
              </Td>
            );
          } else {
            return <Td key={headerModel.id}></Td>; 
          }
        })}
      </Trow>
    ));
  
    if (trackerType === 'multiple') {
      const summaryRow = (
        <Trow style={{ backgroundColor: '#f0f0f0' }}> 
          <Td><b>Summary</b></Td>
          {allModels.map((model) => {
            const summaryModel = modelsData.overall[model.id];
            if (summaryModel) {
              const percentage = ((summaryModel.results.final_value - summaryModel.results.target) / summaryModel.results.target * 100);
              const formattedPercentage = `${percentage > 0 ? '+' : ''}${percentage.toFixed(2)}%`;
              return (
                <Td key={model.id} style={{ backgroundColor: getColorForValue(percentage), cursor: 'pointer' }} onClick={() => { handleCellClick(model.id) }}>
                  {formattedPercentage}
                </Td>
              );
            } else {
              return <td key={model.id}></td>;
            }
          })}
        </Trow>
      );
  
      // Add the summary row to the table rows
      tableRows = [...tableRows, summaryRow];
    }
  
    return (
      <Table border="1" style={{ width: '100%', textAlign: 'center' }}>
        <Thead>
          <Trow>
            <Th>Key</Th>
            {headerCells}
          </Trow>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    );
  };
  
    

  return (
    <div>
      <div>
        <PlainText>{modelTitle}</PlainText><br />
        <BasicParagraph2>Probability of Reaching Target: <span style={{color: getColorForPercentage(0.15)}}>15%</span>⚠️⚠️⚠️</BasicParagraph2>
      </div>

      {base && projection && delta && <> 

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
          <NumberCard value={base} title="Base Value" />
          <NumberCard value={projection} title="Projection" />
          <NumberCard value={delta} title="Delta" />
        </div>
      </>}

      
      <KPIVisualizer startDate={startDate} endDate={endDate} targetValue={2100000} guidance={guidance} style={{width: '100%'}} />
      <StandardButton>Identify Areas of Improvement</StandardButton>
      <NiceText>Areas of Improvement</NiceText>
      <Table>
        <Thead>
          <Th>Improvement Area</Th>
          <Th>Projected Impact</Th>
          <Th>Simulate</Th>
        </Thead>
        <Tbody>
          <Trow>
            <Td>
              East Coast Sales are down 30% YoY
            </Td>
            <Td>
              20%
            </Td>
            <Td>
              <StandardButton>Simulate</StandardButton>
            </Td>
          </Trow>
          <Trow>
            <Td>
              Online Sales are down 20% YoY
            </Td>
            <Td>
              40%
            </Td>
            <Td>
              <StandardButton>Simulate</StandardButton>
            </Td>
          </Trow>
          <Trow>
            <Td>
              The growth rate is lower than 90% of other locations.
            </Td>
            <Td>
              15%
            </Td>
            <Td>
              <StandardButton>Simulate</StandardButton>
            </Td> 
          </Trow>
        </Tbody>
      </Table>

      <Plot
        data={plotData}
        layout={targetData}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
            />
        {/* {
          <div>
          <table border="1" style={{ width: '100%', textAlign: 'center', fontSize: '24px' }}>
            <thead>
              <tr>
                <th style={{ padding: '10px' }}><b>Risk Factors</b></th>
                <th style={{ padding: '10px' }}>Date Range</th>
                <th style={{ padding: '10px' }}>Reporter</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '10px' }}>🌧️ Extreme Weather Events Forecasted in 10 Locations</td>
                <td style={{ padding: '10px' }}>03/31/24 - 04/10/24</td>
                <td style={{ padding: '10px' }}>CircleOps AI</td>
              </tr>
              <tr>
                <td style={{ padding: '10px' }}>Overall booking pace is slowing down recently.</td>
                <td style={{ padding: '10px' }}>03/26/24 - 03/31/24</td>
                <td style={{ padding: '10px' }}>CircleOps AI</td>
              </tr>
              <tr>
                <td style={{ padding: '10px' }}>Growth in 10 regions is slowing down.</td>
                <td style={{ padding: '10px' }}>03/26/24 - 03/31/24</td>
                <td style={{ padding: '10px' }}>CircleOps AI</td>
              </tr>
              <tr>
                <td style={{ padding: '10px' }}>51st street location needs to be shut down for construction.</td>
                <td style={{ padding: '10px' }}>03/31/24 - 04/02/24</td>
                <td style={{ padding: '10px' }}>Stephanie Wu</td>
              </tr>
            </tbody>
          </table>
          <br /><br />
        </div>
        } */}
        {selectedModel && 
        <div>
          <BasicParagraph2>Parameters</BasicParagraph2>
          {simulatedModel.type == 'linear' && Object.keys(simulatedModel.params).length > 0 &&
            <div>
              Base Value <br />
              <input type="number" value={simulatedModel.params.base} onChange={(e) => setSimulatedModel(prevState => ({...prevState, params: {...prevState.params, base: Number(e.target.value)}}))} />
              Rate (per day) <br />
              <input type="number" value={simulatedModel.params.rate} onChange={(e) => setSimulatedModel(prevState => ({...prevState, params: {...prevState.params, rate: Number(e.target.value)}}))} />
              Start Time <br />
              <input
                type="date"
                value={simulatedModel.params.start_time ? new Date(simulatedModel.params.start_time * 1000).toISOString().split('T')[0]: ''}
                onChange={(e) => setSimulatedModel(prevState => ({
                  ...prevState,
                  params: {
                    ...prevState.params,
                    start_time: Date.parse(e.target.value) / 1000
                  }
                }))}
              />
              <br /><br />
              <button onClick={simulate}>Simulate Parameters</button>
            </div>
          }
          {simulatedModel.type == 'date_range' && Object.keys(simulatedModel.params).length > 0 &&
            <div>
              Start Time <br />
              <input
                type="date"
                value={simulatedModel.params.start_time ? new Date(simulatedModel.params.start_time * 1000).toISOString().split('T')[0]: ''}
                onChange={(e) => setSimulatedModel(prevState => ({
                  ...prevState,
                  params: {
                    ...prevState.params,
                    start_time: Date.parse(e.target.value) / 1000
                  }
                }))}
              />
              End Time <br />
              <input
                type="date"
                value={simulatedModel.params.end_time ? new Date(simulatedModel.params.end_time * 1000).toISOString().split('T')[0]: ''}
                onChange={(e) => setSimulatedModel(prevState => ({
                  ...prevState,
                  params: {
                    ...prevState.params,
                    end_time: Date.parse(e.target.value) / 1000
                  }
                }))} />
                <br /><br />
                <button onClick={simulate}>Simulate Parameters</button>
            </div>
          }
          {simulatedModel.type == 'ai_forecast' && Object.keys(simulatedModel.params).length > 0 &&
            <div>
              {/* Confidence Interval <br />
              <input
                type="number"
                min="0"
                max="1"
                step="0.05"
                value={simulatedModel.params.confidence_interval}
                onChange={(e) => setSimulatedModel(prevState => ({
                  ...prevState,
                  params: {...prevState.params, confidence_interval: parseFloat(e.target.value)}
                }))}
              />
              <br />
              Forecast Start Time <br />
              <input
                type="date"
                value={simulatedModel.params.forecast_start_time ? new Date(simulatedModel.params.forecast_start_time * 1000).toISOString().split('T')[0]: ''}
                onChange={(e) => setSimulatedModel(prevState => ({
                  ...prevState,
                  params: {...prevState.params, forecast_start_time: Date.parse(e.target.value) / 1000}
                }))}
              />
              <br /><br />
              <button onClick={simulate}>Simulate Parameters</button> */}
            </div>
          }
          {
            simulatedModel.type === 'checkpoints' &&

            <div>
              {
                simulatedModel.params.checkpoints.dates.map((date, index) => (
                  <div key={index} style={{ marginBottom: '20px' }}>
                    {/* Outer container for each row */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* Container for the Timestamp input */}
                      <div style={{ marginRight: '10px' }}> {/* Add some space between the inputs */}
                        Timestamp <br />
                        <input
                          type="date"
                          value={new Date(date * 1000).toISOString().split('T')[0]}
                          onChange={(e) => setSimulatedModel(prevState => {
                            const newState = { ...prevState };
                            newState.params.checkpoints.dates[index] = Date.parse(e.target.value) / 1000;
                            return newState;
                          })}
                        />
                      </div>

                      {/* Container for the Value input */}
                      <div>
                        Value<br />
                        <input
                          type="number"
                          value={simulatedModel.params.checkpoints.values[index]}
                          onChange={(e) => setSimulatedModel(prevState => {
                            const newState = { ...prevState };
                            newState.params.checkpoints.values[index] = Number(e.target.value);
                            return newState;
                          })}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
              <button onClick={simulate}>Simulate Parameters</button>
            </div>
          }
        </div>}
        <PrimaryButton>Add New Model</PrimaryButton>
        {renderModelsTable(models)} <br /> <br />
    </div>
  );
};

const EntryComponent = ({component_id, kpi_tracker=false}) => {
    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [description, setDescription] = useState('')
    const [wrapperId, setWrapperId] = useState()
    const [params, setParams] = useState({})


    useEffect(() => {
      let docRef; 
      if (kpi_tracker) {
        docRef = doc(db, 'kpi_trackers2', component_id);
      } else {
        docRef = doc(db, 'trackers', component_id);
      }

      getDoc(docRef)
        .then(docSnapshot => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data()
            console.log('data', data)

            setName(data.name)
            setDescription(data.description)
            setType(data.type)
            setWrapperId(data.wrapper_id)
            setParams({...data, id: component_id})
          } else {
            console.log('No such source document!');
          }
        })
        .catch(error => {
          console.error('Error fetching source document:', error);
        });
    }, [component_id])


    return (  <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      overflow: 'auto'
    }}>
      <BasicParagraphStrong>{name}</BasicParagraphStrong><br />
      <BasicParagraph2>{component_id}</BasicParagraph2>
      <BasicParagraph2>{description}</BasicParagraph2>

      {
        type == 'anomalies' && <AggregatedTracker params={params} wrapper_id={wrapperId}/>
      }
      {
        type == 'booking_pace' && <BookingPaceTracker params={params} wrapper_id={wrapperId}/>
      }
      {
        type == 'kpi_tracker' && <KPIEntryComponent2 params={params} wrapper_id={wrapperId} />
      }
      {
        type == 'kpi_tracker_inventory' && <KPIInventoryEntryComponent params={params} wrapper_id={wrapperId} />
      }
    </div>);
}

export { EntryComponent };
