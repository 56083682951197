import { useEffect } from 'react';
import Simulate from './Simulate';
import {Performance} from './Performance';
import PerformanceHistory from './PerformanceHistory';
import { useState } from 'react';
import Dropdown from 'components/parts/Dropdown';
import { StandardButton2 } from 'components/misc/Layouts';
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import ForecastInterpretation from './ForecastInterpretation';

const ForecastDetails = ({ onClose, onSave, trackerParams, entityParams, entityData, selectedScenarioId, wrapper_id, tracker_id, isPrimary, performanceData}) => {
    const navigate = useNavigate();

    const [scenarioParams, setScenarioParams] = useState({})
    const [selectedView, setSelectedView] = useState('forecast interpretation')
    const [userData, setUserData] = useState({})
    useEffect(() => {
        console.log('entity', entityParams, selectedScenarioId);
        if (entityParams && entityParams.scenarios) {
            const matchingScenario = entityParams.scenarios.find(scenario => scenario.id === selectedScenarioId);
            setScenarioParams(matchingScenario)
            console.log('matched', matchingScenario)
        }
    }, [entityParams, selectedScenarioId]);

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user && !userData) {
        const uid = user.uid;
        setUserData(user)
      } else if (!user) {
        navigate('/sign_in')
      }
    });

    useEffect(() => {
        if (Object.keys(userData).length > 0) { 
            const url = new URL('https://activitylog-2m4x72kr4q-uc.a.run.app');
            url.searchParams.append('user_id', userData.uid);
            url.searchParams.append('activity_type', 'loaded forecast details');
        
            fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then(response => response.json())
            .then(data => console.log('Activity logged:', data))
            .catch(error => console.error('Error logging activity:', error));
        }
    }, [userData])
  
    return (
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'flex-start',
        justifyContent: 'center', padding: '50px 0', zIndex: 1000 }}>
        <div style={{ position: 'relative', width: '90%', maxHeight: 'calc(100% - 100px)', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', overflowY: 'auto' }}>
            <StandardButton2 onClick={onClose}>Close</StandardButton2>
            <Dropdown options={["forecast components", "forecast performance", "forecast interpretation"]} onSelect={setSelectedView} selectedOption={selectedView} />
                {selectedView == 'forecast components' && 
                    <Performance
                            historyData={performanceData}
                            scenarioParams={scenarioParams}
                            trackerParams={trackerParams}
                    />
                }
                {selectedView == 'forecast performance' && 
                    <PerformanceHistory 
                        historyData={performanceData}
                        scenarioParams={scenarioParams}
                        targetValue={entityParams.target_value}
                    />
                }
                {selectedView == 'forecast interpretation' && 
                  <ForecastInterpretation scenarioParams={scenarioParams} 
                    trackerParams={trackerParams}
                  />
                }
        </div>
      </div>
    );
  };
  
  export default ForecastDetails;