import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import { Container, Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts";
import tw from "twin.macro";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";


const SectionContainer = tw(ContentWithVerticalPadding)``;
const SectionHeading = tw(HeadingBase)`text-primary`;
const PreviewButton = tw(PrimaryButtonBase)`w-full rounded-b-lg rounded-t-none py-5 font-semibold`;
const SectionDescription = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-4xl`;
const Heading = tw(HeadingBase)`text-center lg:text-left text-primary leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const PreviewCard = tw(motion.a)`block rounded-lg shadow-red-50`;
const TextArea = tw.textarea`w-full px-4 py-2 mt-2 text-base border border-gray-300 rounded-md focus:outline-none focus:border-primary`;


export default () => {
  const navigate = useNavigate();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [name, setName] = useState(''); 
  const [email, setEmail] = useState(''); 
  const [phone, setPhone] = useState(''); 
  const [description, setDescription] = useState('')
  const [instructions, setInstructions] = useState('');

  function fileChange(event) {
    setSelectedFiles(event.target.files);
  }

  function createBot(event) {
    event.preventDefault();
    // const url = 'http://127.0.0.1:5001/circleops-5f8c7/us-central1/hello_world'
    // const url = 'https://us-central1-circleops-5f8c7.cloudfunctions.net/hello_world'

    // const formData = new FormData();
    // formData.append('name', name);
    // formData.append('instructions', instructions);
    // for (let i = 0; i < selectedFiles.length; i++) {
    //   formData.append('files', selectedFiles[i]);
    // }

    // fetch(url, {
    //   method: 'POST',
    //   body: formData,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log('File uploaded:', data);
    //     navigate('/')
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });
    navigate('/')
  }

  return (
    <AnimationRevealPage disabled>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <div style={{ width: '80%', margin: '0 auto' }}>
          <SectionContainer>
            <SectionHeading>Reach Out</SectionHeading>
            <p>Talk to a team member and we'll make sure you're set up for success when you start.</p>
            <InputContainer>
              <Label htmlFor="name-input">Name</Label>
              <Input id="name-input" type="text" name="name" placeholder="E.g. John Doe" value={name} onChange={(e) => setName(e.target.value)} style={{ border: '1px solid black', width: "100%"}} />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="email-input">Email</Label>
              <Input id="email-input" type="text" name="email" placeholder="E.g. sales@thezap.co" value={email} onChange={(e) => setEmail(e.target.value)} style={{ border: '1px solid black', width: "100%" }} />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="phone-input">Phone Number</Label>
              <Input id="phone-input" type="text" name="phone" placeholder="E.g. (777) 777-7777" value={phone} onChange={(e) => setPhone(e.target.value)} style={{ border: '1px solid black', width: "100%" }} />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="phone-input"> Additional information </Label>
              <textarea id="description" name="description" rows="4" style={{ width: "100%", border: "1px solid #ccc", resize: "none", marginRight: '20px' }} onChange={(e) => setDescription(e.target.value)}></textarea>
            </InputContainer>

            <PreviewCard>
              <PreviewButton onClick={createBot}>Submit</PreviewButton>
            </PreviewCard>
          </SectionContainer>
        </div>
      </Container>
    </AnimationRevealPage>
  );
};