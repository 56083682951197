import React, { useState } from 'react';
import { MenuOption, DropdownOption } from 'components/misc/Layouts';

function MultiSelectDropdown({ options, selectedOptions, onChange }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    const currentIndex = selectedOptions.indexOf(option);
    const newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }

    onChange(newSelectedOptions);
  };

  return (
    <div style={{ position: 'relative' }}>
      <button 
          onClick={() => setIsOpen(!isOpen)}
          style={{ 
                border: '1px solid #ccc',
                borderRadius: '4px', 
                paddingTop: '0', // No padding at the top
                paddingBottom: '0', // No padding at the bottom
                paddingLeft: '12px', // Horizontal padding on the left
                paddingRight: '12px', // Horizontal padding on the right
                cursor: 'pointer',
                outline: 'none',
                backgroundColor: '#fff', // Optional: Background color
                boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', // Optional: Box shadow for depth
                display: 'flex', // Center the text vertically
                alignItems: 'center', // Align items center
                justifyContent: 'space-between', // Space between text and arrow icon
                maxWidth: '300px' // Max width for the dropdown button
          }}
      >
          <MenuOption>{selectedOptions.length} filters</MenuOption>
      </button>
      {isOpen && (
        <ul style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            zIndex: 1000,
            minWidth: '100%',
            width: 'auto',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            borderRadius: '4px',
            padding: '0',
            margin: '0',
            maxHeight: '200px', // Maximum height before scrolling
            overflowY: 'auto', // Allows scrolling
            maxWidth: '300px' // Max width for the dropdown list
        }}>
            {options.map((option) => (
                <li key={option} style={{
                    padding: '10px 12px',
                    cursor: 'pointer',
                    listStyleType: 'none',
                }}>
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            id={option}
                            checked={selectedOptions.includes(option)}
                            onChange={() => handleSelect(option)}
                            style={{ marginRight: '10px' }}
                        />
                        <DropdownOption htmlFor={option}>{option}</DropdownOption>
                    </label>
                </li>
            ))}
        </ul>
      )}
    </div>
  );
}

export default MultiSelectDropdown;
