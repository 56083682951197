import React, { useState } from 'react';

function ViewToggle({isCumulative, onChange}) {
    const styles = {
        switchContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Align items to the left
            margin: '20px 20px 20px 0', // Remove left margin by setting it to zero
        },
        switchLabel: {
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#666',
            padding: '10px 10px 10px 0', // Remove left padding by setting it to zero
        },
        switchBase: {
            position: 'relative',
            display: 'inline-block',
            width: '80px',
            height: '34px',
            marginLeft: '0', // Ensure there's no left margin
            marginRight: '10px',
        },
        input: {
            opacity: 0,
            width: 0,
            height: 0,
        },
        slider: {
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#ccc',
            transition: '.4s',
            borderRadius: '34px',
        },
        sliderBefore: {
            position: 'absolute',
            content: '""',
            height: '26px',
            width: '26px',
            left: '4px',
            bottom: '4px',
            backgroundColor: 'white',
            transition: '.4s',
            borderRadius: '50%',
        },
        sliderChecked: {
            backgroundColor: '#2196F3',
        },
        sliderBeforeChecked: {
            transform: 'translateX(46px)', // Adjust if necessary to fit the slider movement
        },
        activeLabel: {
            fontWeight: 'bold',
            color: '#333',
        }
    };

    return (
        <div style={styles.switchContainer}>
            <span 
                style={{
                    ...styles.switchLabel, 
                    ...(isCumulative ? {} : styles.activeLabel)
                }}
                onClick={() => onChange(false)}
            >
                Day by Day
            </span>
            <label style={styles.switchBase}>
                <input 
                    type="checkbox" 
                    checked={isCumulative} 
                    onChange={() => onChange(!isCumulative)} 
                    style={styles.input}
                />
                <span style={{...styles.slider, ...(isCumulative ? styles.sliderChecked : {})}}>
                    <span style={{...styles.sliderBefore, ...(isCumulative ? styles.sliderBeforeChecked : {})}} />
                </span>
            </label>
            <span 
                style={{
                    ...styles.switchLabel, 
                    ...(isCumulative ? styles.activeLabel : {})
                }}
                onClick={() => onChange(true)}
            >
                Cumulative
            </span>
        </div>
    );
}

export default ViewToggle;
