import React, { useState } from 'react';
import { BasicParagraph, DropdownOption, MenuOption, PlainText } from 'components/misc/Layouts';

function Dropdown({ selectedOption, options, onSelect, mapping={} }) {
    const [isOpen, setIsOpen] = useState(false);
    console.log('dropdown', selectedOption, mapping)
  
    return (
        <div style={{ position: 'relative' }}>
            <button 
                onClick={() => setIsOpen(!isOpen)}
                style={{ 
                    border: '1px solid #ccc',
                    borderRadius: '4px', 
                    paddingTop: '0', // No padding at the top
                    paddingBottom: '0', // No padding at the bottom
                    paddingLeft: '12px', // Horizontal padding on the left
                    paddingRight: '12px', // Horizontal padding on the right
                    cursor: 'pointer',
                    outline: 'none',
                    backgroundColor: '#fff', // Optional: Background color
                    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)', // Optional: Box shadow for depth
                    display: 'flex', // Center the text vertically
                    alignItems: 'center', // Align items center
                    justifyContent: 'space-between' // Space between text and arrow icon
                }}                
            >
                                <MenuOption>
                    {selectedOption != null ? (mapping[selectedOption] || selectedOption) : 'Please select value'}
                </MenuOption>
            </button>
  
            {isOpen && (
                <ul style={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    zIndex: 1000,
                    minWidth: '100%', // Ensures the dropdown is at least as wide as the button
                    width: 'auto', // Allows the dropdown to expand based on content
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                    maxHeight: '200px', // Set a maximum height
                    overflowY: 'auto'
                }}>
                    {options.map((option, index) => (
                        <li key={index} onClick={() => {
                            setIsOpen(false);
                            if (onSelect) {  // Check if onSelect is provided
                                onSelect(option);
                            }
                        }} style={{
                            padding: '10px 12px',
                            cursor: 'pointer',
                            listStyleType: 'none',
                        }}>
                            <DropdownOption>
                                {option != null ? (mapping[option] || option) : <i>None</i>}
                            </DropdownOption>
                        </li>
                    ))}
                </ul>
            )}

        </div>
    );
}

export default Dropdown;
