import { PlainText, SecondaryButton, SubHeader } from 'components/misc/Layouts';
import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js'; 
import TableComponent from 'components/TableComponent';

function Dropdown({ selectedOption, options, onSelect }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
      <div style={{ position: 'relative' }}>
          <button 
              onClick={() => setIsOpen(!isOpen)}
              style={{ 
                  border: '1px solid #ccc', 
                  borderRadius: '4px', 
                  padding: '6px 12px', 
                  cursor: 'pointer',
                  outline: 'none'
              }}
          >
              {selectedOption ? selectedOption.name : 'Please select value'}
          </button>

          {isOpen && (
              <ul style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  zIndex: 1000
              }}>
                  {options.map((option, index) => (
                      <li key={index} onClick={() => {
                          setIsOpen(false);
                          if (onSelect) {  // Check if onSelect is provided
                              onSelect(option);
                          }
                      }}>
                          {option.name}
                      </li>
                  ))}
              </ul>
          )}
      </div>
  );
}

const downloadFile = (filepath) => {
    // const url = 'http://127.0.0.1:5001/circleops-5f8c7/us-central1/downloadFile?file_path=' + filepath
    const url = 'https://us-central1-circleops-5f8c7.cloudfunctions.net/downloadFile?file_path=' + filepath
    return fetch(url, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
      },
    }).then((response) => response.json())
}

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US") + ' ' + date.toLocaleTimeString("en-US");
};

const ReportModal = ({onClose, report_id, user_id, id }) => {
  const [reportTitle, setReportTitle] = useState('');
  const [reportNotes, setReportNotes] = useState('');
  const [availableReports, setAvailableReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(report_id);

  // Fetch available reports
  useEffect(() => {
    if (user_id) {
      fetch(`http://127.0.0.1:5001/circleops-5f8c7/us-central1/grabReports?user_id=${user_id}`)
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
        console.log('data', data)
        setAvailableReports(data)

        if (report_id) {
          const foundReport = data.find(report => report.id === report_id);
          if (foundReport) {
              setSelectedReport(foundReport);
          }
        }
      })
      .catch(error => console.error('Error fetching reports:', error));
    }
}, [user_id]);

  // Save function
  const save = () => {
      const url = 'http://127.0.0.1:5001/circleops-5f8c7/us-central1/saveToReport';
      const requestBody = {
          report_id: selectedReport.id || report_id,
          card_id: id,
          title: reportTitle, 
          notes: reportNotes
      };

      fetch(url, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody)
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          // Handle successful save
          onClose();
      })
      .catch(error => {
          console.error('Error saving report:', error);
      });
  };

  return (
      <div className="modal-content" style={{ /* styles */ }}>
          <button onClick={() => onClose()} style={{ /* styles */ }}>&times;</button>
          <SubHeader>Save to Report</SubHeader>
          <input
              type="text"
              placeholder="Title"
              value={reportTitle}
              onChange={(e) => setReportTitle(e.target.value)}
              style={{ /* styles */ }}
          />
          <br />
          <b>Select Report</b>
          <Dropdown 
            selectedOption={selectedReport}
            options={availableReports}
            onSelect={(option)=> {setSelectedReport(option)}}
          />

          <br />
          <textarea
              placeholder="Notes"
              value={reportNotes}
              onChange={(e) => setReportNotes(e.target.value)}
              style={{ /* styles */ }}
          />
          <button onClick={save}>Save</button>
      </div>
  );
};

const VisualizeCard = ({ item, onRender, homePage=false, reportView=false, user_id, report_id}) => {
    const [outputData, setOutputData] = useState({});
    const [showReportModal, setShowReportModal] = useState(false)

    useEffect(() => {
      if (item) {
        const filePath = item.output_parameters.file;
        downloadFile(filePath)
          .then((output_contents) => {
            setOutputData(output_contents)
          })
      }
    }, [item])

    const handleButtonClick = async () => {
      const data = {
        ...item, data: outputData
      }
      if (homePage) {
        onRender(data.source_id, data); 
      } else {
        onRender(data);
      }
    };
    const saveToReport = () => {
      console.log('saving...true')
      setShowReportModal(true)
    }

    return (
      <div>
        {homePage && item.sourceData && <PlainText>Source Name: {item.sourceData.name}</PlainText>}
        {homePage && <PlainText><a>ID: {item.source_id}</a></PlainText>}
        {showReportModal && <ReportModal onClose={() => setShowReportModal(false)} user_id={user_id} id={item.id} report_id={report_id}/>}
        <PlainText>bot used: {item.bot_used}</PlainText>
        <PlainText>Date Created: {formatDate(item.date_created)}</PlainText>
        {/* <PlainText>Prompt: {item.input_parameters.command}</PlainText>
        <PlainText>Description: {item.output_parameters.description}</PlainText> */}

        <Plot
            style={{ width: '100%' }}
            data={outputData.data}
            layout={outputData.layout}
        />
        
        <SecondaryButton onClick={handleButtonClick}>Ask Follow-Up</SecondaryButton>
        {!reportView && <SecondaryButton onClick={saveToReport}>Save to Report</SecondaryButton>}
        
      </div>
    );
};

const TableCard = ({ item, onRender, homePage=false, reportView=false, user_id, report_id}) => {
  const [outputData, setOutputData] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false)

  useEffect(() => {
    if (item) {
      const filePath = item.output_parameters.file;
      downloadFile(filePath)
        .then((output_contents) => {
          setOutputData(output_contents)
        })
    }
  }, [item])

    const handleButtonClick = async () => {
      const data = {
        ...item, data: outputData
      }
      if (homePage) {
        onRender(data.source_id, data); 
      } else {
        onRender(data);
      }
    };

    const saveToReport = () => {
      console.log('saving...true')
      setShowReportModal(true)
    }

    return (
      <div>
        {homePage && item.sourceData && <PlainText>Source Name: {item.sourceData.name}</PlainText>}
        {homePage && <PlainText><a href={`./source?id=${item.source_id}`}>ID: {item.source_id}</a></PlainText>}
        {showReportModal && <ReportModal onClose={() => setShowReportModal(false)} user_id={user_id} id={item.id} report_id={report_id}/>}
        <PlainText>bot used: {item.bot_used}</PlainText>
        <PlainText>Date Created: {formatDate(item.date_created)}</PlainText>
        {/* <PlainText>Prompt: {item.input_parameters.command}</PlainText>
        <PlainText>Response: {item.output_parameters.description} </PlainText> */}
        {outputData.length > 0 && <TableComponent data={outputData}/>}
        <SecondaryButton onClick={handleButtonClick}>Ask Follow-Up</SecondaryButton>
        {!reportView && <SecondaryButton onClick={saveToReport}>Save to Report</SecondaryButton>}

      </div>
    );
};

const ForecastCard = ({ item, onRender, homePage=false, reportView=false, user_id, report_id}) => {
    const [showReportModal, setShowReportModal] = useState(false)

    const handleButtonClick = async () => {
      console.log('handling', item)
      const filePath = item.output_parameters.file;
      downloadFile(filePath)
        .then((output_contents) => {
          const data = {
            ...item, data: output_contents
          }
          if (homePage) {
            onRender(data.source_id, data); 
          } else {
            onRender(data);
          }
        })
    };

    const saveToReport = () => {
      console.log('saving...true')
      setShowReportModal(true)
    }

    return (
      <div>
        {homePage && item.sourceData && <PlainText>Source Name: {item.sourceData.name}</PlainText>}
        {homePage && <PlainText><a href={`./source?id=${item.source_id}`}>ID: {item.source_id}</a></PlainText>}
        {showReportModal && <ReportModal onClose={() => setShowReportModal(false)} user_id={user_id} id={item.id} report_id={report_id}/>}
        <PlainText>bot used: {item.bot_used}</PlainText>
        <PlainText>Date Created: {formatDate(item.date_created)}</PlainText>
        <PlainText>Input Parameters:</PlainText>
        <li>Change Option: {item.input_parameters.change_option}</li>
        <li>Date Option: {item.input_parameters.date_option} </li>
        <li>Forecast Length:{item.input_parameters.duration_value}</li>
        <br /> <br />
        {item.output_parameters.summary && (
            <PlainText>
                Preview: {item.input_parameters.change_option} is projected to have {item.output_parameters.summary.total} from {new Date(item.output_parameters.summary.start_date.seconds * 1000).toLocaleString()} to {new Date(item.output_parameters.summary.end_date.seconds * 1000).toLocaleString()}.
            </PlainText>
        )}
        <SecondaryButton onClick={handleButtonClick}>View More Details/Ask Follow-Up</SecondaryButton>
        {!reportView && <SecondaryButton onClick={saveToReport}>Save to Report</SecondaryButton>}
      </div>
    );
};

const TrendsCard = ({ item, onRender, homePage=false, reportView=false, user_id, report_id}) => {
  const [showReportModal, setShowReportModal] = useState(false)
  const rateClass = item.output_parameters.current_change_rate > 0 ? 'rate-positive' : 'rate-negative';

  const handleButtonClick = async () => {
    console.log('handling', item)
    const filePath = item.output_parameters.file;
    downloadFile(filePath)
      .then((output_contents) => {
        const data = {
          ...item, data: output_contents
        }
        if (homePage) {
          onRender(data.source_id, data); 
        } else {
          onRender(data);
        }
    })
  };

  const saveToReport = () => {
    console.log('saving...true')
    setShowReportModal(true)
  }

    return (
      <div>
        {homePage && item.sourceData && <PlainText>Source Name: {item.sourceData.name}</PlainText>}
        {homePage && <PlainText><a href={`./source?id=${item.source_id}`}>ID: {item.source_id}</a></PlainText>}
        {showReportModal && <ReportModal onClose={() => setShowReportModal(false)} user_id={user_id} id={item.id} report_id={report_id}/>}
        <PlainText>bot used: {item.bot_used}</PlainText>
        <PlainText>Date Created: {formatDate(item.date_created)}</PlainText>
        <PlainText>Input Parameters:</PlainText>
        <li>Change Option: {item.input_parameters.change_option}</li>
        <li>Date Option: {item.input_parameters.date_option} </li>
        <PlainText>Preview: The most recent rate of change is <span className={rateClass}>{item.output_parameters.current_change_rate}</span> /day (from: {item.output_parameters.final_start_date} to {item.output_parameters.final_end_date}). </PlainText>
        <SecondaryButton onClick={handleButtonClick}>View More Details/Ask Follow-Up</SecondaryButton>
        {!reportView && <SecondaryButton onClick={saveToReport}>Save to Report</SecondaryButton>}
      </div>
    );
};

export {VisualizeCard, TableCard, TrendsCard, ForecastCard};