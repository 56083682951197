import { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import ViewToggle from './ViewToggle';
import { BasicParagraph, BasicParagraph2, BasicParagraphStrong, CodeBlock, PlainText, SecondaryButton, StandardButton2, StyledInput, StyledSpinner } from 'components/misc/Layouts';
import { Table, Thead, Trow, Th, Tbody, Td } from 'components/misc/Layouts';
import { PopupModal, PopupModal2 } from './TargetCreator';
import MultiSelectDropdown2 from 'components/parts/MultiSelectDropdown2';
import AnalyzeForecast from 'components/SubComponents/AnalyzeForecast';
import Dropdown from 'components/parts/Dropdown';
import ForecastDetails from './PopupModals';

const downloadFile = (filepath) => {
    const url = 'https://us-central1-circleops-5f8c7.cloudfunctions.net/downloadFile?file_path=' + filepath
    // let url = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/downloadFile?file_path=${filepath}`

    return fetch(url, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
      },
    }).then((response) => response.json())
  }
  

const KPIVisualizer = ({ startDate, endDate, guidance, baselines, actual_data, setting_target=false}) => {
    const [plotData, setPlotData] = useState([]);
    const [isCumulative, setIsCumulative] = useState(true)
    const [guidanceSum, setGuidanceSum] = useState(0)
    const [normalizedValue, setNormalizedValue] = useState(0)
    const [headerData, setHeaderData] = useState({
        guidance_total: 0,
        current_total: 0, 
        baselines: []
    })

    useEffect(() => {
        let newHeaderData = {
            'guidance_total': 0,
            'current_total': 0, 
            'baselines': []
        };
        let nowTimestamp = Date.now()/1000;
        console.log('nowTimestamp', nowTimestamp)

        if (setting_target) {
            if (guidance.length == 0) return;
            let guidanceSum = guidance[0].data.reduce((sum, item) => sum + item.value, 0);
            newHeaderData['guidance_total'] = guidanceSum
            console.log('guidance sum', guidanceSum)

            for (let baseline of baselines) {
                const baselineSum = baseline.data.reduce((sum, item) => {
                    return sum + item.value
                }, 0);
                newHeaderData['baselines'].push({'name': baseline.name, value: (guidanceSum - baselineSum) / baselineSum});
            }
            setHeaderData(newHeaderData)
        } else {
            if (actual_data.length == 0) return;
            let actualDataSum = actual_data[0].data.reduce((sum, item) => sum + item.value, 0);
            newHeaderData['current_total'] = actualDataSum

            if (guidance.length > 0) {
                const guidanceSum = guidance[0].data.reduce((sum, item) => {
                    if (item.timestamp < nowTimestamp) {
                        return sum + item.value;
                    }
                    return sum;
                }, 0);
                newHeaderData['baselines'].push({'name': 'target', value:(actualDataSum - guidanceSum) / guidanceSum});
            }

            for (let baseline of baselines) {
                const baselineSum = baseline.data.reduce((sum, item) => {
                    if (item.timestamp < nowTimestamp) {
                        return sum + item.value;
                    }
                    return sum;
                }, 0);
                newHeaderData['baselines'].push({'name': baseline.name, value: (actualDataSum - baselineSum) / baselineSum});
            }

            setHeaderData(newHeaderData);
        }
    }, [baselines, actual_data, guidance]);

    useEffect(() => {
        if (guidance.length > 0) {
            const totalSum = guidance[0].data.reduce((acc, item) => acc + item.value, 0);
            setGuidanceSum(totalSum);
        }
    }, [guidance])

    useEffect(() => {
        const calculateDaysDifference = () => {
            if (startDate && endDate) {
                const oneDay = 24 * 60 * 60; 
                return Math.round(Math.abs((new Date(startDate) - new Date(endDate)) / oneDay));
            }
            return 0;
        };

        const daysDifference = calculateDaysDifference();

        if (isCumulative) {
            setNormalizedValue(guidanceSum);
        } else {
            if (daysDifference > 0) { 
                setNormalizedValue(guidanceSum/daysDifference);
            } else {
                setNormalizedValue(0)
            }
        }
    }, [startDate, endDate, isCumulative, guidanceSum])
    
    const shapes = [];

    if (startDate) {
        shapes.push({
            type: 'line',
            x0: new Date(startDate * 1000).toISOString(),
            y0: 0,
            x1: new Date(startDate * 1000).toISOString(),
            y1: 1,
            xref: 'x',
            yref: 'paper',
            line: {
                color: 'green',
                width: 2,
                dash: 'dot'
            }
        });
    }

    // Add a vertical line shape at the endDate, if defined
    if (endDate) {
        shapes.push({
            type: 'line',
            x0: new Date(endDate * 1000).toISOString(),
            y0: 0,
            x1: new Date(endDate * 1000).toISOString(),
            y1: 1,
            xref: 'x',
            yref: 'paper',
            line: {
                color: 'green',
                width: 2,
                dash: 'dot'
            }
        });
    }

    shapes.push({
        type: 'line',
        x0: 0,
        y0: normalizedValue,
        x1: 1,
        y1: normalizedValue,
        xref: 'paper',
        yref: 'y',
        line: {
            color: 'orange',
            width: 2,
            dash: 'dot'
        }
    });

    // Define the layout with the dynamic shapes array
    const layout = {
        shapes: shapes,
        xaxis: {
            type: 'date'
        },
        showlegend: true,

        
    };

    const getRandomLightColor = () => {
        // List of five predefined light colors
        const colors = [
            'hsl(210, 70%, 85%)', // Light blue
            'hsl(280, 70%, 85%)', // Light purple
            'hsl(0, 70%, 85%)'    // Light red
        ];
    
        // Randomly select one of the colors
        const index = Math.floor(Math.random() * colors.length);
        return colors[index];
    };
    

    const processDataset = (dataset) => {
      return dataset.map(item => {
          let cumulativeTotal = 0;
          let combinedData = item.data.map(point => ({
              x: new Date(point.timestamp * 1000).toISOString(),
              y: point.value
          }));
          combinedData.sort((a, b) => new Date(a.x) - new Date(b.x));
          let color;
          if (item.name === 'Simulation' || item.name === 'Guidance') {
              color = 'darkblue';
          } else if (item.name === 'Actual Data') {
              color = 'darkgreen'; // Specific solid color for 'Actual Data'
          } else if (item.name == 'Last Year') {
              color = 'hsl(210, 70%, 85%)'; // Random light color for other categories
          } else {
              color = 'hsl(280, 70%, 85%)'
          }
          let xValues = combinedData.map(data => data.x);
          let yValues = combinedData.map(data => {
              if (isCumulative) {
                  cumulativeTotal += data.y;
                  return cumulativeTotal;
              } else {
                  return data.y;
              }
          });
          return {
              x: xValues,
              y: yValues,
              type: 'scatter',
              mode: 'lines+markers',
              marker: {
                color: color
            },
              name: item.name + '     '
          };
      });
  };

  useEffect(() => {
      console.log('processing dataset')

      let guidanceData = processDataset(guidance);
      let baselineData = processDataset(baselines);
      let actualData = processDataset(actual_data); 

      setPlotData([...guidanceData, ...baselineData, ...actualData]);
  }, [guidance, baselines, actual_data, isCumulative]);


    const calculateCumulativeSum = (data) => {
        return data.reduce((acc, point) => acc + point.value, 0);
    };

    const calculatePercentageDifference = (cumulativeSum) => {
        return ((cumulativeSum - referenceSum) / referenceSum * 100).toFixed(2);
    };

    const referenceSum = guidance.length ? calculateCumulativeSum(guidance[0].data) : null;

    const calculateBaselineNumber = (baseline) => {
        console.log('calculating baseline', baseline)
        const cumulativeSum = baseline.data.reduce((acc, item) => acc + item.value, 0);
        const percentageChange = guidanceSum > 0 ? ((cumulativeSum - guidanceSum) / guidanceSum * 100) : 0;
        const formattedNumber = `${cumulativeSum.toFixed(2)} (${percentageChange.toFixed(2)}%)`;
        const color = percentageChange > 0 ? 'green' : percentageChange < 0 ? 'red' : 'black';  // Adjust colors as needed
        return { formattedNumber, color };
    };
    
    

    return (
        <div style={{width: '100%'}}>

            {!setting_target && <Table border="1" style={{ width: '100%', textAlign: 'center' }}>
                <Thead>
                    <Trow>
                        <Th></Th>
                        <Th>Current Total</Th>
                        {headerData.baselines.map((baseline, index) => (
                            <Th key={index}>VS. {baseline.name}</Th>
                        ))}
                    </Trow>
                </Thead>
                <Tbody>
                <Trow>
                    <Td>{headerData['current_total'].toFixed(2)}</Td>
                    {headerData.baselines.map((baseline, index) => {
                        const formattedValue = parseFloat(baseline.value) * 100;
                        const displayValue = (formattedValue > 0 ? "+" : "") + formattedValue.toFixed(2);
                        return <Td key={index}>{displayValue}%</Td>; 
                    })}
                </Trow>
                </Tbody>
            </Table>}
            {setting_target && <Table border="1" style={{ width: '100%', textAlign: 'center' }}>
                <Thead>
                    <Trow>
                        <Th>Simulated Total</Th>
                        {headerData.baselines.map((baseline, index) => (
                            <Th key={index}>VS. {baseline.name}</Th>
                        ))}
                    </Trow>
                </Thead>
                <Tbody>
                <Trow>
                    <Td>{headerData['guidance_total'].toFixed(2)}</Td>
                    {headerData.baselines.map((baseline, index) => {
                        const formattedValue = parseFloat(baseline.value) * 100;
                        const displayValue = (formattedValue > 0 ? "+" : "") + formattedValue.toFixed(2);
                        return <Td key={index}>{displayValue}%</Td>; 
                    })}
                </Trow>
                </Tbody>
            </Table>}

            <ViewToggle isCumulative={isCumulative} onChange={setIsCumulative} />
            <Plot
                data={plotData}
                layout={layout}
                useResizeHandler
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
};

const KPIEntryComponent = ({ wrapper_id, params }) => {
  const [simulationData, setSimulationData] = useState({})
  const [selectedIndex, setSelectedIndex] = useState()
  const [targetData, setTargetData] = useState([])
  const [actualData, setActualData] = useState([])
  const [baselines, setBaselines] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [aggregatedTarget, setAggregatedTarget] = useState({})

  useEffect(() => {
    if (params.targets.length != params.state.data.length) {
        setTargetData([])
    } else {
        let updatedTargets = params.targets.map((target, index) => {
            let total_guidance = target.data.reduce((total, item) => total + item.value, 0);
            let guidance_pace = target.data.reduce((total, item) => {
                return item.timestamp < params.state.last_processed_timestamp ? total + item.value : total;
            }, 0);
            return {
                filters: target.metadata.filters,
                total: params.state.data[index],
                guidance_pace: guidance_pace,
                total_guidance: total_guidance,
                off_guidance: (params.state.data[index] - guidance_pace)/guidance_pace,
                data: {...target, name: 'Guidance'}
            }
        });
        if (updatedTargets.length > 1) {
            const totalSum = updatedTargets.reduce((acc, target) => acc + target.total, 0);
            const guidancePaceSum = updatedTargets.reduce((acc, target) => acc + target.guidance_pace, 0);
            const totalGuidanceSum = updatedTargets.reduce((acc, target) => acc + target.total_guidance, 0);
            let combinedFilters = updatedTargets.flatMap(target => target.filters);

            let timestampValueMap = new Map();
            updatedTargets.forEach(target => {
                target.data.data.forEach(item => {
                    let existingValue = timestampValueMap.get(item.timestamp) || 0;
                    timestampValueMap.set(item.timestamp, existingValue + item.value);
                });
            });

            // Convert the Map to an array
            let aggregatedDataArray = Array.from(timestampValueMap, ([timestamp, value]) => ({ timestamp, value })).sort((a, b) => a.timestamp - b.timestamp);
    
            const aggregatedTarget = {
                metadata: {},
                filters: combinedFilters, 
                total: totalSum, // sum over all targets in filters
                guidance_pace: guidancePaceSum, 
                total_guidance: totalGuidanceSum , // sum over everything
                off_guidance: (totalSum - guidancePaceSum)/guidancePaceSum, 
                data: {
                    metadata: {...updatedTargets[0].data.metadata, filters: combinedFilters},
                    data: aggregatedDataArray,
                    name: 'Guidance'
                }
            }
            setAggregatedTarget(aggregatedTarget)
        }
        console.log('updatedTargets', updatedTargets)
    
        setTargetData(updatedTargets);
    }

}, [params, wrapper_id]);


  // Handle click event for each row in the table
  const handleRowClick = (index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (selectedIndex != null && selectedIndex != 'aggregated') {
        let modelParams = {
            option: targetData[selectedIndex].data.metadata.option,
            filterValue: targetData[selectedIndex].data.metadata.filters,
            guidance: [targetData[selectedIndex].data]
        } 
        if (targetData[selectedIndex].data.option == 'ai_forecast') {
            modelParams['confidenceThreshold'] = targetData[selectedIndex].data.metadata.confidence_threshold
        } else {
            modelParams['queryStartDate'] = targetData[selectedIndex].data.metadata.query_start_date
            modelParams['queryEndDate'] = targetData[selectedIndex].data.metadata.query_end_date
        }

        let object = {
            trackerParams: {
                groupBy: params.tracker_params.group_by, 
                dateOption: params.tracker_params.date_option, 
                changeOption: {
                    type: params.tracker_params.change_option.type, 
                    variable: params.tracker_params.change_option.variable
                }, 
                wrapper_id: wrapper_id,
                startDate: params.tracker_params.start_date, 
                endDate: params.tracker_params.end_date
            },
            modelParams: modelParams
        }

        setSimulationData(object)
    }

  }, [selectedIndex])

  useEffect(() => {
    if (selectedIndex == null) return;

    const fetchBaselineData = async (startDate, endDate, desiredStartDate, name, filters) => {
        let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/queryData?wrapper_id=${wrapper_id}&dateOption=${params.tracker_params.date_option}&timeUnit=day&start_date=${startDate}&end_date=${endDate}`;
        requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/queryData?wrapper_id=${wrapper_id}&dateOption=${params.tracker_params.date_option}&timeUnit=day&start_date=${startDate}&end_date=${endDate}`

        if (params.tracker_params.change_option.type === 'SUM') {
            requestUrl += `&variable=${params.tracker_params.change_option.variable}`;
        }
        requestUrl += `&inclusive=${true}&joinType=OR`

        if (filters.length > 0) {
            const filterStrings = filters.map(filter => 
                `${encodeURIComponent(filter.key)}=${encodeURIComponent(filter.value)}`
            );
            const filterOptions = filterStrings.join(';');
            requestUrl += `&filterOptions=${filterOptions}`;
        }

        try {
            const response = await fetch(requestUrl);
            const data = await response.json();
            const convertedData = data.map(item => ({
                timestamp: new Date(item.date).getTime() / 1000 + (desiredStartDate - startDate),
                value: parseFloat(item.value)
            }));
            return { data: convertedData, name };
        } catch (error) {
            console.error('Error fetching baseline data:', error);
            return null;
        }
    };

    const calculateLastPeriodDates = (startDate, endDate) => {
        let newStartDate = new Date(startDate * 1000);
        let newEndDate = new Date(endDate * 1000);
        let duration = newEndDate - newStartDate;
        
        newStartDate = new Date(newStartDate.getTime() - duration);
        newEndDate = new Date(newEndDate.getTime() - duration);
        
        return {
            startDate: Math.floor(newStartDate.getTime() / 1000),
            endDate: Math.floor(newEndDate.getTime() / 1000)
        };
    };

    const calculateLastYearDates = (startDate, endDate) => {
        let newStartDate = new Date(startDate * 1000);
        let newEndDate = new Date(endDate * 1000);

        newStartDate.setFullYear(newStartDate.getFullYear() - 1);
        newEndDate.setFullYear(newEndDate.getFullYear() - 1);

        return {
            startDate: Math.floor(newStartDate.getTime() / 1000),
            endDate: Math.floor(newEndDate.getTime() / 1000)
        };
    };

    const queryBaselines = async () => {
        let start_date; 
        let end_date;
        let filters;
        if (selectedIndex == 'aggregated') {
            start_date = aggregatedTarget.data.metadata.start_date 
            end_date = aggregatedTarget.data.metadata.end_date
            filters = aggregatedTarget.data.metadata.filters
        } else {
            start_date = targetData[selectedIndex].data.metadata.start_date
            end_date = targetData[selectedIndex].data.metadata.end_date
            filters = targetData[selectedIndex].data.metadata.filters
        }
        const lastPeriodDates = calculateLastPeriodDates(start_date, end_date);
        const lastYearDates = calculateLastYearDates(start_date, end_date);

        const lastPeriodData = await fetchBaselineData(lastPeriodDates.startDate, lastPeriodDates.endDate, start_date, 'Last Period', filters);
        const lastYearData = await fetchBaselineData(lastYearDates.startDate, lastYearDates.endDate, start_date, 'Last Year', filters);
        setBaselines([lastPeriodData, lastYearData]);
    };

    const fetchActualData = async () => {
        let metadata;
        if (selectedIndex == 'aggregated') {
            metadata = aggregatedTarget.data.metadata
        } else {
            metadata = targetData[selectedIndex].data.metadata
        }
        let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/queryData?wrapper_id=${wrapper_id}&dateOption=${params.tracker_params.date_option}&timeUnit=day&start_date=${metadata.start_date}&end_date=${metadata.end_date}`;
        requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/queryData?wrapper_id=${wrapper_id}&dateOption=${params.tracker_params.date_option}&timeUnit=day&start_date=${metadata.start_date}&end_date=${metadata.end_date}`
        if (params.tracker_params.change_option.type === 'SUM') {
            requestUrl += `&variable=${params.tracker_params.change_option.variable}`;
        }
        requestUrl += `&inclusive=${true}&joinType=OR`

        if (metadata.filters.length > 0) {
            const filterStrings = metadata.filters.map(filter => 
                `${encodeURIComponent(filter.key)}=${encodeURIComponent(filter.value)}`
            );
            const filterOptions = filterStrings.join(';');
            requestUrl += `&filterOptions=${filterOptions}`;
        }
        console.log('actual data', requestUrl)

        try {
            const response = await fetch(requestUrl);
            const data = await response.json();
            const convertedData = data.map(item => ({
                timestamp: new Date(item.date).getTime() / 1000,
                value: parseFloat(item.value)
            }));
            setActualData([{ data: convertedData, name: 'Actual Data' }]);
        } catch (error) {
            console.error('Error fetching actual data:', error);
        }
    };

    fetchActualData();
    queryBaselines();

}, [selectedIndex]);
  
const saveData = (identifier, body) => {
    let url = 'http://127.0.0.1:5001/circleops-5f8c7/us-central1/saveTargets';
    url = 'https://us-central1-circleops-5f8c7.cloudfunctions.net/saveTargets'
  
      const requestBody = {
        type: 'edit',
        body: body, 
        tracker_id:  params.id,
        index: identifier
      };
    
      fetch(url, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody)
      }).then((_) => {
        console.log('Component saved successfully');
      })
      .catch(error => {
        console.error('Error saving report:', error);
      });
  };

  function formatDate(timestamp) {
    return new Date(timestamp * 1000).toLocaleString("en-US", {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: "GMT"
    });
  }


  return (
      <div>
        {showPopup && <PopupModal onSave={saveData} onClose={() => {setShowPopup(false)}} trackerParams={simulationData.trackerParams} modelParams={simulationData.modelParams} activeIndex={selectedIndex} /> }

        {(selectedIndex != null && selectedIndex != 'aggregated') && <StandardButton2 onClick={() => {setShowPopup(true)}}>Edit Guidance or Run Simulation</StandardButton2>}
            {selectedIndex != null ? (
                selectedIndex === "aggregated" ? (
                    <CodeBlock>all_keys</CodeBlock>
                ) : (
                    <div>
                    {targetData[selectedIndex].data.metadata.filters.map((filter, idx, arr) => (
                        <>
                        <CodeBlock>{`${filter.key}=${filter.value}`}</CodeBlock>
                        {idx < arr.length - 1 && ', '}
                        </>
                    ))}
                    </div>
                )
            ) : null}
            {selectedIndex != null && (
                selectedIndex === "aggregated" ? (
                    <KPIVisualizer
                    startDate={aggregatedTarget.data.metadata.start_date}
                    endDate={aggregatedTarget.data.metadata.end_date}
                    guidance={[aggregatedTarget.data]}
                    actual_data={actualData}
                    baselines={baselines}
                    />
                ) : (
                    <KPIVisualizer
                    startDate={targetData[selectedIndex].data.metadata.start_date}
                    endDate={targetData[selectedIndex].data.metadata.end_date}
                    guidance={[targetData[selectedIndex].data]}
                    actual_data={actualData}
                    baselines={baselines}
                    />
                )
            )}

          <div>
              <BasicParagraph2>Current Progress: (data up to: {formatDate(params.state.last_processed_timestamp)})</BasicParagraph2>
              {params.state.date_ran > 0 ? (
                    <BasicParagraph2>Date Ran: {formatDate(params.state.date_ran)}</BasicParagraph2>
                ) : (
                    <BasicParagraph2>Date Ran: <i>Not ran yet</i></BasicParagraph2>
                )}

              <BasicParagraph2>Tracker Details:</BasicParagraph2>
              <BasicParagraph2><i>Start Date: {formatDate(params.tracker_params.start_date)}</i>, <i>End Date: {formatDate(params.tracker_params.end_date)}</i></BasicParagraph2>
              <Table border="1" style={{ width: '100%', textAlign: 'center' }}>
                  <Thead>
                      <Trow>
                          <Th></Th>
                          <Th>Current Total</Th>
                          <Th>Current Guidance Pace</Th>
                          <Th>Overall Guidance Target</Th>
                          <Th>Percentage off Guidance</Th>
                      </Trow>
                  </Thead>
                  <Tbody>
                    {
                        Object.keys(aggregatedTarget).length > 0 && 
                        <Trow onClick={() => handleRowClick('aggregated')}>
                            <Td>
                            <CodeBlock>all_keys</CodeBlock>
                            </Td>                              
                            <Td>{aggregatedTarget.total.toFixed(2)}</Td>
                            <Td>{aggregatedTarget.guidance_pace.toFixed(2)}</Td>
                            <Td>{aggregatedTarget.total_guidance.toFixed(2)}</Td>
                            <Td>{aggregatedTarget.off_guidance > 0 ? `+${(aggregatedTarget.off_guidance * 100).toFixed(2)}%` : `${(aggregatedTarget.off_guidance * 100).toFixed(2)}%`}</Td>
                        </Trow>
                    }
                      {targetData.map((item, index) => (

                        <Trow key={index} onClick={() => handleRowClick(index)}>
                            <Td>
                                {item.filters.length === 0 ? (
                                    <CodeBlock>all_keys</CodeBlock>
                                ) : (
                                    // Render each filter as a separate CodeBlock, separated by commas
                                    item.filters.map((filter, idx, arr) => (
                                        <>
                                            <CodeBlock>{`${filter.key}=${filter.value}`}</CodeBlock>
                                            {idx < arr.length - 1 && ', '}
                                        </>
                                    ))
                                )}
                            </Td>                              
                            <Td>{item.total.toFixed(2)}</Td>
                            <Td>{item.guidance_pace.toFixed(2)}</Td>
                            <Td>{item.total_guidance.toFixed(2)}</Td>
                            <Td>{item.off_guidance > 0 ? `+${(item.off_guidance * 100).toFixed(2)}%` : `${(item.off_guidance * 100).toFixed(2)}%`}</Td>
                        </Trow>

                      ))}
                  </Tbody>
              </Table>
          </div>
      </div>
  );
};

const InventoryForecastVisualizer = ({ selectedId, params }) => {
    const [plotData, setPlotData] = useState([{}]);
    const [secondaryPlotData, setSecondaryPlotData] = useState([{}])
    const [layout, setLayout] = useState({});
    const [selectedIndex, setSelectedIndex] = useState()
    const [selectedHeaderData, setSelectedHeaderData] = useState({})
    const [comparableDates, setComparableDates] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [selectedParams, setSelectedParams] = useState({})
    const [selectedData, setSelectedData] = useState()
    const [spinning, setSpinning] = useState(false)
    const [scenarios, setScenarios] = useState({})
    const [selectedScenario, setSelectedScenario] = useState('All')
    const [secondaryTitle, setSecondaryTitle] = useState('')

    useEffect(() => {
        const selectedMetadata = params.metadata.find(item => item.id == selectedId);
        if (selectedMetadata) {
            setSelectedParams(selectedMetadata);

            const forecastDetails = selectedMetadata.scenarios[0]?.forecast_details;
            setSpinning(true)
            downloadFile(forecastDetails).then((response) => {
                setSelectedData(response)
                setSpinning(false)
                setScenarios({
                    'Expected': {
                        'data': []
                    },
                    'Optimistic': {
                        'data': []
                    },
                    'Pessimistic': {
                        'data': [], 
                    }, 
                    'All': {
                        'data': []
                    }
                })
            })
        }

    }, [selectedId, params])

    function findEquivalentDateLastYear(timestampInSec) {
        const currentDate = new Date(timestampInSec * 1000);
        const lastYear = currentDate.getFullYear() - 1;
        const dayOfWeek = currentDate.getUTCDay();
        const dateOfMonth = currentDate.getUTCDate();
    
        const startDayOfMonth = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getUTCMonth(), 1));
        const weekOfMonth = Math.ceil((dateOfMonth + startDayOfMonth.getUTCDay()) / 7);
     
        let targetDate = new Date(Date.UTC(lastYear, currentDate.getUTCMonth(), 1));
        targetDate.setUTCDate(targetDate.getUTCDate() + (dayOfWeek - targetDate.getUTCDay() + 7) % 7);
     
        if (targetDate.getUTCDate() > 7) {
            targetDate.setUTCDate(targetDate.getUTCDate() + (weekOfMonth - 1) * 7);
        } else {
            targetDate.setUTCDate(targetDate.getUTCDate() + weekOfMonth * 7 - 7);
        }
    
        if (targetDate.getUTCMonth() !== currentDate.getUTCMonth()) {
            targetDate.setUTCDate(targetDate.getUTCDate() - 7);
        }
    
        return targetDate;
    }

    useEffect(() => {
        setSecondaryPlotData([])
        if (selectedData) {       
            if (selectedParams.scenarios[0].type == 'grouped') {
                let colors = ['#003f5c', '#665191', '#f95d6a', '#a05195', '#d45087', '#ff7c43', '#2f4b7c', '#ffa600'];
                let tempPlotData = [];
                const combinedData = {};
            
                const lookbackTimestamps = Array.from({ length: params.tracker_params.lookback_days }, (_, i) => {
                    return params.state.last_processed_timestamp - (i) * 24 * 60 * 60;
                }).sort();
                
                const forecastTimestamps = Array.from({ length: params.tracker_params.forecast_days }, (_, i) => {
                    return params.state.last_processed_timestamp + (i + 1) * 24 * 60 * 60;
                }).sort();

                console.log('forecast timestamps', forecastTimestamps,params.state.last_processed_timestamp)
                console.log('past timestamps', lookbackTimestamps)
            
                Object.keys(selectedData.interpretation).forEach((key) => {
                    combinedData[key] = {
                        realized: selectedData.interpretation[key].realized_values,
                        forecast: selectedData.forecast[key] || []
                    };
                });
    
                let xRow2 = [];
                let yRowRealizedTotal = Array(lookbackTimestamps.length).fill(0);
                let yRowForecastExpectedTotal = Array(forecastTimestamps.length).fill(0);
                let yRowForecastOptimisticTotal = Array(forecastTimestamps.length).fill(0);
                let yRowForecastPessimisticTotal = Array(forecastTimestamps.length).fill(0);
    
                lookbackTimestamps.forEach((timestamp) => {
                    xRow2.push(new Intl.DateTimeFormat('en-US', { weekday: 'short', month: 'short', day: 'numeric', timeZone: 'UTC'}).format(new Date(timestamp * 1000)));
                });
    
                forecastTimestamps.forEach((timestamp) => {
                    xRow2.push(new Intl.DateTimeFormat('en-US', { weekday: 'short', month: 'short', day: 'numeric', timeZone: 'UTC'}).format(new Date(timestamp * 1000)));
                });
    
                Object.keys(combinedData).forEach((key, index) => {
                    const scenario = selectedParams.scenarios.find(s => s.id === key);
                    const name = scenario ? scenario.name : key;
            
                    let realizedValues = combinedData[key].realized;
                    let forecastValues = combinedData[key].forecast;
            
                    let yRowRealized = Array(lookbackTimestamps.length).fill(0);
                    lookbackTimestamps.forEach((timestamp, i) => {
                        const realizedValue = realizedValues.find(item => item.timestamp == timestamp);
                        yRowRealized[i] = realizedValue ? realizedValue.value : 0;
                        yRowRealizedTotal[i] += yRowRealized[i];
                    });
            
                    forecastTimestamps.forEach((timestamp, i) => {
                        const forecastedValue = forecastValues.find(item => item.timestamp == timestamp)
                        yRowForecastExpectedTotal[i] += forecastedValue ? forecastedValue.value : 0;
                        yRowForecastOptimisticTotal[i] += forecastedValue ? forecastedValue.upper_value : 0;
                        yRowForecastPessimisticTotal[i] += forecastedValue ? forecastedValue.lower_value : 0;
                    });
            
                    let color = colors[index % colors.length];
            
                    // Add individual realized values as bar chart
                    tempPlotData.push({
                        x: xRow2.slice(0, lookbackTimestamps.length),
                        y: yRowRealized,
                        type: 'bar',
                        name: name,
                        marker: {
                            color: color,
                            opacity: 1
                        },
                        showlegend: false,
                        legendgroup: name,
                    });
            
                    if (selectedScenario !== 'All') {
                        // For other scenarios, add the selected forecast as a bar chart
                        let yRowForecast;
                        if (selectedScenario === 'Expected') {
                            yRowForecast = forecastValues.map(v => v.value);
                        } else if (selectedScenario === 'Optimistic') {
                            yRowForecast = forecastValues.map(v => v.upper_value);
                        } else if (selectedScenario === 'Pessimistic') {
                            yRowForecast = forecastValues.map(v => v.lower_value);
                        }
            
                        tempPlotData.push({
                            x: xRow2.slice(lookbackTimestamps.length),
                            y: yRowForecast,
                            type: 'bar',
                            name: name,
                            marker: {
                                color: color,
                                opacity: 0.6
                            },
                            showlegend: false,
                            legendgroup: name,
                        });
                    }
    
                    // Add custom legend entries
                    tempPlotData.push({
                        x: [null],
                        y: [null],
                        type: 'scatter',
                        mode: 'markers',
                        marker: { color: color, size: 10 },
                        showlegend: true,
                        name: name + ' (Realized)',
                        legendgroup: name,
                        legendgrouptitle: { text: name }
                    });
                    tempPlotData.push({
                        x: [null],
                        y: [null],
                        type: 'scatter',
                        mode: 'markers',
                        marker: { color: color, size: 10, opacity: 0.6 },
                        showlegend: true,
                        name: name + ' (Forecast)',
                        legendgroup: name,
                    });
                });
    
                if (selectedScenario === 'All') {
                    tempPlotData.push({
                        x: xRow2.slice(lookbackTimestamps.length),
                        y: yRowForecastExpectedTotal,
                        type: 'scatter',
                        mode: 'lines',
                        name: 'Total Expected',
                        line: { color: '#003f5c', width: 2 },
                        legendgroup: 'forecast',
                    });
                    tempPlotData.push({
                        x: xRow2.slice(lookbackTimestamps.length),
                        y: yRowForecastOptimisticTotal,
                        type: 'scatter',
                        mode: 'lines',
                        name: 'Total Optimistic',
                        line: { color: '#ffa600', width: 2, dash: 'dot' },
                        legendgroup: 'forecast',
                    });
                    tempPlotData.push({
                        x: xRow2.slice(lookbackTimestamps.length),
                        y: yRowForecastPessimisticTotal,
                        type: 'scatter',
                        mode: 'lines',
                        name: 'Total Pessimistic',
                        line: { color: '#665191', width: 2, dash: 'dashdot' },
                        legendgroup: 'forecast',
                    });
                }
            
                setPlotData(tempPlotData);
                setLayout({
                    barmode: 'stack',
                    xaxis: {
                        ...layout.xaxis,
                        tickangle: 45 
                    },
                    legend: {
                        orientation: 'h',
                        yanchor: 'bottom',
                        y: 1.02,
                        xanchor: 'right',
                        x: 1,
                        traceorder: 'grouped'
                    }
                });
            }
        }
    }, [selectedData, selectedScenario, params.tracker_params.lookback_days, params.tracker_params.forecast_days, params.state.last_processed_timestamp]);

    useEffect(() => {
        console.log('forecast index', selectedIndex, params.tracker_params.lookback_days)
        const forecastIndex = selectedIndex - params.tracker_params.lookback_days
        if (forecastIndex < 0) return; 

        if (selectedData && forecastIndex != null) {
            if (selectedParams.scenarios[0].type == 'grouped') {
                let colors = ['#003f5c', '#665191', '#f95d6a', '#a05195', '#d45087', '#ff7c43', '#2f4b7c', '#ffa600'];

                const forecastDate = params.state.last_processed_timestamp + forecastIndex * 86400;
                const forecastedValues = {}

                Object.keys(selectedData.forecast).forEach((key) => {
                    const forecastedEntity = selectedData.forecast[key].find(item => item.timestamp == forecastDate);
                    if (forecastedEntity) {
                        forecastedValues[key] = {
                            'expected': forecastedEntity['value'],
                            'optimistic': forecastedEntity['upper_value']
                        };
                    }
                });
                
                const referenceDateObject = new Date(forecastDate * 1000);
                const oneYearAgo = new Date(referenceDateObject.getTime());
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
                const oneYearAgoTimestamp = oneYearAgo.getTime()/1000;
                
                const equivalentDateLastYear = findEquivalentDateLastYear(forecastDate);
                let equivalentDateLastYearTimestamp = equivalentDateLastYear ? equivalentDateLastYear.getTime() / 1000 : null;
                
                const previousWeeksTimestamps = Array.from({ length: 4 }, (_, i) => new Date(referenceDateObject.getTime() - (i + 1) * 604800000).getTime() / 1000);
                const adjacentTimestamps = [...new Set([oneYearAgoTimestamp, equivalentDateLastYearTimestamp, ...previousWeeksTimestamps])]
                    .filter(timestamp => timestamp !== null)
                    .sort((a, b) => b - a);

                
                const allKeys = new Set([
                    ...Object.keys(forecastedValues),
                    ...Object.keys(selectedData.interpretation)
                ]);
                
                const plotData = Array.from(allKeys).map((key, index) => {
                    const mappedScenario = selectedParams.scenarios.find(scenario => scenario.id == key);
                    const name = mappedScenario ? mappedScenario.name : key

                    const trace = {
                        x: ['Expected', 'Optimistic'],
                        y: [
                            forecastedValues[key] ? forecastedValues[key].expected : 0,
                            forecastedValues[key] ? forecastedValues[key].optimistic : 0
                        ],
                        type: 'bar',
                        name: name,
                        marker: { color: colors[index % colors.length] }
                    };


                
                    adjacentTimestamps.forEach(timestamp => {
                        const value = selectedData.interpretation[key]?.realized_values.find(item => item.timestamp == timestamp);
                        trace.x.push(new Date(timestamp * 1000).toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric', timeZone: 'UTC'}));;
                        trace.y.push(value ? value.value : 0);
                    });
                
                    return trace;
                });
                
                setSecondaryPlotData(plotData);
                setSecondaryTitle(new Date(forecastDate * 1000).toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric', timeZone: 'UTC'}))
            } else {
                const forecastData =  selectedData.forecast[selectedIndex]
                const referenceDate = forecastData.timestamp
                const referenceDateObject = new Date(referenceDate * 1000);
                
                const oneYearAgo = new Date(referenceDateObject.getTime());
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                const oneYearAgoTimestamp = oneYearAgo.getTime() / 1000;
                
                const oneWeekAgo = new Date(referenceDateObject.getTime() - 604800000); 
                const oneWeekAgoTimestamp = oneWeekAgo.getTime() / 1000;
            
                const equivalentDateLastYear = findEquivalentDateLastYear(referenceDate);
                let equivalentDateLastYearTimestamp;

                if (equivalentDateLastYear) {
                    equivalentDateLastYearTimestamp = equivalentDateLastYear.getTime() / 1000;
                } else {
                    equivalentDateLastYearTimestamp = null;
                }
                
                const relevantDatesWithValues = [
                    { date: oneWeekAgoTimestamp },
                    { date: oneYearAgoTimestamp },
                    { date: equivalentDateLastYearTimestamp }
                ].map((dateObj) => {
                    let matchingValue = selectedData.downloadedData.interpretation.realized_values.find((realizedValue) => realizedValue.timestamp === dateObj.date);
                    if (!matchingValue) {
                        matchingValue = selectedData.downloadedData.realized_values.find((realizedValue) => realizedValue.timestamp === dateObj.date);
                    }
                    return { 'date': new Date(dateObj.date * 1000).toUTCString(), 'volume': matchingValue?.value };
                });


                setComparableDates(relevantDatesWithValues)
            }
        }
    }, [selectedIndex])

    const handleClick = (data) => {
        const pointIndex = data.points[0].pointIndex;
        setSelectedIndex(pointIndex)
    };

    const handleScenarioClick = (key) => {
        setSelectedScenario(key);
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap',}}>
            {spinning && <StyledSpinner />}
            <BasicParagraphStrong>Scenarios</BasicParagraphStrong>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                {Object.keys(scenarios).map(scenario => (
                    <StandardButton2 
                    key={scenario.id}
                    onClick={() => handleScenarioClick(scenario)}
                    >
                    {scenario}
                    </StandardButton2>
                ))}
            </div>
            <BasicParagraph2>Selected Scenario: {selectedScenario}</BasicParagraph2>
            {showPopup && <ForecastDetails 
                    onClose={() => {setShowPopup(false)}} selectedScenarioId={selectedParams.scenarios[0].id} 
                    entityData={selectedData} trackerParams={params.tracker_params} wrapper_id={params.wrapper_id} tracker_id={params.id} 
                    performanceData={{history: []}}
                    entityParams={selectedParams}
            /> }
            <Plot
                data={plotData}
                layout={layout}
                useResizeHandler
                style={{ width: '100%', height: '100%' }}
                onClick={handleClick}
            />
            {secondaryPlotData.length > 0 && <Plot
                    data={secondaryPlotData}
                    useResizeHandler
                    style={{ width: '100%', height: '100%' }}
                    layout={{
                        title: secondaryTitle,
                        barmode: 'stack',
                        xaxis: {
                            title: 'Scenarios',
                        },
                        yaxis: {
                            title: 'Forecasted Value'
                        },
                        legend: {
                            title: 'Forecast Type'
                        }
                    }}
                />}
        </div>
    );
}

const KPIVisualizer2 = ({ selectedId, data, params }) => {
    const [layout, setLayout] = useState([])
    const [historyLayout, setHistoryLayout] = useState([])
    const [plotData, setPlotData] = useState([]);
    const [isCumulative, setIsCumulative] = useState(true)
    const [selectedScenarios, setSelectedScenarios] = useState([])
    const [allScenarios, setAllScenarios] = useState({})
    const [selectedData, setSelectedData] = useState({})
    const [selectedParameters, setSelectedParameters] = useState({})
    const [totalSum, setTotalSum] = useState(0)
    const [primaryId, setPrimaryId] = useState()
    const [showPopup, setShowPopup] = useState(false);
    const [selectedScenarioId, setSelectedScenarioId] = useState()

    useEffect(() => {
        const newSelectedData = data.find(entry => entry.id === selectedId);
        setSelectedData(newSelectedData);

        const newSelectedParameters = params.metadata.find(entry => entry.id == selectedId)
        setSelectedParameters(newSelectedParameters)

        const scenarioMap = {};
        newSelectedParameters.scenarios.forEach(scenario => {
            scenarioMap[scenario.id] = scenario.name;
        });
        setAllScenarios(scenarioMap)
        setPrimaryId(newSelectedData.metadata.primary_id)

        if (newSelectedData.primary_id) {
            setSelectedScenarios([newSelectedData.primary_id]);
        } else {
            setSelectedScenarios(Object.keys(scenarioMap))
        }
    }, [selectedId, data]);

    useEffect(() => {    
        if (Object.keys(selectedParameters).length > 0) {
          const newLayout = {
            shapes: []
          };
          const newHistoryLayout = {
            shapes: []
          };
    
          const start_date = new Date(params.tracker_params.start_date * 1000).toISOString();
          const end_date = new Date(params.tracker_params.end_date * 1000).toISOString();
    
          newLayout.shapes.push({
            type: 'line',
            xref: 'x',
            x0: start_date,
            x1: start_date,
            yref: 'paper',
            y0: 0,
            y1: 1,
            line: {
              color: 'green',
              width: 2,
              dash: 'dash'
            }
          });
          newLayout.shapes.push({
            type: 'line',
            xref: 'x',
            x0: end_date,
            x1: end_date,
            yref: 'paper',
            y0: 0,
            y1: 1,
            line: {
              color: 'green',
              width: 2,
              dash: 'dash'
            }
          });
    
          const target_value = selectedParameters.target_value;
          if (target_value !== null && isCumulative) {
            const targetLine = {
                type: 'line',
                xref: 'paper',
                x0: 0,
                x1: 1,
                yref: 'y',
                y0: target_value,
                y1: target_value,
                line: {
                    color: 'red',
                    width: 2,
                    dash: 'dot'
                }
            };
            newLayout.shapes.push(targetLine);
            newHistoryLayout.shapes.push(targetLine); 
          }
    
          setLayout(newLayout);
          setHistoryLayout(newHistoryLayout)
        } else {
          setLayout({});
        }
    }, [selectedParameters, isCumulative]);    
    

    useEffect(() => {
        if (Object.keys(selectedData) == 0) return;
      
        const sum = Object.values(selectedData.metadata.data).reduce((a, b) => a + b.value, 0)
        setTotalSum(sum);

    }, [selectedData])

    useEffect(() => {
        if (!selectedData || Object.keys(selectedData).length === 0) return;

        const dates = selectedData.metadata.data.map(entry => new Date(entry.timestamp * 1000).toISOString());        
        const values = selectedData.metadata.data.map(entry => entry.value);

        const mappedValues = isCumulative ? accumulateData(values) : values

        const lastActualDate = dates[dates.length - 1]; 
        const lastActualValue = mappedValues[values.length - 1]; 
    
        const actualData = {
            x: dates,
            y: mappedValues, // Apply accumulation if needed
            type: 'scatter',
            mode: 'lines+markers',
            name: 'Actual'
        };

        const total = values.reduce((sum, value) => sum + value, 0);

        const scenariosData = selectedData.scenarios
            .filter(scenario => selectedScenarios.includes(scenario.id))
            .map(scenario => {

            const sortedScenarioData = scenario.data.sort((a, b) => a.timestamp - b.timestamp);
            const scenarioDates = sortedScenarioData.map(entry => new Date(entry.timestamp * 1000).toISOString());
            const scenarioValues = sortedScenarioData.map(entry => entry.value);

            const scenarioParam = selectedParameters.scenarios.find(param => param.id === scenario.id);
            const scenarioName = scenarioParam ? scenarioParam.name : scenario.id;

            return {
                x: [lastActualDate, ...scenarioDates],
                y: isCumulative ? [lastActualValue, ...accumulateData(scenarioValues, total)] : [lastActualValue, ...scenarioValues],
                type: 'scatter',
                mode: 'lines+markers',
                name: scenarioName
            };
        });

        setPlotData([actualData, ...scenariosData]);
    }, [selectedData, isCumulative, selectedScenarios]);

    const accumulateData = (data, total=0) => {
        return data.reduce((acc, value, index) => {
            if (index === 0) return [total + value];
            acc.push(acc[index - 1] + value);
            return acc;
        }, []);
    };

    const renderValue = (scenarioId, total_value, baseline) => {
        const scenario = selectedData.scenarios.find(scenario => scenario.id === scenarioId);
    
        if (!scenario) {
            return <div>No data available</div>;
        }
        
        let sum = total_value;
        
        scenario.data.forEach(dataItem => {
            sum += dataItem.value;
        });
    
        let percentageDiff = ((sum - baseline) / baseline * 100);
        let formattedPercentage = `${percentageDiff >= 0 ? "+" : ""}${percentageDiff.toFixed(2)}%`;
    
        const color = percentageDiff >= 0 ? "green" : "red";
    
        return (
            <div>
                <div>{Number(sum.toFixed(2)).toLocaleString()}</div>
                <div style={{ color: color }}>{formattedPercentage}</div>
            </div>
        );
    }
    
    
    const formatScenario = (scenario, primaryId) => {
        const scenarioData = selectedParameters.scenarios.find(scenarioObj => scenarioObj.id === scenario);
    
        let name = scenarioData.name;
        let label = scenario === primaryId ? ' (Primary Forecast)' : '';
    
        let description = '';
        let type = '';
    
        if (scenarioData.type === 'ai_forecast') {
            const confidence = scenarioData.params.confidence_threshold;
            type = 'AI Forecast';
            description = `Confidence: ${confidence.toFixed(2)}`;
        } else if (scenarioData.type === 'vs. last year') {
            type = 'Last Year\'s Pace';
        }
    
        let adjustmentsDescription = '';
        if (scenarioData.params.adjustments) {
            const adjustmentCount = scenarioData.params.adjustments.length;
            const adjustmentText = adjustmentCount === 1 ? 'adjustment' : 'adjustments';
            adjustmentsDescription = `${adjustmentCount} ${adjustmentText}`;
        } else {
            adjustmentsDescription = '0 adjustments';
        }
    
        return (
            <Trow>
                <Td>{scenario}</Td>
                <Td>{name + label}</Td>
                <Td>{type}</Td>
                <Td>
                    <StandardButton2 onClick={() => editScenario(scenario)}>Details</StandardButton2>
                </Td>
            </Trow>
        );
    };    

    const editScenario = (scenarioId) => {
        setSelectedScenarioId(scenarioId)
        setShowPopup(true);
    }

    const saveData = (scenarioData, scenarioId) => {
        console.log('scenario data', scenarioData)
        let url = 'http://127.0.0.1:5001/circleops-5f8c7/us-central1/saveTargets';
        url = 'https://us-central1-circleops-5f8c7.cloudfunctions.net/saveTargets';
        let requestBody = {
            params: scenarioData,
            tracker_id: params.id,
            scenario_id: scenarioId,
            entity_id: selectedId
        }
        
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody)
        }).then((_) => {
            console.log('Component saved successfully');
        })
        .catch(error => {
            console.error('Error saving report:', error);
        });
      };

    return (
    <>
        <BasicParagraphStrong>Forecasts:</BasicParagraphStrong>

        <BasicParagraph2>Select Forecasts</BasicParagraph2>
        <MultiSelectDropdown2 selectedOptions={selectedScenarios} options={Object.keys(allScenarios)} onChange={setSelectedScenarios} mapping={allScenarios} />
        <Table>
            <Thead>
                <Trow>
                    <Th>Forecast ID</Th>
                    <Th>Forecast Name</Th>
                    <Th>Forecast Type</Th>
                </Trow>
            </Thead>
            <Tbody>
            {selectedScenarios.map((scenario, index) => (
                formatScenario(scenario, primaryId)
            ))}
            </Tbody>
        </Table>
        <Table border="1" style={{ width: '100%', textAlign: 'center' }}>
                <Thead>
                    <Trow>
                    <Th>
                        Current Total
                    </Th>
                    {selectedParameters.target_value && (
                        <Th>
                            Current Target
                        </Th>
                    )}
                    {selectedScenarios.map(id => (
                        <Th>
                        {allScenarios[id]}
                        {(id == primaryId && '(Primary)')}
                        </Th>
                    ))}
                    </Trow>
                </Thead>
                <Tbody>
                    <Trow>
                        <Td>{Number(totalSum.toFixed(2)).toLocaleString()}</Td>
                        {selectedParameters.target_value && <Td>{Number(selectedParameters.target_value.toFixed(2)).toLocaleString()}</Td>}
                        {selectedScenarios.map(scenario => (
                            <Td>{renderValue(scenario, totalSum, selectedParameters.target_value)}</Td>
                        ))}
                    </Trow>
                </Tbody>
        </Table>

        <>
            <ViewToggle isCumulative={isCumulative} onChange={setIsCumulative} />
            <Plot
                data={plotData}
                layout={layout}
                useResizeHandler
                style={{ width: '100%', height: '100%' }}
            />
        </>

        {/* {showPopup && <PopupModal2 onSave={saveData} onClose={() => {setShowPopup(false)}} entityParams={selectedParameters} selectedScenarioId={selectedScenarioId} entityData={selectedData} trackerParams={params.tracker_params} wrapper_id={params.wrapper_id} tracker_id={params.id} /> } */}
        {/* {showAnalyzePopup && <AnalyzeForecast entityParams={selectedParameters} selectedScenarioId={selectedScenarioId} entityData={selectedData} trackerParams={params.tracker_params} wrapper_id={params.wrapper_id} tracker_id={params.id}  /> } */}
        {showPopup && <ForecastDetails 
            onSave={saveData} onClose={() => {setShowPopup(false)}} entityParams={selectedParameters} selectedScenarioId={selectedScenarioId} 
            entityData={selectedData} trackerParams={params.tracker_params} wrapper_id={params.wrapper_id} tracker_id={params.id} 
            performanceData={{history: []}}
        /> }
    </>
    )
}

const KPIEntryComponent2 = ({ wrapper_id, params }) => {
    const [selectedEntryId, setSelectedEntryId] = useState()
    const [tableData, setTableData] = useState([])
    const [scenariosData, setScenariosData] = useState([])
    const [aggregatedTarget, setAggregatedTarget] = useState({})
  
    useEffect(() => {
        let requestUrl = `http://127.0.0.1:5001/circleops-5f8c7/us-central1/downloadFile?file_path=${params.state.data_path}`
        requestUrl = `https://us-central1-circleops-5f8c7.cloudfunctions.net/downloadFile?file_path=${params.state.data_path}`
        fetch(requestUrl)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const processedData = []
            params.metadata.forEach(item => {
                const foundEntity = data.find(d => d.id === item.id);

                if (foundEntity != null) {

                    const actualSum = foundEntity.metadata.data.reduce((a, b) => a + b.value, 0)
                    console.log('found entity', foundEntity, item)


                    const primaryScenario = foundEntity.scenarios.find((scenario) => scenario.id === item.primary_id)

                    let scenarioSum = 'N/A';
                    let targetSum = 'N/A';
                    if (primaryScenario) {
                        scenarioSum = primaryScenario.data.reduce((a, b) => a + b.value, 0) + actualSum;
                    }
                    if (item.target_value) {
                        targetSum = item.target_value
                    }

                    processedData.push({
                        'id': item.id, 
                        'name': item.name,
                        'actualSum': actualSum,
                        'currentPredictionSum': scenarioSum,
                        'targetSum': targetSum
                    })
                }
            });

            setTableData(processedData)
            setScenariosData(data)
          });
  }, [params, wrapper_id]);
  
  
    // Handle click event for each row in the table
    const handleRowClick = (id) => {
      setSelectedEntryId(id);
    };

    function formatDate(timestamp) {
        const date = new Date(timestamp * 1000);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
            timeZoneName: 'short'
        };
        const parts = new Intl.DateTimeFormat('en-US', options).formatToParts(date);
    
        let month = '', day = '', year = '', timeZoneName = '';
    
        for (const part of parts) {
            switch (part.type) {
                case 'month':
                    month = part.value;
                    break;
                case 'day':
                    day = part.value;
                    break;
                case 'year':
                    year = part.value;
                    break;
                case 'timeZoneName':
                    timeZoneName = part.value;
                    break;
            }
        }
    
        return `${month} ${day}, ${year} ${timeZoneName}`;
    }
    
    

    const renderValue = (value, target) => {
        if (target == 'N/A') {
            return (
                <div>
                    <div>{Number(value.toFixed(2)).toLocaleString()}</div>
                </div>
            )
        } else {
            let percentageDiff = ((value - target) / target * 100);
            let formattedPercentage = `${percentageDiff >= 0 ? "+" : ""}${percentageDiff.toFixed(2)}%`;
        
            const color = percentageDiff >= 0 ? "green" : "red";
        
            return (
                <div>
                    <div>{Number(value.toFixed(2)).toLocaleString()}</div>
                    <div style={{ color: color }}>{formattedPercentage}</div>
                </div>
            );
        }
    }

    function humanReadableDiff(timestamp) {
        const now = new Date().getTime(); // current time in milliseconds
        const timeDiff = Math.floor((now - timestamp * 1000) / 1000); // convert difference to seconds
    
        let unit = "second";
        let count = timeDiff;
    
        if (timeDiff >= 86400) { // more than a day
            count = Math.floor(timeDiff / 86400);
            unit = "day";
        } else if (timeDiff >= 3600) { // more than an hour
            count = Math.floor(timeDiff / 3600);
            unit = "hour";
        } else if (timeDiff >= 60) { // more than a minute
            count = Math.floor(timeDiff / 60);
            unit = "minute";
        }
    
        // Handling plural forms
        if (count !== 1) {
            unit += "s";
        }
    
        return `${count} ${unit} ago`;
    }
    
    

    return (
        <div>
            <div>
                <BasicParagraph2><i>{formatDate(params.tracker_params.start_date)} -> {formatDate(params.tracker_params.end_date)}</i></BasicParagraph2>
                <BasicParagraph2>Updated {humanReadableDiff(params.state.date_ran)}</BasicParagraph2>
                <br />
                {selectedEntryId != null && <>
                    <GroupingComponent selectedId={selectedEntryId} params={params} />
                </>} 
                {selectedEntryId != null && <>
                    <KPIVisualizer2 selectedId={selectedEntryId} data={scenariosData} params={params} />
                </>}

                <Table border="1" style={{ width: '100%', textAlign: 'center' }}>
                    <Thead>
                        <Trow>
                            <Th></Th>
                            <Th>Current Total</Th>
                            <Th>Current Prediction</Th>
                            <Th>Target</Th>
                        </Trow>
                    </Thead>
                    <Tbody>
                      {
                          Object.keys(aggregatedTarget).length > 0 && 
                          <Trow onClick={() => handleRowClick('aggregated')}>
                              <Td>
                              <CodeBlock>all_keys</CodeBlock>
                              </Td>                              
                              <Td>{aggregatedTarget.total.toFixed(2)}</Td>
                              <Td>{aggregatedTarget.guidance_pace.toFixed(2)}</Td>
                              <Td>{aggregatedTarget.total_guidance.toFixed(2)}</Td>
                          </Trow>
                      }
                        {tableData.map((item, index) => (
                            <Trow onClick={() => handleRowClick(item.id)}>
                                <Td>
                                    {item.name}
                                </Td>
                                <Td>{Number(item.actualSum.toFixed(2)).toLocaleString()}</Td>
                                <Td>
                                    {renderValue(item.currentPredictionSum, item.targetSum)}
                                </Td>
                                <Td>
                                    {item.targetSum === 'N/A' ? 'N/A' : Number(item.targetSum.toFixed(2)).toLocaleString()}
                                </Td>
                            </Trow>
                        ))}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
  };

const GroupingComponent = ({selectedId, params}) => {
    const [selectedParameters, setSelectedParameters] = useState({})

    useEffect(() => {
        const selectedMetadata = params.metadata.find(item => item.id === selectedId);
        setSelectedParameters(selectedMetadata)
    }, [selectedId, params]);

    const formatFilters = (filters) => {
        if (!filters || filters.length === 0) return '';
        return filters.map(filter => {
            return Object.entries(filter).map(([key, value]) => `${key}=${value}`).join(', ');
        }).join(', ');
    };

    return (
        <>
            <BasicParagraphStrong>Grouping Details</BasicParagraphStrong>
            <BasicParagraph2>Name: {selectedParameters.name}</BasicParagraph2>
            <BasicParagraph2>Filters: {formatFilters(selectedParameters.filters)}</BasicParagraph2>
        </>
    );
};


const KPIInventoryEntryComponent = ({ wrapper_id, params }) => {
    const [selectedEntryId, setSelectedEntryId] = useState()
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        downloadFile(params.state.data_path).then((response) => {
            setTableData(response)
            console.log('table data', response)
        })
    }, [params, wrapper_id]);

    const handleRowClick = (id) => {
      setSelectedEntryId(id);
    };
    
    function formatDate(timestamp) {
        const date = new Date(timestamp * 1000);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
            timeZoneName: 'short'
        };
        const parts = new Intl.DateTimeFormat('en-US', options).formatToParts(date);
    
        let month = '', day = '', year = '', timeZoneName = '';
    
        for (const part of parts) {
            switch (part.type) {
                case 'month':
                    month = part.value;
                    break;
                case 'day':
                    day = part.value;
                    break;
                case 'year':
                    year = part.value;
                    break;
                case 'timeZoneName':
                    timeZoneName = part.value;
                    break;
            }
        }
        return `${month} ${day}, ${year} ${timeZoneName}`;
    }

    function humanReadableDiff(timestamp) {
        const now = new Date().getTime(); 
        const timeDiff = Math.floor((now - timestamp * 1000) / 1000); 
    
        let unit = "second";
        let count = timeDiff;
    
        if (timeDiff >= 86400) {
            count = Math.floor(timeDiff / 86400);
            unit = "day";
        } else if (timeDiff >= 3600) {
            count = Math.floor(timeDiff / 3600);
            unit = "hour";
        } else if (timeDiff >= 60) { 
            count = Math.floor(timeDiff / 60);
            unit = "minute";
        }
    
        if (count !== 1) {
            unit += "s";
        }

        return `${count} ${unit} ago`;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', overflowY: 'auto' }}>
            <div>
                {params.tracker_params.start_date && (
                    <BasicParagraph2>
                        <i>{formatDate(params.tracker_params.start_date)} -> {formatDate(params.tracker_params.end_date)}</i>
                    </BasicParagraph2>
                )}
                {!params.tracker_params.start_date && (
                    <BasicParagraph2>Time: {formatDate(params.state.last_processed_timestamp)}</BasicParagraph2>
                )
                }
                <BasicParagraph2>Updated {humanReadableDiff(params.state.date_ran)}</BasicParagraph2>
            </div>


                {selectedEntryId != null && (
                    <div style={{ marginBottom: '20px' }}>
                        <GroupingComponent selectedId={selectedEntryId} params={params} />
                        <InventoryForecastVisualizer selectedId={selectedEntryId} params={params} />
                    </div>
                )}
                <Table border="1" style={{ width: '100%', textAlign: 'center' }}>
                    <Thead>
                        <Trow>
                            <Th></Th>
                            {tableData.length > 0 && Object.keys(tableData[0].values).map(key => (
                                <Th key={key}>{new Date(key * 1000).toUTCString()}</Th>
                            ))}
                        </Trow>
                    </Thead>
                    <Tbody>
                        {tableData.map((item, index) => (
                            <Trow key={index} onClick={() => handleRowClick(item.id)}>
                                <Td>{item.name}</Td>
                                {tableData.length > 0 && Object.keys(item.values).map(key => (
                                    <Td key={key}>{Math.round(item.values[key])}</Td>
                                ))}
                            </Trow>
                        ))}
                    </Tbody>
                </Table>
            </div>
    );
  };


export {KPIVisualizer, KPIEntryComponent, KPIEntryComponent2, KPIInventoryEntryComponent};
